import {
    SET_V_ALLWALLET,
    SET_V_START_DATE,
    SET_V_END_DATE,
    SET_V_LOADING,
    RESET_V_ALLWALLET
} from "./vendorWallet.types";
import MembersService from "../../line/services/members.service";

export const setAllMemberFilteredWallet = (data) => {
    return {
        type: SET_V_ALLWALLET,
        payload: data,
    };
};

export const refreshVendorWallet = (startDate, endDate) => {
    // console.log("get All Wallet");
    return dispatch => {
        // console.log("refresh getAllWallet dispatch");
        MembersService.getWallet(startDate, endDate)
            .then(res => {
                const wallet = res.data;
                dispatch({
                    type: SET_V_ALLWALLET,
                    payload: wallet
                });
            })
    };
};

export const resetVendorWallet = () => {
    return {
        type: RESET_V_ALLWALLET,
        payload: null
    };
};

export const setWalletStartDate = (data) => {
    return {
        type: SET_V_START_DATE,
        payload: data,
    };
};

export const setVendorWalletLoading = (data) => {
    return {
        type: SET_V_LOADING,
        payload: data,
    };
};

export const setWalletEndDate = (data) => {
    return {
        type: SET_V_END_DATE,
        payload: data,
    };
};

export const getVendorWallet = (startDate, endDate, retailer_id) => {
    // console.log("getWallet");
    return (dispatch) => {
        // console.log("getWallet dispatch");
        MembersService.getWallet(startDate, endDate, retailer_id).then((res) => {
            const wallet = res.data;
            dispatch({
                type: SET_V_ALLWALLET,
                payload: wallet,
            });
        });
    };
};
