import { IonButton } from "@ionic/react";
import { TextField } from "../../components/text-field/text-field";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";

export const Screen30 = () => {
  return (
    <ScrollpageWithHeader title="メールアドレス編集"  id="screen-30">
      <div className="px-6 mt-6">
        <TextField
          placeholder="カード番号を入力してください"
          value="katrina@email.com"
          onChange={(e: any) => {}}
          type="text"
        />

        <TextField
          placeholder="新しいメールアドレス"
          value={""}
          onChange={(e: any) => {}}
          type="text"
        />

        <TextField
          placeholder="確認用メールアドレス"
          value={""}
          onChange={(e: any) => {}}
          type="text"
        />
      </div>

      <div className="px-4">
        <IonButton expand="block" color="light">
          保存
        </IonButton>
      </div>
    </ScrollpageWithHeader>
  );
};
