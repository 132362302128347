import { InformationCircleIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { selectRetailerForAccount } from "../../../redux/retailers/retailers.actions";
import { setMemberWallet } from "../../../redux/wallet/wallet.actions";
import DataService from "../../services/data.service";
import MembersService from "../../services/members.service";
import card1 from "./card1.png";
import { RetailerPoints } from "./retailer-points";

const mapStateToProps = (state: any) => {
  return {
    state: state,
    count: state.counter.count,
    wallet: state.filteredAllwallet,
    retailers: state.retailers,
    isAppLoading: state.global.isAppLoading,
    selectedStore: state.stores.selectedStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setMemberWallet: (data: any) => dispatch(setMemberWallet(data)),
    selectRetailerForAccount: (data: any) =>
      dispatch(selectRetailerForAccount(data)),
  };
};

interface Props {
  wallet: any;
  selectedStore: any;
  retailers: {
    list: any[];
  };
  selectRetailerForAccount: Function;
}

export const MemberHolderPointComponent = (props: Props) => {
  const [wallet, setWallet] = useState<any>(null);
  // const [holder, setHolder] = useState<any>(null);
  const history = useHistory();
  useEffect(() => {
    const selectedStore = props.selectedStore ? props.selectedStore : {retailer_id: null};
    MembersService.getWallet(null, null, selectedStore.retailer_id)
      .then((responseJson) => {
        if (responseJson.data) {
          setWallet(responseJson.data.paid);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (wallet === null) {
    return null;
  }

  const pointsByRetailer: any = {};

  props.wallet.paid.forEach((p: any) => {
    pointsByRetailer[p.retailer_id] = pointsByRetailer[p.retailer_id] || 0;
    pointsByRetailer[p.retailer_id] =
      pointsByRetailer[p.retailer_id] + p.amount;
  });

  props.wallet.pending.forEach((p: any) => {
    pointsByRetailer[p.retailer_id] = pointsByRetailer[p.retailer_id] || 0;
    pointsByRetailer[p.retailer_id] =
      pointsByRetailer[p.retailer_id] + p.amount;
  });

  props.wallet.to_be_paid.forEach((p: any) => {
    pointsByRetailer[p.retailer_id] = pointsByRetailer[p.retailer_id] || 0;
    pointsByRetailer[p.retailer_id] =
      pointsByRetailer[p.retailer_id] + p.amount;
  });

  props.wallet.validated.forEach((p: any) => {
    pointsByRetailer[p.retailer_id] = pointsByRetailer[p.retailer_id] || 0;
    pointsByRetailer[p.retailer_id] =
      pointsByRetailer[p.retailer_id] + p.amount;
  });

  return (
    <div className="border-t border-gray-200 mt-4">
      {props.retailers.list.map((retailer) => (
        <RetailerPoints
          key={retailer.id}
          onClick={() => {
            props.selectRetailerForAccount(retailer);
            history.push(`/account/retailer/${retailer.id}`);
          }}
          points={pointsByRetailer[retailer.id]}
          retailer={retailer}
        />
      ))}

    </div>
  );
};

export const MemberHolderPoint = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberHolderPointComponent);
