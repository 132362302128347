import { IonAlert } from "@ionic/react";
interface Props {
  open: boolean;
  setOpen: Function;
  handleConfirm: Function;
}
export const NoStorePopup = (props: Props) => {
  return (
    <IonAlert
      mode="ios"
      isOpen={true}
      onDidDismiss={() => {}}
      cssClass="confirm-modal"
      header={"店舗を登録してください"}
      backdropDismiss={false}
      buttons={[
        {
          text: "閉じる",
          cssClass: "py-6",
          handler: () => {
            props.handleConfirm();
          },
        },
      ]}
    />
  );
};
