import { SET_SELECTED_TAB } from "./tabs.types";

const INITIAL_STATE = {
  selected: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  
  switch (action.type) {
    case SET_SELECTED_TAB:
      return {
        ...state,
        selected: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
