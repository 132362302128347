import {
    SET_RETAILER_LIST,
    SELECT_RETAILER_FOR_ACCOUNT,
    SET_RETAILERS_LOADING,
    SET_RETAILER,
    SET_PREFECTURE,
    SET_PREFECTURE_LIST,
    SET_CITY_LIST,
    SET_CITY,
    SET_AREA,
    SET_AREA_LIST,
    SET_STORES_LIST,
    SET_STORE,
    ADD_FAVORITE_STORE, ADD_FAVORITE_STORE_ERROR, RESET_ERROR, RESET_HIERARCHY, SET_SELECTION_MODE
} from "./retailers.types";

const INITIAL_STATE = {
    list: [],
    selected: null,
    selectedForAccount: null,
    prefecture: null,
    prefectureList: null,
    cities: null,
    city: null,
    areas: null,
    area: null,
    stores: null,
    store: null,
    loading: true,
    error: false,
    errorMessage: null,
    favoriteStores: [],
    selectionMode: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SELECT_RETAILER_FOR_ACCOUNT:
            return {
                ...state,
                selectedForAccount: action.payload,
            };

        case SET_RETAILER_LIST:
            return {
                ...state,
                list: action.payload,
                // selected: action.payload[0],
                loading: false
            };

        case SET_RETAILER:
            return {
                ...state,
                selected: action.payload,
                selectedForAccount: action.payload
            };
        case RESET_HIERARCHY:
            return {
                ...state,
                prefecture: null,
                prefectureList: null,
                cities: null,
                city: null,
                areas: null,
                area: null,
                stores: null,
                store: null,
                selectionMode: true
            };






        case SET_PREFECTURE_LIST:
            return {
                ...state,
                prefectureList: action.payload,
                loading: false
            };

        case SET_PREFECTURE:
            return {
                ...state,
                prefecture: action.payload
            };







        case SET_CITY_LIST:
            return {
                ...state,
                cities: action.payload,
                loading: false
            };

        case SET_CITY:
            return {
                ...state,
                city: action.payload
            };







        case SET_AREA_LIST:
            return {
                ...state,
                areas: action.payload,
                loading: false
            };

        case SET_AREA:
            return {
                ...state,
                area: action.payload
            };




        case SET_STORES_LIST:
            return {
                ...state,
                stores: action.payload,
                loading: false
            };

        case SET_STORE:
            return {
                ...state,
                store: action.payload
            };

        case SET_SELECTION_MODE:
            return {
                ...state,
                selectionMode: action.payload
            };


        case ADD_FAVORITE_STORE:
            return {
                ...state,
                favoriteStores: action.payload
            };

        case ADD_FAVORITE_STORE_ERROR:
            return {
                ...state,
                error: true,
                errorMessage: action.payload
            };
        case RESET_ERROR:
            return {
                ...state,
                error: false,
                errorMessage: null
            };
        case SET_RETAILERS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
