import {
  REMOVE_EVENT,
  SET_APP_ERROR,
  SET_APP_LOADING,
  SET_EVENT,
  SET_SOCIAL_AUTHENTICATED,
  SET_LIFF_ID,
  SET_USER,
  SET_FIRST_LOAD,
  SET_NOTIFICATION_COUNT,
  SET_STORE_SELECTION
} from "./global.types";

const INITIAL_STATE = {
  isAppLoading: true,
  event: null,
  first_load:true,
  appError: false,
  isSocialAuthenticated: false,
  user: null,
  notificationsCount:0,
  LIFF_ID: null,
  storeSelection: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case SET_APP_LOADING:
      return {
        ...state,
        isAppLoading: action.payload,
      };
    case SET_APP_ERROR:
      return {
        ...state,
        appError: action.payload,
      };
    case SET_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case REMOVE_EVENT:
      return {
        ...state,
        event: action.payload,
      };

      case SET_SOCIAL_AUTHENTICATED:
      return {
        ...state,
        isSocialAuthenticated: action.payload,
      };
      case SET_LIFF_ID:
      return {
        ...state,
        LIFF_ID: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_FIRST_LOAD:
      return {
        ...state,
        first_load: action.payload,
      };
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationsCount: action.payload,
      };
      case SET_STORE_SELECTION:
      return {
        ...state,
        storeSelection: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
