import {
  SET_FAVORITE_STORES,
  SET_SELECTED_STORE,
  SET_STORE_LOADING,
  STORES_AT_LEVEL_CITY,
  STORES_AT_LEVEL_PREFECTURE,
  STORES_AT_LEVEL_RETAILER,
} from "./stores.types";
import MembersService from "../../line/services/members.service";
import DataService from "../../line/services/data.service";

export const setFavoriteStores = (data) => {
  return {
    type: SET_FAVORITE_STORES,
    payload: data,
  };
};

export const setSelectedStore = (data) => {
  return {
    type: SET_SELECTED_STORE,
    payload: data,
  };
};

export const setStoreLoading = (data) => {
  return {
    type: SET_STORE_LOADING,
    payload: data,
  };
};

export const actionGetStoresAtLevelRetailer = (retailer) => {
  return dispatch => {
    DataService.getStoresAtLevelRetailer(retailer)
        .then(res => {
          const stores = res.data.items;
          dispatch({
            type: STORES_AT_LEVEL_RETAILER,
            payload: stores
          });
        })
  };
};

export const actionGetStoresAtLevelPrefecture = (retailer, prefecture) => {
  return dispatch => {
    DataService.getStoresAtLevelPrefecture(retailer, prefecture)
        .then(res => {
          const stores = res.data.items;
          dispatch({
            type: STORES_AT_LEVEL_PREFECTURE,
            payload: stores
          });
        })
  };
};

export const actionGetStoresAtLevelCity = (retailer, prefecture, city) => {
  return dispatch => {
    DataService.getStoresAtLevelPrefecture(retailer, prefecture, city)
        .then(res => {
          const stores = res.data.items;
          dispatch({
            type: STORES_AT_LEVEL_CITY,
            payload: stores
          });
        })
  };
};
