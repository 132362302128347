import { IonButton, IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import MembersService from "../../line/services/members.service";
import { ScrollpageWithHeader } from "../../line/screens/scrollpage-with-header/scrollpage-with-header";
import { TextField } from "../../line/components/text-field/text-field";
import AuthService from "../../line/services/auth.service";
import liff from "@line/liff";
import { NewUpdatedTerms } from "../../line/screens/account-screens/account-terms/account-terms";
import { connect } from "react-redux";
let iyId = process.env.REACT_APP_IY_ID as string;
let ybId = process.env.REACT_APP_YB_ID as string;
let waonId = process.env.REACT_APP_WAON_ID as string;
let izumiId = process.env.REACT_APP_IZUMI_ID as string;

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}
function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const mapStateToProps = (state: any) => {
  return {
  isSocialAuthenticated: state.global.isSocialAuthenticated,
  LIFF_ID: state.global.LIFF_ID
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
      
    
  };
};

interface Props {
  isSocialAuthenticated: any;
  LIFF_ID: any;
}

export const TermsPageComponent = (props: Props) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "virtualPageview",
      pageTitle: "T&CPage",
      pageUrl: window.location.pathname, // replace page url with current url
      dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
    });
  }, []);
  let queryParams = useQueryParams();
  // let LIFF_ID = process.env.REACT_APP_LIFF_ID as string
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  let retailerId = localStorage.getItem('app_retailer_id')
  const getLiffId = () => {
    let LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL as string
    if (queryParams.get('campaign')) {
        if (!queryParams.get('retailerId')) {
            // console.log(queryParams.get('retailers'))
            LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL_CAMPAIGN as string
            // localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
        } else if (queryParams.get('retailerId') === iyId) {
            LIFF_ID = process.env.REACT_APP_LIFF_ID_IY_CAMPAIGN as string
        } else if (queryParams.get('retailerId') === ybId) {
            LIFF_ID = process.env.REACT_APP_LIFF_ID_YB_CAMPAIGN as string
        } 
    } else{
        if (!queryParams.get('retailerId')) {
            // console.log(queryParams.get('retailers'))
            LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL as string
            // localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
        } else if (queryParams.get('retailerId') === iyId) {
            LIFF_ID = process.env.REACT_APP_LIFF_ID_IY as string
        } else if (queryParams.get('retailerId') === ybId) {
            LIFF_ID = process.env.REACT_APP_LIFF_ID_YB as string
        } else if (queryParams.get('retailerId') === izumiId) {
            LIFF_ID = process.env.REACT_APP_LIFF_ID_YouMe as string
        } else if (queryParams.get('retailerId') === waonId) {
            LIFF_ID = process.env.REACT_APP_LIFF_ID_WAON as string
        }
    }
    return LIFF_ID;
}
  // if (!retailerId) {
  //   // console.log(queryParams.get('retailers'))
  //   LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL as string
  //   // localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
  // }
  // else if (retailerId === iyId) {
  //   LIFF_ID = process.env.REACT_APP_LIFF_ID_IY as string
  // } else if (retailerId === ybId) {
  //   LIFF_ID = process.env.REACT_APP_LIFF_ID_YB as string
  // } else if (retailerId === waonId) {
  //   LIFF_ID = process.env.REACT_APP_LIFF_ID_WAON as string
  // } else if (retailerId === izumiId) {
  //   LIFF_ID = process.env.REACT_APP_LIFF_ID_YouMe as string
  // }
  useEffect(() => {
    setDefaultEmail();
    return () => { };
  }, []);

  const setDefaultEmail = async () => {
    let LIFF_ID = props.LIFF_ID;
    if(!props.LIFF_ID){
      LIFF_ID = getLiffId();
    }
    liff
      .init({ liffId: LIFF_ID })
      .then(async () => {
        console.log(liff.isLoggedIn());
        console.log(await liff.getDecodedIDToken())
        if (liff.isLoggedIn()) {
          console.log(
            (await liff.getDecodedIDToken())
              ? (await liff.getDecodedIDToken()).email
              : ""
          );
          setEmail(
            (await liff.getDecodedIDToken())
              ? (await liff.getDecodedIDToken()).email
              : ""
          );
          setConfirmEmail(
            (await liff.getDecodedIDToken())
              ? (await liff.getDecodedIDToken()).email
              : ""
          );
          // setLineUser(await liff.getProfile());
          // setLineId((await liff.getProfile()).userId);
          // console.log(
          //     "Line profile" + ((await liff.getProfile()).userId)
          // );
          // console.log("AccessToken" + liff.getAccessToken());
          // console.log("IdToken" + liff.getIDToken());
          // console.log("DecodedIdToken" + await liff.getDecodedIDToken().aud);
          // console.log("DecodedIdToken" + await liff.getDecodedIDToken().iat);
          // console.log("DecodedIdToken" + await liff.getDecodedIDToken().iss);
          // console.log("DecodedIdToken" + await liff.getDecodedIDToken().name);
          // console.log("DecodedIdToken" + await liff.getDecodedIDToken().sub);
          // console.log("DecodedIdToken" + await liff.id);
          // console.log("DecodedIdToken" + await liff.getAId);
          // console.log("DecodedIdToken" + await liff.getContext().tid);
          // console.log("DecodedIdToken" + await liff.getContext().userId);
          // console.log("DecodedIdToken" + await liff.getContext().utouId);

          // console.log("AddId"+ await liff.getAdvertisingId());
          // console.log("ProfilePlus" + liff.getProfilePlus().regionCode);
          // await handleLogin((await liff.getProfile()).userId);

          // await liff.sendMessages([
          //   {
          //     type: "text",
          //     text: "You've successfully sent a message! Hooray!",
          //   },
          // ]);
        } else {
          liff.login();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log(
    //   (await liff.getDecodedIDToken())
    //     ? (await liff.getDecodedIDToken()).email
    //     : ""
    // );
    // setEmail(
    //   (await liff.getDecodedIDToken())
    //     ? (await liff.getDecodedIDToken()).email
    //     : ""
    // );
    // setConfirmEmail(
    //   (await liff.getDecodedIDToken())
    //     ? (await liff.getDecodedIDToken()).email
    //     : ""
    // );
  };
  const handleSignup = async () => {
    const userId = (await liff.getProfile()).userId;
    AuthService.signupSocial(email, "", {
      type: 1,
      id: userId,
    })
      // AuthService.signup(email, password)
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "virtualPageview",
          pageTitle: "sign-up",
          pageUrl: window.location.pathname, // replace page url with current url
          dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
        });
        // eventBus.on("GTM_EVENT", async function () {
        //   window.dataLayer = window.dataLayer || [];
        //   window.dataLayer.push({
        //     event: "virtualPageview",
        //     pageTitle: "sign-up",
        //     pageUrl: window.location.pathname, // replace page url with current url
        //     dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
        //   });
        // });

        // handleLogin(userId);
        AuthService.socialLogin(liff.getAccessToken(), userId)
          .then((response) => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "virtualPageview",
              pageTitle: "login",
              pageUrl: window.location.pathname, // replace page url with current url
              dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
            });
            // eventBus.on("GTM_EVENT", async function () {
            //   window.dataLayer = window.dataLayer || [];
            //   window.dataLayer.push({
            //     event: 'virtualPageview',
            //     pageTitle: 'login',
            //     pageUrl: window.location.pathname, // replace page url with current url
            //     dm_screen_type: 'other' // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
            //   });
            // });
            localStorage.setItem("user-data", JSON.stringify(response.data));
            return MembersService.getFavoriteStores();
          })
          .then((response) => {
            history.push("/tabs/home");
          })
          .catch((err) => {
            setErrorMessage(err.response.data.error);
            setOpenErrorModel(true);
          });
      })
      .catch((err) => {
        setErrorMessage(err.response.data.error);
        setOpenErrorModel(true);
      });
  };
  // const handlePatch = () => {
  //     MembersService.patchUser({email})
  //         .then((res) => {
  //             console.log("user patch update res ", res);
  //             history.push("/2");
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //             setErrorMessage(err.response.data.error);
  //             setOpenErrorModel(true);
  //         });
  // };

  return (
    <ScrollpageWithHeader title="メルアドレスを登録" id="terms">
      <div className="px-6 mt-6">
        {openErrorModel && (
          <div className="text-red-600 text-xs mt-3 mb-10">{errorMessage}</div>
        )}
        <div className="account-editing text-sm font-bold">メールアドレス</div>
        <TextField
          placeholder="メールアドレスを入力してください"
          value={email}
          onChange={(value: any) => setEmail(value)}
          type="email"
        />
        {email && !validateEmail(email) && (
          <div className="text-red-600 text-xs -mt-3 mb-5">
            メールアドレスを正しく入力してください。
          </div>
        )}

        <div className="account-editing text-sm font-bold">
          メールアドレス(確認用)
        </div>
        <TextField
          placeholder="もう一度入力してください"
          value={confirmEmail}
          onChange={(value: any) => setConfirmEmail(value)}
          type="email"
        />
        {confirmEmail && !validateEmail(confirmEmail) && (
          <div className="text-red-600 text-xs -mt-3 mb-5">
            確認用のメールアドレスを入力してください。
          </div>
        )}

        {email &&
          confirmEmail &&
          validateEmail(confirmEmail) &&
          email !== confirmEmail && (
            <div className="text-red-600 text-xs -mt-3 mb-5">
              確認用のメールアドレスが一致していません。
            </div>
          )}

        <div className="font-bold text-sm" style={{ marginTop: "3.375rem" }}>利用規約</div>
        <div className="text-sm text-black flex overflow-auto h-60 ...">
          <NewUpdatedTerms />
        </div>
        {/* <div className="text font-bold">1.利用資格</div>
          <div className="text-sm">
            本サービス内のお酒のクーポンをご利用頂けるのは、20歳以上の方に限られます。
          </div> */}
      </div>
      {/* <div className="px-4"> */}
      <div>
        <IonItem lines="none" className="my-3 flex items-center">
          <IonCheckbox
            mode="md"
            disabled={false}
            checked={checked}
            onIonChange={(e) => setChecked(e.detail.checked)}
          />
          <IonLabel className="ml-2" style={{ fontSize: "0.875rem" }}>利用規約に同意する</IonLabel>
        </IonItem>
      </div>
      {/* </div>
      <div className="px-4"> */}
      <IonButton
        className="mx-4 h-12 mb-6 rounded text-875 disabled-DFDFDF rounded-25-rem"
        style={{ minHeight: 48 }}
        disabled={
          !email ||
          !confirmEmail ||
          !validateEmail(email) ||
          !validateEmail(confirmEmail) ||
          email !== confirmEmail ||
          !checked
        }
        onClick={handleSignup}
        expand="block"
        color="primary"
      >登録する
      </IonButton>
      {/* </div> */}
      {/* {openErrorModel && (
        <InformModal
          message={errorMessage}
          open={openErrorModel}
          setOpen={setOpenErrorModel}
        />
      )} */}
    </ScrollpageWithHeader>
  );
};

export const TermsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsPageComponent);

