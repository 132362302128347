import step1 from "../../../line/screens/screen1/step-images/step1.png";
import step2 from "../../../line/screens/screen1/step-images/step2.png";

import marker1 from "./markers/marker1.png";
import marker2 from "./markers/marker2.png";

export const StepHeader1 = () => {
    return (
        <div className="flex justify-between p-6 border-b items-end relative">
            <div className="absolute top-5 right-6">
                <img src={marker1} className="h-1" />
            </div>
            <div className="flex-1">
                <div className="font-semibold text-red-500">Step1</div>
                <div className="pt-1">会員登録をしてください</div>
            </div>
            <img src={step1} className="w-24 object-contain" />
        </div>
    );
}

export const StepHeader2 = () => {
    return (
        <div className="flex justify-between p-6 border-b items-end relative">
            <div className="absolute top-5 right-6">
                <img src={marker2} className="h-1" />
            </div>
            <div className="flex-1">
                <div className="font-semibold text-red-500">Step2</div>
                <div className="pt-1">よく行くお店を登録してください</div>
            </div>
            <img src={step2} className="w-12 object-contain" />
        </div>
    );
}