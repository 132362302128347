import { CheckIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { IonAlert, IonModal, useIonToast } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {useHistory, useLocation} from "react-router-dom";
import { InformModal } from "../../../components/inform-modal/inform-modal";
import { setSelectedOffer } from "../../../redux/offer-details/offer-details.actions";
import { eventBus } from "../../event-bus/event-bus";
import MembersService from "../../services/members.service";
import './offer-card.scss'
import {
  fetchBasket,
  fetchOffers,
} from "../../../redux/offer-list/offer-list.actions";
import {useOnScreen} from "../useOnScreen";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
interface Props {
  cartItemId?: number;
  offer: any;
  expiredAt: any;
  bestMode?: boolean;
  loadMoreMode?: boolean;
  homeScreenMode?: boolean;
  stores: any;
  added?: boolean;
  basketMode?: boolean;
  loading?: any;
  basket: any[];
  setAdded?: Function;
  pixelApi?: Function;
  addButtonDisabled?: boolean;
  setSelectedOffer: Function;
  cards: any[];
  cardsLoading: boolean;
  retailer: any;
  fetchOffers: Function;
  fetchBasket: Function;
}

const cardStyle = {
  boxShadow: "rgb(0 0 0 / 10%) 0px 4px 16px",
};

const mapStateToProps = (state: any) => {
  return {
    count: state.counter.count,
    offerDetails: state.offerDetails,
    basket: state.offerList.basket,
    stores: state.stores,
    cards: state.cards.holders,
    cardsLoading: state.cards.loading,
    retailer: state.retailers.selected,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedOffer: (data: any) => dispatch(setSelectedOffer(data)),
    fetchOffers: () => dispatch(fetchOffers()),
    fetchBasket: () => dispatch(fetchBasket()),
  };
};
function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const OfferCardComponent = (props: Props) => {
  
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  const selectedStore = props.stores.selectedStore ? props.stores.selectedStore : { retailer_id: "-1" };
  const basketOfferIds = props.basket.map((b) => b.offer.id);
  const [showDelete, setShowDelete] = useState(false);
  const isAddedToBasket = basketOfferIds.indexOf(props.offer.id) > -1;
  const [errorMessage, setErrorMessage] = useState<string>("");
  let holder = props.cards.filter(
    (c) => c.retailer_id === parseInt(selectedStore.retailer_id)
  )[0]
  let queryParams = useQueryParams();
  let debug  = queryParams.get('debug');

  const setSelectedOffer = () => {
    return MembersService.getCouponDetail(props.offer.id);
  };
  const ref = useRef(null);
  const componentView = useOnScreen(ref);

  return (
    <div
        className={classNames("relative flex flex-col m-0 overflow-hidden rounded-md product bg-white shadow-sm", props.bestMode && (props.offer.hasOwnProperty('default_target') && props.offer.default_target === false) ? "border-2 border-catalina-base":"")}
        // className={classNames("relative flex flex-col m-0 overflow-hidden rounded-md product bg-white shadow-md")}
        ref={ref}
      data-id={props.offer.id}
    // style={cardStyle}
    >
      <IonAlert
        isOpen={showDelete}
        onDidDismiss={() => setShowDelete(false)}
        cssClass="confirm-modal"
        header={"確認"}
        message={
          '<p class="text-gray-400">選択したクーポンを <br/> バスケットから削除しますか？</p>'
        }
        buttons={[
          {
            text: "いいえ",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              setShowDelete(false);
            },
          },
          {
            text: "はい",
            handler: () => {
              MembersService.removeCouponFromBasket(props.cartItemId).then(
                () => {
                  // eventBus.dispatch("refetchBasket", {});
                  props.fetchOffers();
                  props.fetchBasket();
                  // eventBus.dispatch("refetchOffers", {});
                  setShowDelete(false);
                }
              );

              console.log("Confirm Okay");
            },
          },
        ]}/**/
      />
      <div className="flex flex-col justify-center flex-1 py-2">
        <div>
          {props.bestMode && (props.offer.hasOwnProperty('default_target') && props.offer.default_target === false) &&
              <div className="speech bottom">
                あなただけ
              </div>
          }
          {debug === 'true' && props.offer.id}
          <LazyLoadImage
              alt={props.offer.id}
              height={0}
              src={props.offer.picture_url} // use normal <img> attributes as props
              width={0}
              onLoad={() => props.pixelApi(props.offer)}
          />
          <img
            id={props.offer.id}
            className="h-24 w-24 block m-auto mt-2 object-contain"
            // className="h-24 w-24 block m-auto"
            src={props.offer.picture_url}
            onClick={async () => {
              if (
                (!selectedStore || selectedStore.retailer_id === "-1" ||
                  selectedStore.id === null ||
                  selectedStore.id === "undefined") || (props.cards.length === 0 && props.cardsLoading === false) ||
                  props.loadMoreMode
              ) {
                return false;
              }
              const offer = await setSelectedOffer();
              props.setSelectedOffer({
                ...offer.data,
                isAddedInBasket: props.added,
              });
              history.push("/offer-details");
            }}
          />
          {/* {isAddedToBasket && props.basketMode && (
            <XIcon
              onClick={() => {
                setShowDelete(true);
              }}
              className="absolute w-7 h-7 p-1 text-gray-400 top-2 right-2"
            />
          )} */}
        </div>
        <div>
          {isAddedToBasket && props.basketMode && (
            <div className="px-3 pt-1 overflow-hidden text-center text-red-500 whitespace-nowrap overflow-ellipsis">
              {props.expiredAt} まで
            </div>
          )}
          <div className="px-3 mt-1 overflow-hidden font-bold text-center whitespace-nowrap overflow-ellipsis">
            {props.offer.discount_value}P付与
          </div>
          <div className="px-3 mt-1 overflow-hidden text-sm text-center whitespace-nowrap overflow-ellipsis text-gray-400">
            {props.offer.title}
          </div>
          <div className="px-3 text-sm text-center text-gray-400">
            {props.offer.subtitle}
          </div>
        </div>
      </div>
      
      {!props.homeScreenMode && (
        <button
            disabled={loading}
          onClick={() => {
            if (props.homeScreenMode) {
              return false;
            }
            if (isAddedToBasket) {
              return false;
            }
            if (
              (!selectedStore || selectedStore.retailer_id === "-1" ||
                selectedStore.id === null ||
                selectedStore.id === "undefined")
            ) {
              setErrorMessage("お店を登録してください");
              setOpenErrorModel(true);
              return false;
            }
            let holder = props.cards.filter(
              (c) => c.retailer_id === parseInt(selectedStore.retailer_id)
          )[0]
            if ((props.cards.length === 0 && props.cardsLoading === false)||!holder) {
              // present("ポイントを獲得するにはカードを登録してください", 2000);
              // console.log("Please register a card to get the points");
              setErrorMessage("ポイントを獲得するにはカードを登録してください");
              setOpenErrorModel(true);
              return false;
            }
            setLoading(true);
            MembersService.addCouponToBasket(props.offer.id, selectedStore)
              .then((response) => {
                setLoading(false);
                // eventBus.dispatch("refetchBasket", {});
                props.fetchOffers();
                props.fetchBasket();
              })
              .catch((error) => {
                setLoading(false);
                if (error.response && error.response.data) {
                  setErrorMessage(error.response.data.error);
                  setOpenErrorModel(true);
                }
              });
          }}
          className={classNames(
            "mx-3 mb-3 p-3 py-1  rounded-md",
            selectedStore.retailer_id === "-1"?"bg-catalina-base text-white":
            (props.added || props.basketMode) && !props.homeScreenMode
              ? (props.basketMode) ? "font-bold" : "bg-white text-gray-600 border-gray-200 border-2"
              : "bg-catalina-base text-white"
          )}
        >
          <div className="flex items-center justify-center font-bold text-center text">
            {!props.basketMode && (
              <div className="py-1">
                {isAddedToBasket ? "セット済み" : "セットする"}
              </div>
            )}
            {loading ? (
              <div className="w-4 h-4 ml-2">
                <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />
              </div>
            ) : null}
            <div>
              {selectedStore.retailer_id === "-1"?null:isAddedToBasket && !props.basketMode && !props.homeScreenMode ? (
                <CheckIcon className="w-5 h-5" />
              ) : null}
            </div>
          </div>
        </button>
      )}
{isAddedToBasket && props.basketMode && (
  
            <div
              onClick={() => {
                setShowDelete(true);
              }}
              style={{
                fontSize:"13px"
              }}
              className={classNames(
                "-mt-5 mx-3 mb-3 text-center rounded-md bg-white px-5 py-3 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                selectedStore.retailer_id === "-1"?"bg-catalina-base text-white":
                (props.added || props.basketMode) && !props.homeScreenMode
                  ? (props.basketMode) ? "font-bold" : "bg-white text-gray-600 border-gray-200 border-2"
                  : "bg-catalina-base text-white"
              )}
            >
              取り消し
            </div>
          )}
      {props.homeScreenMode && (
          <div
              onClick={() => {
                if (props.homeScreenMode) {
                  return false;
                }
              }}
              className={
                  "mx-3 mb-2 p-3 py-1 rounded-md bg-white text-gray-600"
              }
          >
            <div className="flex items-center justify-center font-bold text-center text">
              {!props.basketMode && (
                  <div>
                    {props.offer.discount_value}ポイント</div>
              )}

              <div>
                {selectedStore.retailer_id === "-1"?null:isAddedToBasket && !props.basketMode && !props.homeScreenMode ? (
                    <CheckIcon className="w-5 h-5" />
                ) : null}
              </div>
            </div>
          </div>
      )}

      {/* {isAddedToBasket && (
        <div className="flex items-center justify-center p-3 mx-3 mb-2 font-bold text-center text-black text">
          <div>{props.offer.discount_value}ポイント</div>
        </div>
      )} */}
      {openErrorModel && (
        <IonAlert
          mode="ios"
          isOpen={openErrorModel}
          onDidDismiss={() => {
            setOpenErrorModel(false);
          }}
          cssClass="confirm-modal"
          message={`<p class="text-base font-semibold">${errorMessage}</p>`}
          buttons={[
            {
              text: "閉じる",
              cssClass: "py-6",
              handler: () => { return true },
            },
          ]}
        />
        // <InformModal
        //   message={errorMessage}
        //   open={openErrorModel}
        //   setOpen={setOpenErrorModel}
        // />
      )}
    </div>
  );
};

export const OfferCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferCardComponent);
