import {
  SET_BASKET_LIST,
  SET_OFFER_LIST,
  SET_OFFERS_LOADING,
} from "./offer-list.types";

const INITIAL_STATE = {
  basketPoints: 0,
  first6offers: [],
  offers: [],
  nextgenOffers:[],
  nextgenIds:[],
  bestOffers: [],
  nonBestOffers: [],
  offersLoading: false,
  basket: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OFFER_LIST:
      return {
        ...state,
        first6offers: action.payload[3] || [],
        offers: action.payload[2],
        bestOffers: action.payload[1],
        nonBestOffers: action.payload[0],
        nextgenOffers: action.payload[4],
        nextgenIds:action.payload[5]
      };

    case SET_OFFERS_LOADING:
      return {
        ...state,
        offersLoading: action.payload,
      };

    case SET_BASKET_LIST:
      let sumOfPoints = 0;
      action.payload.map((reward) => {
        sumOfPoints = sumOfPoints + reward.offer.discount_value;
      });

      return {
        ...state,
        basketPoints: sumOfPoints,
        basket: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
