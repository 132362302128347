import { IonButton, IonItem, IonLabel, IonRippleEffect, useIonToast } from "@ionic/react";
import { TextField } from "../../../components/text-field/text-field";
import { ScrollpageWithHeader } from "../../scrollpage-with-header/scrollpage-with-header";
import "./account-editing.scss";
import { Popup2 } from "./popup2";
import { useEffect, useState } from "react";
import MembersService from "../../../services/members.service";
import { Popup1 } from "./popup1";
import { InformModal } from "../../../../components/inform-modal/inform-modal";
import { useHistory } from "react-router";
import { eventBus } from "../../../event-bus/event-bus";
import liff from "@line/liff";
import {connect} from "react-redux";
import {Screen9Component} from "../../screen9/screen9";
import {setAccountMode, setHolderCards} from "../../../../redux/holder-cards/holder-cards.actions";
import {fetchCategories, fetchOffers} from "../../../../redux/offer-list/offer-list.actions";
import {setSelectedTab} from "../../../../redux/tabs/tabs.actions";

function validateEmail(email: string) {
    const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
const mapStateToProps = (state: any) => {
    return {
        cards: state.cards.holders,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};

interface Props {
    cards: any[];
}
export const AccountEditingComponent = (props: Props) => {
    const history = useHistory();
    const [member, setMember] = useState<any>({ email: '' });
    const [email, setEmail] = useState<any>("");
    const [isChecked, setIsChecked] = useState(false);

    const [confirmEmail, setConfirmEmail] = useState<any>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
    const [showDeleteConfirmModel, setShowDeleteConfirmModel] = useState<boolean>(false);
    const [showDeletedModel, setShowDeletedModel] = useState<boolean>(false);
    const [present, dismiss] = useIonToast();

    const getMember = () => {
        MembersService.getMember().then((res) => {
            setMember(res.data);
            setIsChecked(res.data?.news_optin)
        });
    };
    const deleteMember = () => {
        var promises: any[] = [];

        props.cards.forEach((card)=>{
            console.log(card)
            promises.push(
                MembersService.patchPaperless(card.retailer, false).then((res)=>{
                    console.log(res)
                }).catch((err)=>{
                    console.log("Problem in setting paperless to false");
                })
            )
        })
        Promise.all(promises).then(() =>
            {
                console.log('All cards paperless set to false')
                MembersService.deleteMember().then(() => {
                    localStorage.clear();
                    setShowDeletedModel(true);
                    liff.logout();
                    liff.closeWindow();
                });
            }
        );


    };
    const handleOnChange = () => {
        setIsChecked(!isChecked);
        handlePatchForOptIn(!isChecked);
    };
    const handlePatchForOptIn = (isChecked:boolean) => {
        MembersService.patchMember({ news_optin: isChecked })
            .then((res) => {
                eventBus.dispatch('refetchMember', {});
                setMember(res.data);
                // setErrorMessage("Congrats email registered successfully");
                // history.goBack();
                // present("メールが正常に登録されました", 2000);
                // setOpenErrorModel(true);
            }).catch((err) => {
            setErrorMessage(err.response.data.error);
            setOpenErrorModel(true);
        })
    };
    const handlePatch = () => {
        MembersService.patchMember({ email, news_optin: isChecked })
            .then((res) => {
                eventBus.dispatch('refetchMember', {});
                setMember(res.data);
                setEmail('');
                setConfirmEmail('');
                // setErrorMessage("Congrats email registered successfully");
                history.goBack();
                present("メールが正常に登録されました", 2000);
                // setOpenErrorModel(true);
            }).catch((err) => {
                setErrorMessage(err.response.data.error);
                setOpenErrorModel(true);
            })
    };
    useEffect(() => {
        getMember();
    }, []);
    return (
        <ScrollpageWithHeader title="アカウント編集" id="account-editing">
            <div className="px-6 mt-6">
                {openErrorModel && (
                    <div className="text-red-600 text-xs -mt-3 mb-10">{errorMessage}</div>
                )}
                <IonLabel className="account-editing font-semibold">メールアドレス</IonLabel>
                <TextField
                    placeholder="電子メールID"
                    value={member?.email!}
                    disabled={true}
                    type="email"
                />

                <IonLabel className="account-editing font-semibold">新しいメールアドレス</IonLabel>
                <TextField
                    placeholder="新しいメールアドレスを入力してください"
                    value={email}
                    onChange={(value: any) => setEmail(value)}
                    type="email"
                />
                {email && !validateEmail(email) && (
                    <div className="text-red-600 text-xs -mt-3 mb-5">
                        メールアドレスを正しく入力してください。
                    </div>
                )}

                <IonLabel className="account-editing font-semibold">メールアドレス(確認用)</IonLabel>
                <TextField
                    placeholder="もう一度入力してください"
                    value={confirmEmail}
                    onChange={(value: any) => setConfirmEmail(value)}
                    type="email"
                />
                {confirmEmail && !validateEmail(confirmEmail) && (
                    <div className="text-red-600 text-xs -mt-3 mb-4">
                        確認用のメールアドレスを入力してください。
                    </div>
                )}
                {email &&
                    confirmEmail &&
                    validateEmail(confirmEmail) &&
                    email !== confirmEmail && (
                        <div className="text-red-600 text-xs -mt-3 mb-10">
                            確認用のメールアドレスが一致していません。
                        </div>
                    )}
            </div>

            <div className="px-4">
                <IonButton disabled={
                    !email ||
                    !confirmEmail ||
                    !validateEmail(email) ||
                    !validateEmail(confirmEmail) ||
                    email !== confirmEmail
                } onClick={handlePatch} expand="block" color="primary" className="text-875 rounded-25-rem disabled-DFDFDF">
                    保存
                </IonButton>
            </div>
            <div className="px-4 mt-4 flex">
                <div className="border flex-1 p-2 rounded-2xl">
                    <IonLabel className="account-editing font-semibold">メールマガジン</IonLabel>
                    <div className="px-6 py-2">
                        <div className="form-check flex">
                            <input
                                type="checkbox"
                                checked={isChecked}
                                id="optIn"
                                onChange={handleOnChange}
                            />

                            <label className="mx-2 text-sm font-bold" htmlFor="optIn">
                                毎日のお買い物がお得になるクーポンプラスからの情報を受け取る
                            </label>
                        </div>
                        <div className="text m-4  text-sm font-bold text-gray-400">
                            チェックを外していても、サービス運営のため重要なメールが送信される場合がございます。ご了承ください。
                        </div>

                    </div>
                </div>


            </div>
            <div className="px-4 mt-4">
                <IonButton expand="block" onClick={() => setShowDeleteConfirmModel(true)} fill="outline" className="text-875 rounded-25-rem border-D9D9D9">
                    アカウントを削除する
                </IonButton>
            </div>

            {
                showDeleteConfirmModel &&
                <Popup1 handleConfirm={deleteMember} open={showDeleteConfirmModel} setOpen={setShowDeleteConfirmModel} />
            }
            {
                showDeletedModel &&
                <Popup2 setOpen={setShowDeletedModel} open={showDeletedModel} confirmClose={() => {
                    history.replace('/1');
                    window.location.reload();
                }} />
            }
            {/* {openErrorModel && (
                <InformModal
                    message={errorMessage}
                    open={openErrorModel}
                    setOpen={setOpenErrorModel}
                />
            )} */}
        </ScrollpageWithHeader>
    );
};
export const AccountEditing = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountEditingComponent);