import google from "../logos/google.png";
import facebook from "../logos/facebook.png";
import twitter from "../logos/twitter.png";

const logos = [
  {
    image: google,
  },
  {
    image: facebook,
  },
  {
    image: twitter,
  },
];

export const SocialIcons = () => {
  return (
    <div className="logos pt-6 flex justify-around mt-6 w-52 m-auto">
      {logos.map((logo) => (
        <div
          className="logo p-4 shadow-md rounded-full"
          style={{
            boxShadow: "1px 3px 11px 0px rgb(0, 0, 0, 0.1)",
          }}
        >
          <img className="h-5 w-5" src={logo.image} />
        </div>
      ))}
    </div>
  );
};
