import { HalfSplitBackgroundShell } from "../../../shells/half-split-background-shell/half-split-background-shell";
import { FieldWithIcon } from "../../../shells/components/field-with-icon/field-with-icon";
import { FullRoundButton } from "../../../shells/components/full-round-button/full-round-button";
import {
  MailIcon,
  UserCircleIcon,
  LockClosedIcon,
} from "@heroicons/react/solid";
import { SocialIcons } from "../social-icons/social-icons";
import { BottomTermsCopywight } from "../bottom-terms-copyright/bottom-terms-copyright";

export const RegisterScreen = () => {
  return (
    <HalfSplitBackgroundShell>
      <FieldWithIcon
        Icon={UserCircleIcon}
        placeholder="Name"
        type="password"
      />
      <FieldWithIcon
        Icon={MailIcon}
        placeholder="Email"
        type="password"
      />
      <FieldWithIcon
        Icon={LockClosedIcon}
        placeholder="Password"
        type="password"
      />
      <FullRoundButton text="パスワード変更" />
      <div className="text-sm text-center text-gray-500 pt-3">
        ログイン画面へ戻る
      </div>

      <SocialIcons />
      <BottomTermsCopywight />
    </HalfSplitBackgroundShell>
  );
};
