import { Listbox } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import {
  CheckIcon,
  SelectorIcon,
  HomeIcon,
  CreditCardIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  UserCircleIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid";
import { ChevronDownIcon } from "@heroicons/react/solid";

const publishingOptions = [
  {
    title: "Favorite Store number 1 (Default)",
    description:
      "Location of the shop. This should be the address of the favorite shop.",
    current: true,
  },
  {
    title: "Favorite Store number 2",
    description:
      "Location of the shop. This should be the address of the favorite shop.",
    current: false,
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const StoreSelector = () => {
  const [selected2, setSelected2] = useState(publishingOptions[0]);
  return (
    <>
      <div className="text-xs text-gray-light pt-8">お気に入り登録店舗選択</div>

      <Listbox value={selected2} onChange={setSelected2}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">
              Change published status
            </Listbox.Label>
            <div className="relative">
              <Listbox.Button className="bg-okuwa_blue relative w-full border-b border-solid border-white py-2 text-left cursor-default focus:outline-none">
                <div className="relative flex justify-between w-full items-center bg-okuwa_blue py-2 pl-1 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                  <div className="flex items-center">
                    <HomeIcon className="h-5 w-5" aria-hidden="true" />
                    <p className="ml-2.5 text-sm font-medium">
                      {selected2.title}
                    </p>
                  </div>
                  <ChevronDownIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-full rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {publishingOptions.map((option) => (
                    <Listbox.Option
                      key={option.title}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-okuwa_blue" : "text-gray-900",
                          "cursor-default select-none relative p-4 text-sm"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <div className="flex items-center">
                          <div className="left-icon w-12">
                            <LocationMarkerIcon
                              className={
                                selected ? "text-white" : "text-okuwa_blue"
                              }
                            />
                          </div>
                          <div className="flex flex-col pl-3">
                            <div className="flex justify-between">
                              <p
                                className={
                                  selected ? "font-semibold" : "font-normal"
                                }
                              >
                                {option.title}
                              </p>
                              {selected ? (
                                <span
                                  className={
                                    active ? "text-white" : "text-indigo-500"
                                  }
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </div>
                            <p
                              className={classNames(
                                active ? "text-indigo-200" : "text-gray-500",
                                "mt-2"
                              )}
                            >
                              {option.description}
                            </p>
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
};
