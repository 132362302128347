import { SET_ALLWALLET, SET_START_DATE, SET_END_DATE } from "./allwallet.types";
import MembersService from "../../line/services/members.service";
import {SET_WALLET} from "../wallet/wallet.types";

export const setAllMemberWallet = (data) => {
  return {
    type: SET_ALLWALLET,
    payload: data,
  };
};

export const refreshAllMemberWallet = (startDate, endDate) => {
  console.log("get All Wallet");
  return dispatch => {
    console.log("refresh getAllWallet dispatch");
    MembersService.getWallet(startDate, endDate)
        .then(res => {
          const wallet = res.data;
          dispatch({
            type: SET_ALLWALLET,
            payload: wallet
          });
        })
  };
};

export const setWalletStartDate = (data) => {
  return {
    type: SET_START_DATE,
    payload: data,
  };
};

export const setWalletEndDate = (data) => {
  return {
    type: SET_END_DATE,
    payload: data,
  };
};

// export const getWallet = (startDate, endDate) => {
//   console.log("getWallet");
//   return (dispatch) => {
//     console.log("getWallet dispatch");
//     MembersService.getWallet(startDate, endDate).then((res) => {
//       const wallet = res.data;
//       dispatch({
//         type: SET_WALLET,
//         payload: wallet,
//       });
//     });
//   };
// };
