import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import catalina from "./catalina.png";
import { useEffect, useState } from "react";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { CalendarIcon } from "@heroicons/react/outline";
import { DateDropdown } from "./dropdown/dropdown";
import MembersService from "../../services/members.service";
import { MemberHolderPoint } from "../../components/member-holder-point/member-holder-point";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  setWalletEndDate,
  setWalletStartDate,
} from "../../../redux/wallet/wallet.actions";
import { useIonViewDidEnter } from "@ionic/react";
import {eventBus} from "../../event-bus/event-bus";
import {
  getWallet,
  refreshAllMemberFilteredWallet
} from "../../../redux/filteredAllwallet/filteredAllwallet.actions";
import {setAccountMode} from "../../../redux/holder-cards/holder-cards.actions";
import {setSelectedTab} from "../../../redux/tabs/tabs.actions";
import {resetVendorWallet, setVendorWalletLoading} from "../../../redux/vendorWallet/vendorWallet.actions";

const mapStateToProps = (state: any) => {
  return {
    startDate: state.wallet.startDate,
    selectedStore: state.stores.selectedStore,
    endDate: state.wallet.endDate,
    paid: state.filteredAllwallet.paid,
    allWallet: state.filteredAllwallet,
    retailers: state.retailers,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setWalletStartDate: (data: any) => dispatch(setWalletStartDate(data)),
    setWalletEndDate: (data: any) => dispatch(setWalletEndDate(data)),
    resetVendorWallet: (data: any) => dispatch(resetVendorWallet()),
    setAccountMode: (data: any) => dispatch(setAccountMode(data)),
    getWallet: (startDate: any, endDate: any) => dispatch(getWallet(startDate, endDate)),
    setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
    refreshAllMemberFilteredWallet: (startDate: any, endDate: any) => dispatch(refreshAllMemberFilteredWallet(startDate, endDate)),
  };
};

interface Props {
  startDate: string;
  endDate: string;
  allWallet: any;
  selectedStore:any;
  paid: any;
  retailers: any;
  getWallet: Function;
  refreshAllMemberFilteredWallet: Function;
  setWalletStartDate: Function;
  resetVendorWallet: Function;
  setWalletEndDate: Function;
  setAccountMode: Function;
  setSelectedTab: Function;
}

export const Screen24Component = (props: Props) => {
  // console.log("screen 24 props: ", props);
  useIonViewDidEnter(() => {
    console.log('ionViewDidEnter event fired on accounts page ');
    props.setSelectedTab('account');
    // props.resetVendorWallet(true);

  });

  const history = useHistory();
  const [member, setMember] = useState<any>(null);
  const [nothing, setNothing] = useState<number>(0);

  useEffect(() => {
    getMember();
    props.setAccountMode(true);
    eventBus.on('refetchMember', () => getMember());
    refetchWallet(props.startDate, props.endDate);
    // props.resetVendorWallet(true);
  }, []);
  const getMember = () => {
    MembersService.getMember().then((res) => {
      setMember(res.data);
    });
  }
  const showProps = () => {
    // console.log("DADAL: ", props.endDate);
    setNothing(nothing + 1);
  }

  useIonViewDidEnter(() => {
    // console.log('ionViewDidEnter event fired: ');
    showProps();
  });

  const refetchWallet = (start: string, end: string) => {
    setTimeout(() => {
      props.getWallet(start, end);
      props.refreshAllMemberFilteredWallet(start, end);
    }, 500);
  };
  let retailer_ids= props.retailers.list.map((retailer:any)=>{return retailer.id})
  
  let sumOfHistoryPoints = 0;
  props.allWallet.paid.forEach((result: any) => {
    if (retailer_ids.includes(result.retailer_id)) {
      sumOfHistoryPoints += result.amount
    }
  });
  props.allWallet.to_be_paid.forEach((result: any) => {
    if (retailer_ids.includes(result.retailer_id)) {
      sumOfHistoryPoints += result.amount
    }
  });
  props.allWallet.pending.forEach((result: any) => {
    if (retailer_ids.includes(result.retailer_id)) {
      sumOfHistoryPoints += result.amount
    }
  });
  props.allWallet.validated.forEach((result: any) => {
    if (retailer_ids.includes(result.retailer_id)) {
      sumOfHistoryPoints += result.amount
    }
  });

  return (
    <ScrollpageWithHeader title="マイアカウント" id="screen-24">
      <div className="flex items-center px-4 py-2 border-b border-gray-200">
        <img className="object-contain" src={catalina} style={{
          height: "3.5rem",
          width: "3.5rem",
        }} />
        <div className="text-875 flex-grow pl-2 pr-2 w-1/2">{member?.email}</div>
        <div
          className="flex text text-catalina-base font-semibold"
          onClick={() => history.push("/account/settings")}
        >
          設定
        </div>
      </div>
      <div className="mt-8">
        <div className="px-4 flex items-center">
          <div className="flex flex-1">
            <CalendarIcon className="h-5 w-5 mr-2 text-gray-700" />
            <div>年月</div>
          </div>
          <div className="flex flex-1 justify-end">
            <div className="text-gray-600 pr-2">{"合計 "}</div>
            <div className="text">{sumOfHistoryPoints} ポイント</div>
          </div>
        </div>
        <div className="px-4 flex items-center mt-2">
          <DateDropdown
            // startDate={props.startDate}
            // endDate={props.endDate}
            setWalletStartDate={props.setWalletStartDate}
            setWalletEndDate={props.setWalletEndDate}
            refetchWallet={refetchWallet}
            mode="START"
          />
          <ArrowNarrowRightIcon className="h-5 w-5 mx-2 text-gray-400" />
          <DateDropdown
            // startDate={props.startDate}
            // endDate={props.endDate}
            setWalletStartDate={props.setWalletStartDate}
            setWalletEndDate={props.setWalletEndDate}
            refetchWallet={refetchWallet}
            mode="END"
          />
        </div>
        <MemberHolderPoint />
      </div>
    </ScrollpageWithHeader>
  );
};

export const Screen24 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen24Component);
