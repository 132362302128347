import { useEffect, useState } from "react";
import MembersService from "../../services/members.service";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { connect } from "react-redux";
import { setSelectedCategory } from "../../../redux/categories/categories.actions";
import { eventBus } from "../../event-bus/event-bus";
import {setEvent, setFirstLoad} from "../../../redux/global/global.actions";

interface Props {
  state: any;
  selectedCategory?: any;
  categories?: any[];
  setSelectedCategory: Function;
  fetchOffers?: Function;
  setEvent: Function;
  setFirstLoad: Function;
}

const mapStateToProps = (state: any) => {
  return {
    state: state,
    categories: state.categories.categories,
    selectedCategory: state.categories.selectedCategory,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedCategory: (data: any) => dispatch(setSelectedCategory(data)),
    setEvent: (data: any) => dispatch(setEvent(data)),
    setFirstLoad: (data: any) => dispatch(setFirstLoad(data)),
  };
};

export const MemberCategoriesComponent = (props: Props) => {
  const { categories, selectedCategory } = props;
  return (
    <div>
      <IonSegment
        mode="md"
        scrollable
        value={selectedCategory ? selectedCategory.id : null}
      >
        {categories.map((category) => {
          return (
            <IonSegmentButton
              id={category.id}
              key={category.id}
              value={category.id}
              onClick={() => {
                props.setFirstLoad(true)
                props.setSelectedCategory(category.id);
                if (props.fetchOffers) {
                  props.fetchOffers(category.id);
                }
                props.setEvent("CATEGORY_CHANGED")
                // eventBus.dispatch("GTM_EVENT", {
                //   eventName: "CATEGORY_CHANGED",
                //   data: props.state,
                // });
              }}
            >
              <IonLabel>{category.name}</IonLabel>
            </IonSegmentButton>
          );
        })}
      </IonSegment>
    </div>
  );
};

export const MemberCategories = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberCategoriesComponent);
