export const SET_APP_LOADING = "SET_APP_LOADING";
export const SET_APP_ERROR = "SET_APP_ERROR";
export const SET_EVENT = "SET_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const SET_SOCIAL_AUTHENTICATED = "SET_SOCIAL_AUTHENTICATED";
export const SET_LIFF_ID = "SET_LIFF_ID";
export const SET_USER = "SET_USER";
export const SET_FIRST_LOAD = "SET_FIRST_LOAD";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const SET_STORE_SELECTION = "SET_STORE_SELECTION";
