export const requestErrorFn = function (error:any) {
    console.log('Interceptor request Error', error)
    // Do something with request error
    return Promise.reject(error);
}

export const preRequestFn = function (config:any) {
    // Do something before request is sent
    return config;
}

export const errorFn = function (error:any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('Interceptor response Error', error)
    if (error.code === 'ECONNABORTED'){
        window.location.href = "/error";
    }
    else
    {
        return Promise.reject(error);
    }
    // return Promise.reject(error);
}

export const Fn200 = function (response:any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('Interceptor response ')
    return response;
}

