import { atom } from 'jotai';

type iSelectedRetailer = {
    id: number;
    name: string;
    image_url: string;
    picture_card: string;
}

export const Atom_Registration_Email = atom('');
export const Atom_Registration_Email_Confirm = atom('');
export const Atom_Registration_Holder = atom('');
export const Atom_Registration_Selected_Retailer = atom<iSelectedRetailer>({
    id: 0,
    name: "",
    image_url: "",
    picture_card:""
});
