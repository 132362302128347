import { IonPage } from "@ionic/react";
import { Link } from "react-router-dom";

const pages = [
  {
    name: "Login",
    name2: "ログイン",
    path: "/login",
  },
  {
    name: "Register",
    name2: "登録",
    path: "/register",
  },
  {
    name: "Forgot Password Email",
    name2: "パスワードを忘れたメール",
    path: "/forgot-password-email",
  },
  {
    name: "Forgot Password Reset",
    name2: "パスワードのリセットを忘れた",
    path: "/forgot-password-reset",
  },
  {
    name: "Terms and Conditions",
    name2: "規約と条件",
    path: "/terms",
  },
  {
    name: "Get Started",
    name2: "はじめに",
    path: "/get-started",
  },
  {
    name: "Cart",
    name2: "カート",
    path: "/cart",
  },
  {
    name: "Product Details",
    name2: "製品詳細",
    path: "/product-details",
  },
  {
    name: "Tab",
    name2: "Tab",
    path: "/tab1",
  },
];

export const PagesMenu = () => {
  return (
    <IonPage>
      <ul role="list" className="divide-y divide-gray-200 px-4 overflow-auto">
        {pages.map((page, index) => (
          <Link to={page.path}>
            <li key={index} className="py-4 flex">
              <img
                className="h-10 w-10 rounded-full p-1"
                src="https://raw.githubusercontent.com/PKief/vscode-material-icon-theme/main/logo.png"
                alt=""
              />
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">{page.name}</p>
                <p className="text-sm text-gray-500">{page.name2}</p>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </IonPage>
  );
};
