import { IonAlert } from "@ionic/react";
interface Props {
  open: boolean;
  setOpen: Function;
  handleConfirm: Function;
}
export const Popup1 = (props: Props) => {
  return (
    <IonAlert
      mode="ios"
      isOpen={true}
      onDidDismiss={() => {}}
      cssClass="confirm-modal"
      header={"アカウントを削除します"}
      message={
        '<p class="text-gray-400">アカウントを削除した場合、認証されないクーポンは紛失されます。</p>'
      }
      buttons={[
        {
          text: "いいえ",
          role: "cancel",
          cssClass: "secondary py-6",
          handler: () => props.setOpen(false),
        },
        {
          text: "はい",
          cssClass: "py-6",
          handler: () => {
            props.handleConfirm();
          },
        },
      ]}
    />
  );
};
