import { useEffect, useState } from "react";
import { ScrollpageWithHeader } from "../../line/screens/scrollpage-with-header/scrollpage-with-header";


declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}

export const ErrorPage = () => {
  const [showBannerTime, setShowBannerTime] = useState(false);

  useEffect(() => {
    let date = new Date();
    if ((new Date("11/01/2022 00:00:00") < date) && (date < new Date("11/01/2022 02:00:00"))) { setShowBannerTime(true) }
  }, [])
  return (
    <ScrollpageWithHeader title="メルアドレスを登録" id="terms">
      <div className="px-6 pt-40 mt-6 text-center">
        <br />
        <div className="account-editing font-bold">
          ただいまメンテナンス中です。
        </div><br />
        <div className="account-editing font-bold">
          しばらく経ってからお試しください。
        </div><br /><br />
        {showBannerTime &&
          <div className="account-editing font-bold text-red-500">
            2022/11/1(火) 02:00まで
          </div>
        }
      </div>
    </ScrollpageWithHeader>
  );
};
