import { IonPage } from "@ionic/react";
import { useState } from "react";
import { CartCardBarcode } from "../../components/cart-card-barcode/cart-card-barcode";
import { CartCard } from "../../components/cart-card/cart-card";
import { Header } from "../header/header";
import { StoreSelector } from "../store-selector/store-selector";
import { ConfirmModal } from "../../components/confirm-modal/confirm-modal";
import { InformModal } from "../../components/inform-modal/inform-modal";
import {
  CheckIcon,
  SelectorIcon,
  HomeIcon,
  CreditCardIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  UserCircleIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const cardStyle = {
  boxShadow: "rgb(0 0 0 / 10%) 0px 4px 16px",
};

export const ProductDetailsPage = () => {
  const [tabs, setTabs] = useState([
    { name: "ポイント", href: "#", current: true },
    { name: "バーコード", href: "#", current: false },
  ]);
  return (
    <IonPage className="overflow-auto">
      <Header />
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="top bg-white relative px-6">
          <div className="pt-10 pb-14 flex justify-center relative">
            <div className="bg-gray-200 px-4 py-1 rounded-md absolute top-6 left-0">
              戻る
            </div>
            <img
              className="h-56"
              src="https://consolidatedlabel.com/app/uploads/2021/06/wine-bottle-labels-banner.png"
            />
          </div>
          <div
            className="bg-white border border-gray-100 rounded-full p-2 absolute -bottom-6 shadow-xl"
            style={{
              width: "90%",
              left: "5%",
            }}
          >
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  onClick={() => {
                    setTabs(
                      tabs.map((tabitem) => ({
                        ...tabitem,
                        current: tabitem.name === tab.name,
                      }))
                    );
                  }}
                  key={tab.name}
                  className={classNames(
                    tab.current
                      ? "bg-okuwa_blue text-white rounded-2xl"
                      : "text-okuwa_blue",
                    "px-3 py-2 flex-1 font-medium text-sm rounded-full text-center"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        <div className="list flex-1 bg-gray-200">
          <div className="mt-16 text-center">
            <div className="text-lg">チョコラBB ローヤル2 50mL</div>
            <div className="text-gray-600 mt-2">
              This is the product subtitle
            </div>
            <div className="flex justify-center mt-4">
              <div className="bg-gray-300 px-4 py-1 rounded-md">
                ホーム&キッチン
              </div>
              <div className="w-4"></div>
              <div className="bg-gray-300 px-4 py-1 rounded-md">お酒</div>
            </div>

            <div className="flex flex-col bg-white p-6 m-4 mt-8 rounded-md">
              <img
                className="h-14 opacity-60"
                src="https://pngimg.com/uploads/barcode/barcode_PNG7.png"
              />

              <div className="text-3xl mt-6 font-bold">50円</div>
              <div className="text-lg mt-3">有効期限： 明日まで</div>
            </div>
            <div className="flex flex-col bg-white p-5 m-4 mt-4 rounded-md">
              <div className="flex">
                <div className="left-icon w-12">
                  <LocationMarkerIcon className="text-okuwa_blue" />
                </div>
                <div className="pl-3 text-sm text-left">
                  Location of address one: This should be the address where the
                  product is findable.
                </div>
              </div>

              <div className="flex mt-8">
                <div className="left-icon w-12">
                  <LocationMarkerIcon className="text-okuwa_blue" />
                </div>
                <div className="pl-3 text-sm text-left">
                  Location of address one: This should be the address where the
                  product is findable.
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white p-5 m-4 mt-4 rounded-md text-left text-gray-600">
              表示の数量に限り1回のみ有効です。
              <br />
              単品の場合、複数購入しても、単品のポイントのみ付与されます。
              <br />
              新CMで話題！
              <br />
              疲れ、肌荒れ飛んでいけ！
              <br />
              活性型ビタミンB2、タウリン、ローヤルゼリー等、11種の成分配合
              <br />
              <br />
              [指定医薬部外品]
              <br />
              メーカー名:エーザイ
              <br />
              <br />
              クーポン対象商品
              <br />
              <br />
              チョコラBB ローヤル2 50mL
              <br />
              　バーコード:4987028115373
              <br />
              　バーコード:4987028115427
              <br />
            </div>
            <div className="h-4"></div>
          </div>
        </div>
      </div>
      <div className="bottom-nav border-t border-gray-200">
        <div className="flex">
          <div className="py-4 px-5 text-xl font-bold flex-1">50円</div>
          <div className="py-4 px-5 text bg-okuwa_blue text-gray-400">カートに追加</div>
        </div>
      </div>
      {/* <ConfirmModal /> */}
      <InformModal message="Congrats! Your coupon is now in the cart" open={true} setOpen={() => {}}/>
    </IonPage>
  );
};
