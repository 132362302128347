import { IonButton, IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import { useState } from "react";
import { TextField } from "../../components/text-field/text-field";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";

export const Screen31 = () => {
  const [checked, setChecked] = useState(false);
  return (
    <ScrollpageWithHeader title="パスワード編集"  id="screen-31">
      <div className="px-6 mt-6">
        <TextField
          placeholder="現在のパスワード"
          value={""}
          onChange={(e: any) => {}}
          type="text"
        />

        <TextField
          placeholder="新しいパスワード"
          value={""}
          onChange={(e: any) => {}}
          type="text"
        />

        <TextField
          placeholder="新しいパスワード確認"
          value={""}
          onChange={(e: any) => {}}
          type="text"
        />
      </div>

      <IonItem lines="none">
        <IonCheckbox
          checked={checked}
          onIonChange={(e) => setChecked(e.detail.checked)}
        />
        <IonLabel>パスワードを表示する</IonLabel>
      </IonItem>

      <div className="px-4 mt-2">
        <IonButton expand="block" color="light">
          保存
        </IonButton>
      </div>
    </ScrollpageWithHeader>
  );
};
