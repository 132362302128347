
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { setMemberWallet } from "../../../redux/wallet/wallet.actions";

const mapStateToProps = (state: any) => {
    return {
        state: state,
        count: state.counter.count,
        vendorWallet: state.vendorWallet,
        wallet: state.wallet,
        retailers: state.retailers,
        isAppLoading: state.global.isAppLoading,
        selectedStore: state.stores.selectedStore,
        vendorLoading: state.vendorWallet.vendorLoading
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setMemberWallet: (data: any) => dispatch(setMemberWallet(data)),
    };
};

interface Props {
    vendorWallet: any;
    wallet: any;
    vendorLoading: boolean;
    retailers: {
        list: any[];
        selectedForAccount: any | null;
    };
}
export function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}
export const MemberHolderPointRetailerComponent = (props: Props) => {
    let waonId = Number(process.env.REACT_APP_WAON_ID);
    let status:any = {
        'paid': '付与完了',
        'refused': '不承認',
        'pending': '保留中',
        'to_be_paid': '処理中',
        'validated': '検証済み',

    }
    const history = useHistory();
    useEffect(() => {
        if (!props.retailers.selectedForAccount) {
            history.goBack();
        }
    }, []);

    if (props.vendorWallet === null) {
        return null;
    }

    const getDate = (date:string) => {
        let pDate = new Date(date)
        let key = pDate.toLocaleDateString('ja-JP', {month:"long", day:"numeric"})
        return key;
    }
    const pointsByRetailer: any = {};

    props.vendorWallet.paid.forEach((p: any) => {
        p.date = getDate(p.updated_at)
        p.category = 'paid'
        pointsByRetailer[p.retailer_id] = pointsByRetailer[p.retailer_id] || 0;
        pointsByRetailer[p.retailer_id] =
            pointsByRetailer[p.retailer_id] + p.amount;
    });

    props.vendorWallet.to_be_paid.forEach((result: any) => {
        result.date = getDate(result.updated_at)
        result.category = 'to_be_paid'
    });
    props.vendorWallet.pending.forEach((result: any) => {
        result.date = getDate(result.updated_at)
        result.category = 'pending'
    });
    props.vendorWallet.validated.forEach((result: any) => {
        result.date = getDate(result.updated_at)
        result.category = 'validated'
    });
    props.vendorWallet.refused.forEach((result: any) => {
        result.date = getDate(result.updated_at)
        result.category = 'refused'
    });
    let sumOfHistoryPoints = 0;
    props.vendorWallet.paid.forEach((result: any) => (sumOfHistoryPoints += result.point));

    return (
        <div>
            <br />
            {/*{props.retailers.selectedForAccount.id === waonId && (*/}
            {/*    <>*/}
            {/*        <div className="text-xs mt-3" style={{ fontSize: 14 }}>*/}
            {/*            3月に開催した春トク祭りキャンペーンの特典ポイントはこちらに反映されません。*/}
            {/*        </div>*/}
            {/*        <br />*/}
            {/*    </>*/}
            {/*)}*/}
            {props.vendorLoading &&
                <div className="animate-pulse">
                    <div className="flex items-end">
                        <div className="bg-gray-500 w-3 h-3 rounded-full"></div>
                        <div style={{ marginLeft: "0.9375rem" }} className="-mb-2 bg-gray-300">
                            <div className="w-36 bg-gray-300 h-6 rounded-md ">
                            </div>
                        </div>
                    </div>
                    <div
                        className="border-l-2 border-gray-500 pb-4 pl-3"
                        style={{ marginLeft: 5 }}
                    >
                        <div className="pt-4">
                            <div className="text text-gray ml-4">
                                <div className="w-36 bg-gray-300 h-4 rounded-md ">
                                </div>
                            </div>
                            <div className="text text-gray mt-2 ml-4">
                                <div className="w-36 bg-gray-300 h-4 rounded-md ">
                                </div>
                            </div>
                            <div className="text text-gray mt-2 ml-4">
                                <div className="w-36 bg-gray-300 h-4 rounded-md ">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <div className="bg-gray-500 w-3 h-3 rounded-full"></div>
                        <div style={{ marginLeft: "0.9375rem" }} className="-mt-2">
                            <div className="w-36 bg-gray-300 h-6">
                            </div>
                        </div>
                    </div>
                </div>

            }

            {!props.vendorLoading &&
                <>
                    <div className="flex items-end">
                        <div className="bg-gray-500 w-3 h-3 rounded-full"></div>
                        <div style={{ marginLeft: "0.9375rem" }} className="-mb-2">{props.wallet.endDate}</div>
                    </div>
                    <div
                        className="border-l-2 border-gray-500 pb-4 pl-3"
                        style={{ marginLeft: 5 }}
                    >

                        {props.vendorWallet.pending && props.vendorWallet.pending.map((p: any) => {
                            if (p.offer) {
                                return (
                                    <div
                                        key={p.id}
                                        className="flex py-4 mt-2 items-center"
                                    >

                                        <img
                                            src={p.offer.picture_url}
                                            alt={"Item"}
                                            className="w-24 mr-2 rounded-md"
                                        />
                                        <div className="flex-1 min-w-0">
                                            <div className={classNames(
                                                "text pt-1 font-semibold",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}
                                            >
                                                {p.offer.discount_value}P - {status[p.category]}
                                            </div>
                                            <div className={classNames(
                                                "text-sm truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.title}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.subtitle}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>{p.date}</div>
                                        </div>
                                    </div>
                                    // <div className="pt-6" key={p.id}>
                                    //     <div className="text text-gray">{p.offer.title}</div>
                                    //     <div className="text text-gray">{p.offer.subtitle}</div>
                                    //     <div className="text text-gray"><b>{p.amount}</b> ポイント - 保留中</div>
                                    // </div>
                                );
                            }
                            if (!p.offer) {
                                return (
                                    <div className="pt-6" key={p.id}>
                                        <div className="text text-gray">{p.comment}</div>
                                        <div className="text text-gray"><b>{p.amount}</b> ポイント - 保留中</div>
                                    </div>
                                );
                            }
                        })}

                        {props.vendorWallet.refused && props.vendorWallet.refused.map((p: any) => {
                            if (p.offer) {
                                return (
                                    <div
                                        key={p.id}
                                        className="flex py-4 mt-2 items-center"
                                    >

                                        <img
                                            src={p.offer.picture_url}
                                            alt={"Item"}
                                            className="w-24 mr-2 rounded-md"
                                        />
                                        <div className="flex-1 min-w-0">
                                            <div className={classNames(
                                                "text pt-1 font-semibold",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}
                                            >
                                                {p.offer.discount_value}P - {status[p.category]}
                                            </div>
                                            <div className={classNames(
                                                "text-sm truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.title}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.subtitle}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>{p.date}</div>
                                        </div>
                                    </div>
                                    // <div className="pt-6" key={p.id}>
                                    //     <div className="text text-gray">{p.offer.title}</div>
                                    //     <div className="text text-gray">{p.offer.subtitle}</div>
                                    //     <div className="text text-gray"><b>{p.amount}</b> ポイント - 不承認</div>
                                    // </div>
                                );
                            }
                            if (!p.offer) {
                                return (
                                    <div className="pt-6" key={p.id}>
                                        <div className="text text-gray">{p.comment}</div>
                                        <div className="text text-gray"><b>{p.amount}</b> ポイント - 不承認</div>
                                    </div>
                                );
                            }

                        })}

                        {props.vendorWallet.to_be_paid && props.vendorWallet.to_be_paid.map((p: any) => {
                            if (p.offer) {
                                return (
                                    <div
                                        key={p.id}
                                        className="flex py-4 mt-2 items-center"
                                    >

                                        <img
                                            src={p.offer.picture_url}
                                            alt={"Item"}
                                            className="w-24 mr-2 rounded-md"
                                        />
                                        <div className="flex-1 min-w-0">
                                            <div className={classNames(
                                                "text pt-1 font-semibold",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}
                                            >
                                                {p.offer.discount_value}P - {status[p.category]}
                                            </div>
                                            <div className={classNames(
                                                "text-sm truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.title}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.subtitle}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>{p.date}</div>
                                        </div>
                                    </div>
                                    // <div className="pt-6" key={p.id}>
                                    //     <div className="text text-gray">{p.offer.title}</div>
                                    //     <div className="text text-gray">{p.offer.subtitle}</div>
                                    //     <div className="text text-gray"><b>{p.amount}</b> ポイント - 処理中</div>
                                    // </div>
                                );
                            }
                            if (!p.offer) {
                                return (
                                    <div className="pt-6" key={p.id}>
                                        <div className="text text-gray">{p.comment}</div>
                                        <div className="text text-gray"><b>{p.amount}</b> ポイント - 処理中</div>
                                    </div>
                                );
                            }

                        })}

                        {props.vendorWallet.paid && props.vendorWallet.paid.map((p: any) => {
                            if (p.offer) {
                                return (
                                    <div
                                        key={p.id}
                                        className="flex py-4 mt-2 items-center"
                                    >

                                        <img
                                            src={p.offer.picture_url}
                                            alt={"Item"}
                                            className="w-24 mr-2 rounded-md"
                                        />
                                        <div className="flex-1 min-w-0">
                                            <div className={classNames(
                                                "text pt-1 font-semibold",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}
                                            >
                                                {p.offer.discount_value}P - {status[p.category]}
                                            </div>
                                            <div className={classNames(
                                                "text-sm truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.title}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.subtitle}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>{p.date}</div>
                                        </div>
                                    </div>
                                    // <div className="pt-6" key={p.id}>
                                    //     <div className="text">{p.offer.title}</div>
                                    //     <div className="text">{p.offer.subtitle}</div>
                                    //     <div className="text"><b>{p.amount}</b> ポイント - 付与完了</div>
                                    // </div>
                                );
                            }
                            if (!p.offer) {
                                return (
                                    <div className="pt-6" key={p.id}>
                                        <div className="text">{p.comment}</div>
                                        <div className="text"><b>{p.amount}</b> ポイント - 付与完了</div>
                                    </div>
                                );
                            }

                        })}

                        {props.vendorWallet.validated && props.vendorWallet.validated.map((p: any) => {
                            if (p.offer) {
                                return (
                                    <div
                                        key={p.id}
                                        className="flex py-4 mt-2 items-center"
                                    >

                                        <img
                                            src={p.offer.picture_url}
                                            alt={"Item"}
                                            className="w-24 mr-2 rounded-md"
                                        />
                                        <div className="flex-1 min-w-0">
                                            <div className={classNames(
                                                "text pt-1 font-semibold",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}
                                            >
                                                {p.offer.discount_value}P - {status[p.category]}
                                            </div>
                                            <div className={classNames(
                                                "text-sm truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.title}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>
                                                {p.offer.subtitle}
                                            </div>
                                            <div className={classNames(
                                                "text-sm pt-1 truncate",
                                                p.category != 'paid' ? "text-gray" : ""
                                            )}>{p.date}</div>
                                        </div>
                                    </div>
                                    // <div className="pt-6" key={p.id}>
                                    //     <div className="text text-gray">{p.offer.title}</div>
                                    //     <div className="text text-gray">{p.offer.subtitle}</div>
                                    //     <div className="text text-gray"><b>{p.amount}</b> ポイント - 検証済み</div>
                                    // </div>
                                );
                            }
                            if (!p.offer) {
                                return (
                                    <div className="pt-6" key={p.id}>
                                        <div className="text text-gray">{p.comment}</div>
                                        <div className="text text-gray"><b>{p.amount}</b> ポイント - 検証済み</div>
                                    </div>
                                );
                            }
                        })}

                        {(props.vendorWallet.pending && props.vendorWallet.pending.length === 0) &&
                            (props.vendorWallet.paid && props.vendorWallet.paid.length === 0) &&
                            (props.vendorWallet.to_be_paid && props.vendorWallet.to_be_paid.length === 0) &&
                            (props.vendorWallet.validated && props.vendorWallet.validated.length === 0) &&
                            (props.vendorWallet.refused && props.vendorWallet.refused.length === 0) && (

                                <div className="text-875 text-center py-10" style={{
                                    color: "#B7B7B7"
                                }}>ポイントはありません</div>
                            )}
                    </div>

                    <div className="flex items-start">
                        <div className="bg-gray-500 w-3 h-3 rounded-full"></div>
                        <div style={{ marginLeft: "0.9375rem" }} className="-mt-2">{props.wallet.startDate}</div>
                    </div>
                </>
            }

        </div>
    );
};

export const MemberHolderPointRetailer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberHolderPointRetailerComponent);
