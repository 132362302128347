import { XIcon } from "@heroicons/react/solid";
import "./screen16.scss";
import { InformationCircleIcon } from "@heroicons/react/outline";
import empty_cart from "./empty_cart.png";
import { OfferCard } from "../../components/offer-card/offer-card";
import { MemberHolder } from "../../components/member-holder/member-holder";
import { MemberStore } from "../../components/member-store/member-store";
import { useHistory } from "react-router-dom";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {IonImg, IonSpinner, useIonViewDidEnter} from "@ionic/react";
import questionCircle from "./Question Circle.png";
import {setSelectedTab} from "../../../redux/tabs/tabs.actions";
import {NoStorePopup} from "../../components/member-holder/NoStorePopup";
import React, {useState} from "react";

interface Props {
  basket: any[];
  cards: any[];
  basketPoints: number;
  offersLoading: boolean;
  selectedStore: any;
  retailers: any[];
  setSelectedTab: Function;
}

const mapStateToProps = (state: any) => {
  return {
    selectedStore: state.stores.selectedStore,
    retailers:state.retailers.list,
    basket: state.offerList.basket,
    cards: state.cards.holders,
    basketPoints: state.offerList.basketPoints,
    offersLoading: state.offerList.offersLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
  };
};

export const Screen16Component = (props: Props) => {
  useIonViewDidEnter(() => {
    console.log('ionViewDidEnter event fired: ');
    props.setSelectedTab('basket');

  });

  const history = useHistory();
  const [showNoStorePopup, setShowNoStorePopup] = useState(false);
  const holders = props.cards;
  let selectedStore = props.selectedStore;
  let selectedStoreRetailerId = props.selectedStore ? parseInt(props.selectedStore.retailer_id) : -1;
  let holder = props.cards.filter(
      (c) => c.retailer_id === selectedStoreRetailerId
  )[0]
  // console.log(holder)
  // console.log(`wow holder is ${holder}`)
  // console.log("basket changed", props);
  let sumOfPoints = 0;
  props.basket.map((reward) => {
    sumOfPoints = sumOfPoints + reward.offer.discount_value;
  });
  // console.log(sumOfPoints);
  return (
    <ScrollpageWithHeader title="クーポン情報" id="screen-16">
      <div className="flex flex-col flex-1 overflow-auto bg-gray-100" >
        <div className="flex flex-col flex-1 ">
          <MemberStore />
          <MemberHolder />
          <div />
          <div className="flex items-center justify-center px-6 py-6 bg-white">
            <div className="text-sm text-gray-500">合計</div>
            {!props.selectedStore && (
              <div className="pl-2 text-lg font-bold">0 ポイント</div>
            )}
            {props.selectedStore && (
              <div className="pl-2 text-lg font-bold">
                {holder ? props.basketPoints : 0}ポイント
              </div>
            )}
            <div className="pl-2 pr-2 text-sm text-gray-500">登録済み</div>
            <IonImg
              slot="icon-only"
              src={questionCircle}
              className="w-6 h-6 text-gray-700"
              onClick={() => {
                history.push("/tabs/23");
              }}
            />
            {/* <InformationCircleIcon
              className="w-6 h-6 text-gray-700"
              onClick={() => {
                history.push("/23");
              }}
            /> */}
          </div>

          {/* {loadingBasket && (
            <div className="flex justify-center pt-12">
              <IonSpinner />
            </div>
          )} */}

          {!props.selectedStore && (
            <div className="flex px-6 py-6 text-center bg-white border-b border-t">
              <div className="pt-4 text-sm text-gray-500">
                お店を登録してください
              </div>
            </div>
          )}

          {holders && holders.length === 0 && (
            <div className="flex-1 px-6 pt-36 text-center bg-white">
              <div className="pt-4 text-sm text-gray-500">
                お好きなクーポンをバスケット
              </div>
              <div className="pt-4 text-sm text-gray-500">に追加する前に</div>
              <div className="text-green-400 text">
                <div  className="pt-4 text-sm text-catalina-base" onClick={()=>{
                  if(!props.selectedStore && props.retailers.length > 1){
                    setShowNoStorePopup(true)
                  } else {
                    history.push("/9")
                  }
                }}>
                  カードを入力してください
                </div>
              </div>
            </div>
          )}

          {props.offersLoading && (
            <div className="flex justify-center">
              <IonSpinner />
            </div>
          )}
          {holders && holders.length > 0 && props.selectedStore && (
            <div className="grid grid-cols-2 gap-2 px-2 py-3">
              {props.basket.map((cartItem) => {
                const offer = cartItem.offer;
                return (
                  <OfferCard
                    key={cartItem.id}
                    addButtonDisabled={true}
                    cartItemId={cartItem.id}
                    bestMode={true}
                    offer={offer}
                    basketMode={true}
                    expiredAt={new Date(cartItem.expired_at)
                      .toISOString()
                      .slice(0, 10)}
                  />
                );
              })}
            </div>
          )}

          {holders && holders.length > 0 && props.basket.length === 0 && (
            <div className="flex flex-col items-center justify-center flex-1 px-6 py-6 text-sm text-center text-gray-600 bg-gray-100">
              {/* <img className="px-16" src={empty_cart} /> */}
              <div className="py-2 text-sm text-gray-500">
                バスケットに追加済みのクーポンはありません
              </div>
            </div>
          )}
        </div>
        {
            showNoStorePopup &&
            <NoStorePopup handleConfirm={setShowNoStorePopup} open={showNoStorePopup} setOpen={setShowNoStorePopup} />
        }
      </div>
    </ScrollpageWithHeader>
  );
};

export const Screen16 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen16Component);
