import { SET_HOLDER_CARDS } from "./holder-cards.types";
import { SET_ACCOUNT_MODE } from "./holder-cards.types";


const INITIAL_STATE = {
  holders: [],
  accountScreenMode: false,
  loading: true,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_HOLDER_CARDS:
      return {
        ...state,
        holders: action.payload,
        loading: false,
      };
    case SET_ACCOUNT_MODE:
      return {
        ...state,
        accountScreenMode: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
