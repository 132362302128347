import { IonPage } from "@ionic/react";
import { useState } from "react";
import { CartCardBarcode } from "../../components/cart-card-barcode/cart-card-barcode";
import { CartCard } from "../../components/cart-card/cart-card";
import { Header } from "../header/header";
import { StoreSelector } from "../store-selector/store-selector";
import { ConfirmModal } from "../../components/confirm-modal/confirm-modal";
import { InformModal } from "../../components/inform-modal/inform-modal";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const cardStyle = {
  boxShadow: "rgb(0 0 0 / 10%) 0px 4px 16px",
};

export const CartPage = () => {
  const [tabs, setTabs] = useState([
    { name: "ポイント", href: "#", current: true },
    { name: "バーコード", href: "#", current: false },
  ]);
  return (
    <IonPage className="overflow-auto">
      <Header />
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="top bg-okuwa_blue relative px-6">
          <div className="text-lg text-white mt-6">You have 23 items</div>
          <StoreSelector />
          <br />
          <br />
          <div
            className="bg-white rounded-full p-2 absolute -bottom-6 shadow-lg"
            style={{
              width: "90%",
              left: "5%",
            }}
          >
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  onClick={() => {
                    setTabs(
                      tabs.map((tabitem) => ({
                        ...tabitem,
                        current: tabitem.name === tab.name,
                      }))
                    );
                  }}
                  key={tab.name}
                  className={classNames(
                    tab.current
                      ? "bg-okuwa_blue text-white rounded-2xl"
                      : "text-okuwa_blue",
                    "px-3 py-2 flex-1 font-medium text-sm rounded-full text-center"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        <div className="list flex-1 bg-white">
          <div className="mt-10">
            {/* <div className="text-lg text-center mt-24 text-gray-dark">
              クーポンがありません。
            </div>
            <div className="mt-8 flex justify-center px-4">
              <button
                type="submit"
                className="bg-okuwa_blue rounded-md shadow-sm py-3 px-10 text-sm font-medium text-white"
              >
                クーポンを追加
              </button>
            </div> */}
            <ul role="list" className="divide-y divide-gray-200 px-4">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) =>
                tabs.filter((tab) => tab.current)[0].name === "ポイント" ? (
                  <CartCard />
                ) : (
                  <CartCardBarcode />
                )
              )}
            </ul>
            <div className="h-4"></div>
          </div>
        </div>
      </div>
      {/* <ConfirmModal /> */}
      {/* <InformModal /> */}
    </IonPage>
  );
};
