import {
  SET_FAVORITE_STORES,
  SET_SELECTED_STORE,
  SET_STORE_LOADING,
  STORES_AT_LEVEL_CITY,
  STORES_AT_LEVEL_PREFECTURE,
  STORES_AT_LEVEL_RETAILER,
} from "./stores.types";
import {star} from "ionicons/icons";

const INITIAL_STATE = {
  favoriteStores: [],
  loading: true,
  selectedStore: null,

  stores_level_retailer: [],
  stores_level_prefecture: [],
  stores_level_city: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FAVORITE_STORES:
      let starStore = JSON.parse(localStorage.getItem('fav-store'));
      let app_retailer_id = localStorage.getItem('app_retailer_id')
        let id_filter = ''
      if (app_retailer_id) {
        id_filter = app_retailer_id.split(',').map( Number );
      }
      if(id_filter.length>0){
        action.payload = action.payload.filter((retailer) => id_filter.includes(retailer.retailer_id))
      }
      if(starStore && app_retailer_id && !id_filter.includes(starStore.retailer_id)){
        localStorage.removeItem('fav-store')
        starStore = null;
      }

      if(!starStore){
        // selectedStore: action.payload.length > 0 ? action.payload[0] : null,
        let retailer_id = localStorage.getItem("fav-store-retailer");
        if(retailer_id){
          let filteredStores = action.payload.filter((retailer) =>{
            console.log(retailer)
            console.log(retailer.retailer_id === parseInt(retailer_id))
            return retailer.retailer_id === parseInt(retailer_id)
          })
          if(!starStore && filteredStores.length>0){
            starStore = filteredStores.length > 0 ? filteredStores[0] : null
          }
          console.log(starStore)
        }
        if(!starStore){
          starStore = action.payload.length > 0 ? action.payload[0] : null
        }
        localStorage.setItem('fav-store', JSON.stringify(starStore))

      }
      return {
        ...state,
        favoriteStores: action.payload,
        selectedStore: starStore,
      };

    case SET_SELECTED_STORE:
      let store = state.favoriteStores.filter(
          (c) => c.id === action.payload.id
      )[0]
        if(store){
          localStorage.setItem('fav-store', JSON.stringify(store))
        }

      return {
        ...state,
        selectedStore: store,
      };

    case SET_STORE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case STORES_AT_LEVEL_RETAILER:
      return {
        ...state,
        stores_level_retailer: action.payload,
      }

    case STORES_AT_LEVEL_PREFECTURE:
      return {
        ...state,
        stores_level_prefecture: action.payload,
      }

    case STORES_AT_LEVEL_CITY:
      return {
        ...state,
        stores_level_city: action.payload,
      }

    default:
      return state;
  }
};

export default reducer;
