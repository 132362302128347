import { SET_CATEGORIES, SET_SELECTED_CATEGORY } from "./categories.types";

export const setCategories = (data) => {
  return {
    type: SET_CATEGORIES,
    payload: data,
  };
};

export const setSelectedCategory = (data) => {
  return {
    type: SET_SELECTED_CATEGORY,
    payload: data,
  };
};
