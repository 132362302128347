import axios from "axios";
import {errorFn, Fn200, preRequestFn, requestErrorFn} from "./errorfunctions";
import UtilityService from "./utility";
const OCP_API_SUBSCRIPTION_KEY= process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY as string;
const MEMBERS_URL= process.env.REACT_APP_MEMBERS_URL as string;
const LOGIN_URL= process.env.REACT_APP_LOGIN_URL as string;
const SOCIAL_LOGIN_URL= process.env.REACT_APP_SOCIAL_LOGIN_URL as string;
// Add a request interceptor
axios.interceptors.request.use(preRequestFn, requestErrorFn);

// Add a response interceptor
axios.interceptors.response.use(Fn200, errorFn);

const AuthService = {
  login: (email: string, password: string) => {
      return axios.post(
      LOGIN_URL,
      JSON.stringify({
        email,
        password,
      }),
      {
        timeout: UtilityService.timeOut,
        headers: {
          "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
          "Ocp-Apim-Trace": "true",
          "Content-Type": "application/json",
        },
      }
    )
  },
  socialLogin: (token: string, lineUserID: string) => {
    return axios.post(
      SOCIAL_LOGIN_URL,
      JSON.stringify({
        token: token,
        type: 1,
        id: lineUserID
      }),
      {
        timeout: UtilityService.timeOut,
        headers: {
          "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
          "Ocp-Apim-Trace": "true",
          "Content-Type": "application/json",
        },
      }
    );
  },
  signup: (email: string, password: string) => {
    return axios.post(
      MEMBERS_URL,
      JSON.stringify({
        email,
        password
      }),
      {
        timeout: UtilityService.timeOut,
        headers: {
          "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
          "Ocp-Apim-Trace": "true",
          "Content-Type": "application/json",
        },
      }
    );
  },
  signupSocial: (email: string, password: string, social: any) => {
    return axios.post(
      MEMBERS_URL,
      JSON.stringify({
        email,
        password,
        social
      }),
      {
        timeout: UtilityService.timeOut,
        headers: {
          "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
          "Ocp-Apim-Trace": "true",
          "Content-Type": "application/json",
        },
      }
    );
  },

  signupSocialWithHolder: (email: string, holder: string, retailer_id: number, social: { type: number; id: string }) => {
    return axios.post(
      MEMBERS_URL,
      JSON.stringify({
        email,
        password: "",
        social,
        holders: [
          {
            ref: holder,
            retailer_id,
          }
        ]
      }),
      {
        timeout: UtilityService.timeOut,
        headers: {
          "Ocp-Apim-Subscription-Key": OCP_API_SUBSCRIPTION_KEY,
          "Ocp-Apim-Trace": "true",
          "Content-Type": "application/json",
        },
      }
    );
  },
};

export default AuthService;
