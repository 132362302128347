import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { connect } from "react-redux";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  mode: string;
  startDate: string;
  endDate: string;
  setWalletStartDate: Function;
  setWalletEndDate: Function;
  refetchWallet: Function;
}

const getLast6Months = () => {
  let d = new Date();
  const dates = [];
  for (let i = 0; i <= 6; i++) {
    let dateString = d.toISOString().split("-");
    let year = dateString[0];
    let month = dateString[1];
    dates.push(`${year}/${month}`);
    d.setMonth(d.getMonth() - 1);
  }
  return dates;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // setWalletStartDate: (data: any) => dispatch(setWalletStartDate(data)),
    // setWalletEndDate: (data: any) => dispatch(setWalletEndDate(data)),
    // getWallet: (startDate: any, endDate: any) => dispatch(getWallet(startDate, endDate)),
  };
};

const mapStateToProps = (state: any) => {
  return {
    startDate: state.wallet.startDate,
    endDate: state.wallet.endDate,
  };
};

interface Props {
  startDate: string;
  endDate: string;
  // setWalletStartDate: Function;
  // setWalletEndDate: Function;
}

export const DateDropdownComponent = (props: Props) => {

  const getLast36Months = () => {
    let d = new Date();
    const dates = [];
    for (let i = 0; i < 36; i++) {
      let dateString = d.toISOString().split("-");
      let year = dateString[0];
      let month = dateString[1];
      dates.push(`${year}/${month}`);
      d.setMonth(d.getMonth() - 1);
    }
    return dates;
  };

  const getLessThanStartDate = () => {
    const enddates = [];
    let d = new Date();
    if(props.startDate && props.mode=='END'){
      // console.log(props.startDate)
      for (let i = 0; i < 36; i++) {
        let dateString = d.toISOString().split("-");
        let year = dateString[0];
        let month = dateString[1];
        let startYear = parseInt(props.startDate.split('/')[0])
        let startMonth = parseInt(props.startDate.split('/')[1])
        let startDate = new Date(startYear, startMonth, 1)
        let dateToAdd = new Date(parseInt(year), parseInt(month), 1)
        if(dateToAdd>=startDate){
          enddates.push(`${year}/${month}`);
        }
        d.setMonth(d.getMonth() - 1);
      }
      return enddates;
    }
  };

  const [dates, setDates] = useState<string[]>(getLast36Months());
  const [endDates, setEndDates] = useState<string[]>();
  const [startDates] = useState<string[]>(getLast6Months());
  // const [selected, setSelected] = useState(
  //   props.mode === "START" ? dates[dates.length - 1] : dates[0]
  // );

  useEffect(() => {
    setTimeout(() => {
      if (props.mode === "START" && !props.startDate) {
        props.setWalletStartDate(startDates[startDates.length - 1]);
      } else {
        if (!props.endDate) {
          props.setWalletEndDate(dates[0]);
        }
      }
    }, 500);
  }, []);

  useEffect(() => {
    if(props.mode === "END" && props.startDate){
      setDates(getLessThanStartDate())
    }
  }, [props.startDate]);

  const selected = props.mode === "START" ? props.startDate : props.endDate;
  
  return (
    <Listbox
      value={props.mode === "START" ? props.startDate : props.endDate}
      onChange={(val) => {
        if (props.mode === "START") {
          props.setWalletStartDate(val);
          let endYear = parseInt(props.endDate.split('/')[0])
          let endMonth = parseInt(props.endDate.split('/')[1])
          let endDate = new Date(endYear, endMonth, 1)

          let startYear = parseInt(val.split('/')[0])
          let startMonth = parseInt(val.split('/')[1])
          let startDate = new Date(startYear, startMonth, 1)
          if(endDate <  startDate){
            props.setWalletEndDate(val);
            props.refetchWallet(val, val);
          } else {
            props.refetchWallet(val, props.endDate);
          }

        } else {
          props.setWalletEndDate(val);
          props.refetchWallet(props.startDate, val);
        }
      }}
    >
      <div className="mt-1 relative flex-1 border border-gray-300 rounded-md">
        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-catalina-base focus:border-catalina-base sm:text-sm">
          <span className="block truncate">{selected}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >

          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {dates.map((date) => (
              <Listbox.Option
                key={date}
                className={({ active }) =>
                  classNames(
                    active ? "text-white bg-catalina-base" : "text-gray-900",
                    "cursor-default select-none relative py-2 pl-3 pr-9"
                  )
                }
                value={date}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={classNames(
                        selected ? "font-semibold" : "font-normal",
                        "block truncate"
                      )}
                    >
                      {date}
                    </span>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? "text-white" : "text-catalina-base",
                          "absolute inset-y-0 right-0 flex items-center pr-4"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export const DateDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateDropdownComponent);
