import { HalfSplitBackgroundShell } from "../../../shells/half-split-background-shell/half-split-background-shell";
import { MailIcon } from "@heroicons/react/solid";
import { FieldWithIcon } from "../../../shells/components/field-with-icon/field-with-icon";
import { FullRoundButton } from "../../../shells/components/full-round-button/full-round-button";
import { SocialIcons } from "../social-icons/social-icons";
import { BottomTermsCopywight } from "../bottom-terms-copyright/bottom-terms-copyright";

export const ForgotPasswordSendMail = () => {
  return (
    <HalfSplitBackgroundShell>
      <div className="text-lg text-center">パスワードを忘れた場合</div>
      <div className="text-sm text-center py-3 text-gray-600">
        メールアドレスを入力してください。アカウントにアクセスするためのリンクを送信します。
      </div>
      <br />
      <FieldWithIcon
        Icon={MailIcon}
        placeholder="電子メールアドレス"
        type="email"
      />
      <FullRoundButton text="配信" />
      <div className="text-sm text-center text-gray-500 pt-3">
        ログイン画面へ戻る
      </div>
      <SocialIcons />
      <BottomTermsCopywight />
    </HalfSplitBackgroundShell>
  );
};
