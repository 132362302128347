import {
  IonContent,
  IonHeader,
  IonPage,
  IonCard,
  IonCardHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  MailIcon,
  UserCircleIcon,
  LockClosedIcon,
  ChevronRightIcon,
  HomeIcon,
  CreditCardIcon,
} from "@heroicons/react/solid";

import logo from "./logos/logo.png";
import { HalfSplitBackgroundShell } from "../../shells/half-split-background-shell/half-split-background-shell";

export const GetStartedPage = () => {
  return (
    <HalfSplitBackgroundShell makeBackgroundTransparent={true}>
      <div className="bg-white shadow-lg rounded-md py-6 px-5">
        <div className="font-bold">Card Registration</div>
        <div className="flex mt-3">
          <CreditCardIcon className="h-6 w-6 text-gray-500" />
          <div className="text-gray-600 pl-4 flex-1">0000 0000 0000 0000</div>
          <ChevronRightIcon className="h-6 w-6 text-gray-dark" />
        </div>
      </div>
      <div className="bg-white shadow-lg rounded-md py-6 px-5 mt-6">
        <div className="font-bold">Find your favorite shops</div>
        <div className="flex mt-3">
          <HomeIcon className="h-6 w-6 text-gray-500" />
          <div className="text-gray-600 pl-4 flex-1">店舗を選択</div>
          <ChevronRightIcon className="h-6 w-6 text-gray-dark" />
        </div>
      </div>
      <div className="bg-okuwa_blue text-white py-5 text-center text-sm absolute bottom-0 left-0 right-0">
        Register
      </div>
    </HalfSplitBackgroundShell>
  );
};
