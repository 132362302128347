import React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import linelogo from "./linelogo.png";
export const LineLightLink = () => {


    return (
        <>
            <div
                className="bg-white flex p-4 items-center border-t-8"
                onClick={() => {
                    console.log("Executing Analytics tag code for line redirection!");
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "virtualClick",
                        pageTitle: window.location.pathname,
                        pageUrl:
                            "https://liff.line.me/1611905212-3bydBEmv?utm_source=catalina&utm_medium=c_plus&utm_campaign=top",
                        dm_screen_type: "redirect",
                    });
                    window.location.href =
                        "https://liff.line.me/1611905212-3bydBEmv?utm_source=catalina&utm_medium=c_plus&utm_campaign=top";
                    // liff.closeWindow();
                }}
            >
                <div className="flex-1">
                    <div className="text-lg  font-bold">
                        <img src={linelogo} className="h-5 mb-1"/>
                    </div>
                    <div className="text text-sm" style={{color: "#616161"}}>
                        今すぐ使えて毎日おトク 全国40ショップ以上で使える
                    </div>
                </div>
                <ChevronRightIcon className="w-6 h-6"/>
            </div>
        </>
    );
};
