import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import { connect } from "react-redux";
import {
    fetchCategoriesForHomePage, fetchOffersForHomePage,
} from "../../../redux/offer-list/offer-list.actions";
import { useHistory, useLocation } from "react-router-dom";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import liff from "@line/liff";
import AuthService from "../../services/auth.service";
import MembersService from "../../services/members.service";
import {setLIFF_ID, setSocialAuthenticated, setUser} from "../../../redux/global/global.actions";
const mapStateToProps = (state: any) => {
    return {
        isSocialAuthenticated: state.global.isSocialAuthenticated,
        LIFF_ID: state.global.LIFF_ID,
        user: state.global.user
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSocialAuthenticated: (isAuthenticated: string) => dispatch(setSocialAuthenticated(isAuthenticated)),
        setLiffId: (LIFF_ID: string) => dispatch(setLIFF_ID(LIFF_ID)),
        setUser: (data: any) => dispatch(setUser(data)),
    };
};

interface Props {
    setSocialAuthenticated: Function,
    isSocialAuthenticated: any;
    LIFF_ID: any;
    user: any;
    setLiffId: Function;
    setUser: Function;
}

function useQueryParams() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


export const CampaignEndedAprComponent = (props: Props) => {
    let queryParams = useQueryParams();
    let waonId = process.env.REACT_APP_WAON_ID as string;
    let izumiId = process.env.REACT_APP_IZUMI_ID as string;
    let iyId = process.env.REACT_APP_IY_ID as string;
    let iyURL = process.env.REACT_APP_IY_APP_LIFF_URL as string;

    let ybId = process.env.REACT_APP_YB_ID as string;
    let ybURL = process.env.REACT_APP_YB_APP_LIFF_URL as string;

    let allURL = process.env.REACT_APP_ALL_APP_LIFF_URL as string;


    const history = useHistory();
    const { search } = useLocation();
    const [isLoading, setLoading] = useState(false);
    const [isGTMLoading, setGTMLoading] = useState(false);
    const [url, setURL] = useState("");

    let debug  = queryParams.get('debug');
    const bannerStart = (debug === 'true')? new Date('2024/04/02 0:0:0') : new Date(process.env.REACT_APP_BANNER_START_APR);
    const bannerEnd = new Date(process.env.REACT_APP_BANNER_END_APR);
    const todayDate = new Date();
    console.log(todayDate > bannerStart &&  todayDate< bannerEnd)
    useEffect(() => {
        let cacheCleaned = JSON.parse(localStorage.getItem('cache-cleaned'));
        let app_retailer_id = queryParams.get('retailerId');
        const user = JSON.parse(localStorage.getItem("user-data")!);
        if(user){
            props.setUser(user)
        }

        if (!cacheCleaned) {
            // localStorage.clear();
        }
        localStorage.setItem('cache-cleaned', 'true');
        if(app_retailer_id){
            localStorage.setItem('app_retailer_id', app_retailer_id);
        }

        (async () => {
            console.log(props.LIFF_ID);

            let LIFF_ID = getLiffId();
            if(props.LIFF_ID){
                LIFF_ID = props.LIFF_ID;
            } else {
                props.setLiffId(LIFF_ID);
            }
            liff
                .init({ liffId: LIFF_ID })
                .then(async () => {
                    // console.log(liff.isLoggedIn());
                    let app_retailer_id = queryParams.get('retailerId');
                    if (app_retailer_id) {
                        localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
                    }
                    const user = JSON.parse(localStorage.getItem("user-data")!);
                    if(user){
                        props.setUser(user)
                    }
                    let user_id = user ? user.id : 0;

                    if (liff.isLoggedIn()) {
                        let line_email = (await liff.getDecodedIDToken())
                            ? (await liff.getDecodedIDToken()).email
                            : "NA"
                        localStorage.setItem("user-liff-data", JSON.stringify((await liff.getProfile())));
                        localStorage.setItem("user-liff-email", line_email);
                        let userLiffData = JSON.parse(localStorage.getItem('user-liff-data'));
                        window.dataLayer = window.dataLayer || [];
                        console.log({
                            event: "campaignPageLoaded",
                            pageTitle: "campaignPageLoaded",
                            pageUrl: window.location.pathname,
                            dm_user_id: userLiffData?userLiffData.userId:0,
                            user_id: `${user_id}`,
                            dm_screen_type: "other",
                        })
                        window.dataLayer.push({
                            event: "campaignPageLoaded",
                            pageTitle: "campaignPageLoaded",
                            pageUrl: window.location.pathname,
                            user_id: `${user_id}`,
                            dm_user_id: userLiffData?userLiffData.userId:0,
                            dm_screen_type: "other",
                        });

                        // await handleLogin((await liff.getProfile()).userId);
                    } else {
                        // liff.login();
                        window.dataLayer = window.dataLayer || [];
                        console.log({
                            event: "campaignPageLoaded",
                            pageTitle: "campaignPageLoaded",
                            pageUrl: window.location.pathname,
                            dm_user_id: "NA",
                            user_id: `${user_id}`,
                            dm_screen_type: "other",
                        })
                        window.dataLayer.push({
                            event: "campaignPageLoaded",
                            pageTitle: "campaignPageLoaded",
                            pageUrl: window.location.pathname,
                            dm_user_id: "NA",
                            user_id: `${user_id}`,
                            dm_screen_type: "other",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    const getLiffId = () => {
        let LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL as string
        if (queryParams.get('campaign')) {
            if (!queryParams.get('retailerId')) {
                // console.log(queryParams.get('retailers'))
                LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL_CAMPAIGN as string
                setURL(allURL);
                // localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
            } else if (queryParams.get('retailerId') === iyId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_IY_CAMPAIGN as string
                setURL(iyURL);
            } else if (queryParams.get('retailerId') === ybId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_YB_CAMPAIGN as string
                setURL(ybURL);
            }
        } else {
            if (!queryParams.get('retailerId')) {
                // console.log(queryParams.get('retailers'))
                LIFF_ID = process.env.REACT_APP_LIFF_ID_ALL as string
                // localStorage.setItem('app_retailer_id', queryParams.get('retailerId'))
            } else if (queryParams.get('retailerId') === iyId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_IY as string
            } else if (queryParams.get('retailerId') === ybId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_YB as string
            } else if (queryParams.get('retailerId') === izumiId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_YouMe as string
            } else if (queryParams.get('retailerId') === waonId) {
                LIFF_ID = process.env.REACT_APP_LIFF_ID_WAON as string
            }
        }
        return LIFF_ID;
    }
    const liffLogin = (e: React.MouseEvent<HTMLButtonElement>, isGtm : boolean, skip: boolean) => {

        console.log('isGtm', isGtm)
        if(isGtm){
            setGTMLoading(true);
        } else {
            setLoading(true);
        }
        console.log('Screen 0 useEffect')
        let cacheCleaned = JSON.parse(localStorage.getItem('cache-cleaned'));
        let starStore = JSON.parse(localStorage.getItem('fav-store'));
        if (!cacheCleaned) {
            // localStorage.clear();
        }
        // localStorage.setItem('cache-cleaned', 'true')
        let LIFF_ID = props.LIFF_ID;
        if (!props.LIFF_ID) {
            LIFF_ID = getLiffId()
            props.setLiffId(LIFF_ID)
        }

        liff
            .init({ liffId: LIFF_ID })
            .then(async () => {
                // console.log(liff.isLoggedIn());
                if (liff.isLoggedIn()) {
                    let line_email = (await liff.getDecodedIDToken())
                        ? (await liff.getDecodedIDToken()).email
                        : "NA"
                    localStorage.setItem("user-liff-data", JSON.stringify((await liff.getProfile())));
                    localStorage.setItem("user-liff-email", line_email);
                    await handleLogin((await liff.getProfile()).userId, isGtm, skip);
                } else {
                    liff.login();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleLogin = async (userId: any, isGtm : boolean, skip:boolean) => {
        let user_id = props.user ? props.user.id : 0;
        if (isGtm) {
            window.dataLayer = window.dataLayer || [];
            console.log({
                event: "virtualPageview",
                pageTitle: "CampaignBannerPage",
                campaignButtonId: "EnterCampaignButton",
                user_id: `${user_id}`,
                dm_user_id: userId ? userId : 0,
                pageUrl: window.location.pathname, // replace page url with current url
                dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
            });
            window.dataLayer.push({
                event: "virtualPageview",
                pageTitle: "CampaignBannerPage",
                campaignButtonId: "EnterCampaignButton",
                dm_user_id: userId ? userId : 0,
                user_id: `${user_id}`,
                pageUrl: window.location.pathname, // replace page url with current url
                dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
            });
        }
        if (skip) {
            window.dataLayer = window.dataLayer || [];
            console.log({
                event: "virtualPageview",
                pageTitle: "CampaignBannerPage",
                campaignButtonId: "SkipCampaignButton",
                dm_user_id: userId ? userId : 0,
                pageUrl: window.location.pathname, // replace page url with current url
                dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
            });
            window.dataLayer.push({
                event: "virtualPageview",
                pageTitle: "CampaignBannerPage",
                campaignButtonId: "SkipCampaignButton",
                dm_user_id: userId ? userId : 0,
                pageUrl: window.location.pathname, // replace page url with current url
                dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
            });
        }
        AuthService.socialLogin(liff.getAccessToken(), userId)
            .then((response) => {
                props.setSocialAuthenticated(true)

                localStorage.setItem("user-data", JSON.stringify(response.data));
                const user = JSON.parse(localStorage.getItem("user-data")!);
                if(user){
                    props.setUser(user)
                }
                let user_id = user ? user.id : 0;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "virtualPageview",
                    pageTitle: "login",
                    user_id: `${user_id}`,
                    pageUrl: window.location.pathname, // replace page url with current url
                    dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
                });
                localStorage.setItem("user-data", JSON.stringify(response.data));
                let p1 = MembersService.getHolders()
                let p2 = MembersService.getMember();
                Promise.all([p1, p2]).then(response => {
                    let holderResponse = response[0]
                    let memberProfileResponse = response[1]
                    localStorage.setItem("user-card-data", JSON.stringify(holderResponse.data));
                    localStorage.setItem("user-profile", JSON.stringify(memberProfileResponse.data));
                })
                return MembersService.getFavoriteStores();
            })
            .then((response) => {
                setLoading(false);
                setGTMLoading(false)
                if (response.status === 200) {
                    history.replace("/tabs/home");
                } else {

                    history.push("/1");
                }
            })
            .catch((err) => {
                setLoading(false);
                setGTMLoading(false)
                // Local storage issue testing
                if (err.response.status === 404) {
                    localStorage.removeItem("user-data");
                    props.setSocialAuthenticated(true)
                    setTimeout(() => {
                        history.push("/1");
                        console.log(err);
                    }, 2000);
                }
            });
    };
    return (<ScrollpageWithHeader
        title="キャンペーン"
        id="screen-0"
    >
        <div className="flex flex-col items-center justify-start flex-1 overflow-auto details ">
            {/*<img className='' src="https://res.cloudinary.com/cwjp/image/upload/v1707805573/LCM_CAMPAIGN/LCP_Campaign_TopBanner_Feb26_1_eljcb8.png" />*/}
            <img className='' src="https://res.cloudinary.com/cwjp/image/upload/v1712562535/LCM_CAMPAIGN/LCP_AprilCampaign_TopBanner2024_kns2qp.png" />
            {/*Orange Banner*/}
            {(todayDate > bannerStart &&  todayDate< bannerEnd) &&
                <div className='w-full px-5 py-5 text-white font-bold text-center'
                     style={{ backgroundColor: '#FB7B05', borderColor: '#FB7B05' }}>
                    {/*期間 2024.02.26 10:00 – 03.28 23:59*/}
                    期間 2024.04.22 10:00 – 05.07 23:59
                </div>
            }
            {!(todayDate > bannerStart &&  todayDate< bannerEnd) &&
                <div className='w-full px-5 py-5 text-white font-bold text-center'
                     style={{ backgroundColor: '#14B945', borderColor: '#707070' }}>
                    <div>本キャンペーンは終了しました</div>
                    <div>ありがとうございました</div>

                </div>
            }

            {!(todayDate > bannerStart &&  todayDate< bannerEnd) &&
                <div className="px-6 pt-1 mt-8 w-full flex flex-col ">
                    <button
                        type="button"
                        id="skipCampaignButton"
                        onClick={(e) => {
                            liffLogin(e, false, false)
                        }}
                        className="inline-flex items-center justify-center px-4  rounded-md font-bold shadow-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-catalina-light"
                    >
                        <div className="py-4 text-875">クーポンプラスご利用の方はこちら</div>
                        {isLoading && (
                            <div className="pl-5">
                                <IonSpinner name="lines" />
                            </div>
                        )}
                    </button>
                </div>
            }
            {(todayDate > bannerStart &&  todayDate< bannerEnd) &&
                <>
                    <div className="flex flex-col items-center mt-4">
                        <div className="mb-0 text-center">
                            <h1 className='font-bold text-lg'>エントリーのうえ</h1>
                        </div>
                        <h1 className='font-bold text-lg'>クーポンプラスにはじめてカード登録で</h1>
                        {/*<h2 className='text-red-500 mt-2 font-bold text-2xl'>100万LINEポイント山分け</h2>*/}
                        <h2 className='text-red-500 mt-2 font-bold text-2xl text-center'>LINEポイント<br/>
                            20ポイントプレゼント
                        </h2>

                    </div>

                    <div className="px-6 py-6 w-full flex flex-col ">

                        <button
                            type="button"
                            id="enterCampaignButton"
                            onClick={(e) => liffLogin(e, true,false)}
                            className="inline-flex items-center justify-center px-4 border border-transparent rounded-md font-bold shadow-sm text-white bg-catalina-base hover:bg-catalina-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-catalina-light"
                        >
                            <div className="py-4 text-875">キャンペーンにエントリー</div>
                            {isGTMLoading && (
                                <div className="pl-5">
                                    <IonSpinner name="lines" />
                                </div>
                            )}
                        </button>
                    </div>

                    <div className="px-6 pt-1 w-full flex flex-col ">

                        <button
                            type="button"
                            id="skipCampaignButton"
                            onClick={(e) => liffLogin(e, false, true)}
                            className="inline-flex items-center justify-center px-4  rounded-md font-bold shadow-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-catalina-light"
                        >
                            <div className="py-4 text-875">登録済みの方はクーポンプラスへ</div>
                            {isLoading && (
                                <div className="pl-5">
                                    <IonSpinner name="lines" />
                                </div>
                            )}
                        </button>
                    </div>
                    <div className="flex items-center justify-start text-gray-400 px-10 pt-6 text-xs">
                        {/*※次回は12月にキャンペーン開催予定（内容、期間は変更になる場合がございます）<br/>*/}
                        {/*※次回は2月末にキャンペーン開催予定（内容、期間は変更になる場合がございます）<br/>*/}
                        ※次回は6月にキャンペーン開催予定（内容、期間は変更になる場合がございます）<br/>
                        ※エントリーと同時にクーポンプラスに移動します。<br/>
                        ※すでにエントリー済みの方もエントリーボタンは表示されます。

                    </div>
                </>
            }
            <div className="flex flex-col w-full items-center ">

                <div className="pt-6 font-bold">
                    <img className='px-6' src="https://res.cloudinary.com/cwjp/image/upload/v1699628554/LCM/l8rmtad2ekyk5190qecy.png" />
                </div>
                <br />
                <div className='w-full py-2 text-white bg-pink-50'>
                </div>





                <div className='flex flex-col w-full justify-start mt-6  px-6'>
                    <div className="pt-2  text-lg font-bold">
                        キャンペーン概要
                    </div>
                </div>
                <div className=' mt-10  px-6 flex flex-col w-full justify-start '>
                    <div className="pt-2  text-sm font-semibold text-gray-600">
                        キャンペーン名称
                    </div>
                    <div className='mt-2  text-xs text-gray-600'>
                        {/*クーポンプラスにはじめてカード登録で LINEポイント100万ポイント山分けキャンペーン（先着100万名）*/}
                        クーポンプラスにはじめてカード登録で必ずもらえるLINEポイント20ポイントプレゼントキャンペーン
                    </div>
                </div>

                <div className='flex flex-col w-full justify-start mb-2 px-6'>
                    <div className="pt-2  text-sm font-semibold text-gray-600">
                        キャンペーン期間
                    </div>
                    <div className='mt-2  text-xs text-gray-600'>
                        {/*2月26日(月) 10時00分 – 3月28日(木) 23時59分*/}
                        4月22日(月) 10時00分 – 5月7日(火) 23時59分
                    </div>
                </div>

                <div className='px-6'>
                    <div className="pt-2 text-sm font-semibold text-gray-600">
                        キャンペーン条件

                    </div>
                    <p className='mt-2 text-xs text-gray-600'>
                        期間中に本キャンペーン特設ページにてエントリーした後、「クーポンプラス」にて対象ポイントカード・電子マネーをはじめて登録いただいた方にLINEポイント20ポイントを進呈します。
                    </p>

                    {/*<p className='mt-2 text-xs text-gray-600'>*/}
                    {/*    ※ ひとりの獲得上限は100ポイントとなります。*/}
                    {/*</p>*/}
                    {/*<p className='text-xs text-gray-600'>*/}
                    {/*    ※ ポイント付与対象者は先着100万名となります。先着上限に達した場合、キャンペーン期間中でも本キャンペーンを終了する場合がございます。*/}
                    {/*</p>*/}
                    <p className='text-xs text-gray-600'>
                        ※ 対象ポイントカード・電子マネーを登録した時点でポイント付与対象となります。
                    </p>
                    <p className='mt-4 text-xs text-gray-600'>
                        Step1 キャンペーンにエントリー<br />
                        Step2 クーポンプラスにて、対象ポイントカード・電子マネーを登録（会員登録がまだの方は、先に会員登録が必要）

                    </p>
                </div>

                <div className='mt-2'>

                    <div className='px-6'>
                        <div className='pt-2 text-sm font-semibold text-gray-600'>
                            参加方法
                        </div>

                        <p className='mt-4 text-xs text-gray-600'>
                            本キャンペーンの参加にはエントリーが必要となります。こちらのキャンペーン特設ページにてエントリーボタンを押し、対象ポイントカード・電子マネーを登録してください。<br/>
                            対象ポイントカード・電子マネー<br/>
                            電子マネーWAON、電子マネーnanaco、ゆめカード<br/>
                            ※ キャンペーン期間前にいずれかのカードを登録している方はキャンペーン対象外となります。ご了承ください。
                        </p>
                        {/*<p className='text-xs text-gray-600'>*/}
                        {/*    ※ キャンペーン期間前にすでに会員登録している方はキャンペーン対象外となります。ご了承ください。*/}
                        {/*</p>*/}


                    </div>

                    <div className='mt-2  text-sm  px-6 '>
                        <div className="font-semibold text-gray-600 text-sm">
                            特典進呈時期
                        </div>
                        <div className='text-gray-600 mt-2'>
                            <p className='mb-2 text-xs'>
                                2024年6月30日までにポイント進呈対象者へLINEポイントを付与いたします。
                            </p>
                            <p className='mb-2 text-xs'>
                                ※ ポイント付与のご連絡は致しません。ご自身でLINEポイントの獲得履歴をご確認ください。

                            </p>
                            <p className=' mb-2 text-xs'>
                                ※ ポイント進呈時期は予告なく前後する場合がございます。

                            </p>
                        </div>
                    </div>
                    <div className='w-full py-2 text-white bg-pink-50 mb-10 mt-14'>
                    </div>
                    <div className=' mb-2 px-6'>
                        <div className="font-semibold text-gray-600 mb-4 text-lg">
                            注意事項
                        </div>
                        <div className='mt-6 font-semibold text-gray-600 text-sm'>
                            注意事項
                        </div>
                        <p className='mt-2 mb-2 text-gray-600 text-xs'>
                            ・本キャンペーンは予告なく変更・終了または延長する場合があります。
                        </p>
                        <p className='mb-2 text-xs text-gray-600'>
                            ・本キャンペーンは日本国内在住の方に限りご参加いただけます。
                        </p>
                        <p className='mb-2 text-xs text-gray-600'>
                            ・本キャンペーンはエントリー後の取り消しはできません。
                        </p>
                        {/*<p className='mb-2 text-xs text-gray-600'>*/}
                        {/*    ・本キャンペーンで獲得できる報酬は、獲得条件を達成した人数に応じて変動します。ひとりのLINEポイントの獲得上限は100ポイント、下限は1ポイントとなります。*/}
                        {/*</p>*/}
                        <p className='mb-2 text-xs text-gray-600'>
                            ・本キャンペーンにエントリーされても、獲得条件を満たしていない場合、報酬は付与されません。
                        </p>

                        <p className='mb-2 text-xs text-gray-600'>
                            ・本キャンペーンにエントリーされても、報酬獲得よりも前にクーポンプラスを退会、または登録したポイントカード・電子マネーを削除した場合には報酬は付与されません。
                        </p>

                        <p className='mb-2 text-xs text-gray-600'>
                            ・本キャンペーンの期間前にすでにいずれかの対象ポイントカード・電子マネーを登録している場合、今回新たにポイントカード・電子マネーを登録しても報酬は付与されません。また、キャンペーン期間中に複数のポイントカード・電子マネーを登録しても、報酬は一回のみ付与されます。
                        </p>
                        <p className='mb-2 text-xs text-gray-600'>
                            ・本キャンペーンの期間中、登録済みのポイントカード・電子マネーの登録を削除し再登録しても、報酬は付与されません。
                        </p>

                        <p className='mb-2 text-xs text-gray-600'>
                            {/*・本キャンペーンで獲得された権利・報酬は第三者に譲渡できません。*/}
                            ・本キャンペーンで獲得された権利・報酬は第三者に譲渡できません。<br/>
                            ・本キャンペーンへのエントリーはお一人様1回までとなります。


                        </p>
                        <p className='mb-2 text-xs text-gray-600'>
                            ・同一の本キャンペーン参加者による異なる複数のLINEアカウントから参加、不正なアカウントからの参加や、その他当社が不正とみなす行為（なりすまし、イタズラ等）が発覚した場合、各注意事項に違反する場合は、エントリーは無効とさせていただきます。

                        </p> <p className='mb-2 text-xs text-gray-600'>
                        ・本キャンペーンは、カタリナマーケティングジャパン株式会社が主催しています。
                    </p>
                        <p className="mb-2 text-xs text-gray-600">
                            ・本キャンペーンは、LINEポイント付与のために、LINEヤフー株式会社に本サービスで発行される内部識別子を提供します。キャンペーンにエントリーした時点で、内部識別子の提供に同意したものとみなします。
                        </p>
                        <p className="mb-2 text-xs text-gray-600">
                            ・本キャンペーンにおける個人情報の取り扱いは、各社プライバシーポリシーに従います。キャンペーンにエントリーした時点で、各社プライバシーポリシーに同意したものとみなします。
                        </p>
                        <p className="mb-2 text-xs text-gray-600">
                            ・<a href="https://www.catalinamarketing.co.jp/privacy-policy/" className="underline"> カタリナマーケティングジャパン株式会社プライバシーポリシーはこちら</a>
                        </p>
                        <p className="mb-2 text-xs text-gray-600 ">
                            ・<a href="https://line.me/ja/terms/policy/" className="underline">LINEヤフー株式会社プライバシーポリシーはこちら</a>
                        </p>

                    </div>
                    <div className='px-6 mt-4'>
                        <div className="font-semibold text-gray-600 mb-4 text-sm">
                            問い合わせ先
                        </div>
                        <div className='mt-2 text-xs text-gray-600'>
                            ・本キャンペーンおよびクーポンプラスに関するお問い合わせ先
                        </div>
                        <p className='mb-2 text-xs text-gray-600 '>
                            カタリナお客様サポートセンターTEL: 03-3570-6974
                            受付時間: 9:00 -21:00（年中無休）

                        </p>
                        <p className='mb-2 text-xs text-gray-600 '>
                            ・<a href="https://contact-cc.line.me/serviceId/12274" className="underline"> LINEポイントに関するお問い合わせはこちら</a>
                        </p>

                    </div>
                    <div className='text-white w-full mt-10 px-6 py-6 h-40' style={{
                        backgroundColor: "#FB7B05"
                    }}>
                        <div className="font-bold">
                            クーポンプラス
                        </div>
                        <div className='mt-2 flex'>
                            <p className='mr-2 text-xs'>
                                <a href="https://lin.ee/1XbtbJQ/llwa" className="underline"> LINEクーポンへ</a>
                            </p>
                            <p className='mr-2 text-xs'>
                                <a href={url} className="underline"> クーポンプラスへ</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </ScrollpageWithHeader>);
};
export const CampaignEndedApr = connect(mapStateToProps, mapDispatchToProps)(CampaignEndedAprComponent);
