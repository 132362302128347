import {
  IonButton,
  IonItem,
  IonLabel,
  IonSpinner,
  useIonToast,
  useIonViewDidEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MembersService from "../../services/members.service";
import { InformModal } from "../../../components/inform-modal/inform-modal";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import { connect } from "react-redux";
import { setSelectedTab } from "../../../redux/tabs/tabs.actions";
import {
  addToFavStores,
  fetchFavoriteStores,
  fetchStoreToSelect,
  resetError,
  setStore,
} from "../../../redux/retailers/retailers.actions";
import {
  actionGetStoresAtLevelCity,
  actionGetStoresAtLevelPrefecture,
  setSelectedStore
} from "../../../redux/stores/stores.actions";
import { StepHeader2 } from "../../../pages/registration/step-headers/step-headers";
import {setStoreSelection} from "../../../redux/global/global.actions";

const mapStateToProps = (state: any) => {
  return {
    stores: state.retailers.stores,
    store: state.retailers.store,
    retailer: state.retailers.selected,
    prefecture: state.retailers.prefecture,
    city: state.retailers.city,
    area: state.retailers.area,
    loading: state.retailers.loading,
    error: state.retailers.error,
    errorMessage: state.retailers.errorMessage,
    cards: state.cards.holders,
    favoriteStores: state.stores.favoriteStores,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedStore: (data: any) => dispatch(setSelectedStore(data)),
    fetchStoreToSelect: (
      retailer: any,
      prefecture: any,
      city: any,
      area: any
    ) => dispatch(fetchStoreToSelect(retailer, prefecture, city)),
    actionGetStoresAtLevelCity: (retailer: any,
      prefecture: any,
      city: any,) => dispatch(actionGetStoresAtLevelCity(retailer, prefecture, city)),
    setStore: (store: any) => dispatch(setStore(store)),
    fetchFavoriteStores: (store: any) => dispatch(fetchFavoriteStores()),
    addToFavStores: (storeId: any) => dispatch(addToFavStores(storeId)),
    setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
    setStoreSelection: (data: any) => dispatch(setStoreSelection(data)),
    resetError: () => dispatch(resetError()),
  };
};
interface Props {
  stores: any;
  fetchStoreToSelect: Function;
  actionGetStoresAtLevelCity: Function;
  setStore: Function;
  addToFavStores: Function;
  resetError: Function;
  fetchFavoriteStores: Function;
  setSelectedStore: Function;
  setStoreSelection: Function;
  retailer: any;
  loading: any;
  error: boolean;
  errorMessage: any;
  prefecture: any;
  city: any;
  area: any;
  store: any;
  cards: any[];
  setSelectedTab: Function;
  favoriteStores: any[];
}

export const Screen7Component = (props: Props) => {
  const [present, dismiss] = useIonToast();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cardForRetailer, setCardForRetailer] = useState<string>("");
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  let [stores, setStores] = useState<any[]>([]);
  const history = useHistory();

  const addToFavStores = (selectedStoreId: any, selectedStore: any) => {
    MembersService.addStoreToFavs(selectedStoreId, selectedStore)
      .then((responseJson: any) => {
        props.fetchFavoriteStores();
        setStores([]);
        props.setStoreSelection(false)
        checkForHolderCardsAndNavigate(selectedStore);
      })
      .then(() => {
        present("店舗が登録されました。", 2000);
      })
      .catch((error: any) => {
        if (error.response && error.response.data) {
          // setErrorMessage(error.response.data.error);
          // setOpenErrorModel(true);
        }
        if (error.response && error.response.status === 422) {
          if(selectedStore){
            checkForHolderCardsAndNavigate(selectedStore);
          }
          // present("既にリストに保存。", 2000);
          props.setSelectedTab('home');
          history.push("/tabs/home");
          // setErrorMessage(error.response.data.error);
          // setOpenErrorModel(true);
        }
      });
  };

  const checkForHolderCardsAndNavigate = (selectedStore: any) => {
    localStorage.setItem('fav-store', JSON.stringify(selectedStore))
    let card = props.cards.filter((card) => {
      return card.retailer_id === props.retailer.id
    })[0]
    setCardForRetailer(card);
    props.setSelectedStore(selectedStore);
    if (!card) {
      history.push("/9");
    } else {
      setTimeout(()=>{
        props.setSelectedTab('home');
        history.push("/tabs/home");
      }, 1000)
    }
  };

  useIonViewDidEnter(() => {
    // init();
  });
  const init = () => {
    setCardForRetailer(props.cards.filter((card) => {
      return card.retailer_id === props.retailer.id
    })[0]);

    // console.log('Found the card', )
    // if (props.retailer && props.prefecture && props.city && props.area) {
    if (props.retailer && props.prefecture && props.city) {
      props.fetchStoreToSelect(props.retailer, props.prefecture, props.city);
      props.actionGetStoresAtLevelCity(props.retailer, props.prefecture, props.city);
    } else {
      history.replace("/2");
    }
  };
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (props.city) {
      init();
    }
  }, [props.city]);

  return (
    <ScrollpageWithHeader title="店舗選択" id="7">
      {props.loading && (
        <div className="mt-6 flex justify-center">
          <IonSpinner />
        </div>
      )}

      <div className="details flex flex-col justify-start pb-6">
        <StepHeader2 />
        <div className="text-lg text-left">{props.area}</div>
        {openErrorModel && (
          <div className="text-red-600 text-xs -mt-3 mb-10">{errorMessage}</div>
        )}
        {!props.loading && props.stores &&
          props.stores.map((item: any) => (
            <div className="block mt-2">
              <IonItem
                onClick={(e) => {
                  if (!item.isFav) {
                    addToFavStores(item.id, item);
                  } else {
                    // localStorage.setItem('fav-store', JSON.stringify(item));
                    checkForHolderCardsAndNavigate(item);
                  }
                }}
                detail={true}
                lines="none"
                className="mt-2"
                key={item.id}
              >
                <IonLabel>{item.name}</IonLabel>
                {/* <IonButton
                className="text-lg"
                color="primary"
                onClick={(e) => {
                  if(!item.isFav) {
                    addToFavStores(item.id, item);
                  }
                }}
                disabled={item.isFav}
              >
                追加
              </IonButton> */}
              </IonItem>
            </div>
          ))}
        {!props.loading && props.stores &&
        props.stores.length===0 &&
        <div className="my-auto flex-shrink-0 py-16 sm:py-32">
          {/*<h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">都市が見つかりません</h1>*/}
          <p className="mt-2 text-base text-xs text-gray-600" >データ取得に失敗しました。他の市町村を試すか、後ほど再度お試しください</p>
          <div className="mt-6">
            <a className="text-base font-medium text-indigo-600 hover:text-indigo-500" style={{
              color: 'rgb(6, 199, 85)'
            }} onClick={()=>{
              history.push("/tabs/home");
            }
            }>
              「ホーム」に戻る
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
        }
        {/* <div className="px-2 mt-2">
          <div className="flex">
            <IonButton
              color="light"
              expand="block"
              onClick={() => {
                checkForHolderCardsAndNavigate();
              }}
              className="w-full"
            >
              あとで登録する
            </IonButton>
          </div>
        </div> */}
      </div>

      {/* {openErrorModel && (
        <InformModal
          message={errorMessage}
          open={openErrorModel}
          setOpen={setOpenErrorModel}
        />
      )} */}
    </ScrollpageWithHeader>
  );
};
export const Screen7 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen7Component);
