import { IonHeader } from "@ionic/react";
import logo from "./logo.png";
import {
  CreditCardIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  UserCircleIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";

const buttons = [
  {
    icon: CreditCardIcon,
    isSelected: true,
  },
  {
    icon: ShoppingBagIcon,
    isSelected: false,
  },
  {
    icon: ShoppingCartIcon,
    isSelected: false,
  },
  {
    icon: UserCircleIcon,
    isSelected: false,
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const Header = () => {
  return (
    <IonHeader className=" px-4 bg-okuwa_blue flex justify-between items-center">
      <Link
        className=""
        to="/pages"
      >
        <img className="h-12 my-1" src="https://vignette1.wikia.nocookie.net/cookierun/images/2/28/Logo_line_circle.png/revision/latest?cb=20150817074324" />
      </Link>
      <div className="flex items-center">
        {buttons.map((button) => (
          <div
            className={classNames(
              "ml-5",
              button.isSelected
                ? "p-1 bg-white rounded-full"
                : "bg-okuwa_blue text-white"
            )}
          >
            <button.icon
              className={classNames(
                "h-6 w-6",
                button.isSelected ? "text-okuwa_blue" : "bg-okuwa_blue"
              )}
            />
          </div>
        ))}
      </div>
    </IonHeader>
  );
};
