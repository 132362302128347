import { IonIcon, IonImg, useIonViewDidEnter } from "@ionic/react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import card1 from "./card_default.png";
import questionCircle from "./Question Circle.png";
import React, { useEffect, useState } from "react";
import {resetHierarchy, selectRetailerForAccount, setRetailer} from "../../../redux/retailers/retailers.actions";
import waon from "../../screens/screen2/1.png";
import izumi from "../../screens/screen2/2.png";
import yb from "../../screens/screen2/6.png";
import iy from "../../screens/screen2/10.png";
import grayboxicon from "./gray-box-icon.png";
import { ChevronRightIcon, XIcon } from "@heroicons/react/solid";
import { Popup1 } from "../../screens/account-screens/account-editing/popup1";
import { NoStorePopup } from "./NoStorePopup";
import store1 from "../member-store/store_default.png";

const mapStateToProps = (state: any) => {
  return {
    cards: state.cards.holders,
    retailers: state.retailers.list,
    selectedStore: state.stores.selectedStore
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectRetailerForAccount: (data: any) => dispatch(selectRetailerForAccount(data)),
    resetHierarchy: (data: any) => dispatch(resetHierarchy()),
    setRetailer: (data: any) => dispatch(setRetailer(data)),
  };
};

interface Props {
  cards: any[];
  retailers: any[];
  retailer_id?: any;
  accountScreenMode?: boolean;
  selectedStore?: any;
  selectRetailerForAccount: Function;
  resetHierarchy: Function;
  setRetailer: Function;
  stepSection?: any;
}

const scrollDown = (ref: any) => {
  ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  console.log('scrolled' + window.screenX)
};

export const MemberHolderComponent = (props: Props) => {
  const [showBanner, setShowBanner] = useState(true);
  const [showNoStorePopup, setShowNoStorePopup] = useState(false);
  useIonViewDidEnter(() => {
    setShowBanner(true);
  })
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;


  let todayDate = new Date();
  const bannerStart = new Date(process.env.REACT_APP_BANNER_START_FEB);
  const bannerEnd = new Date(process.env.REACT_APP_BANNER_END_FEB);

   // true
  let imageURL: any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  useEffect(() => {
    if (props.retailers.length === 1) {
      props.selectRetailerForAccount(props.retailers[0]);
    }
  }, [])
  // const holder = props.cards[0];
  const selectedStore = props.selectedStore ? props.selectedStore : { retailer_id: "-1" };

  let holder = props.accountScreenMode ? props.cards.filter(
    (c) => c.retailer_id === parseInt(props.retailer_id)
  )[0] : props.cards.filter(
    (c) => c.retailer_id === parseInt(selectedStore.retailer_id)
  )[0]
  // if(!holder && props.cards.length===1){
  //   holder= props.cards[0];
  // }
  const history = useHistory();
  let app_retailer_id = localStorage.getItem("app_retailer_id");

  let retailer_id = props.accountScreenMode ? props.retailer_id : holder ? holder.retailer_id : props.selectedStore ? props.selectedStore.retailer_id : app_retailer_id ? app_retailer_id : null;

  // let retailers = [waonId, izumiId, iyId, ybId]
  let id_filter: any = [];

  if (app_retailer_id) {
    id_filter = app_retailer_id.split(',');
    let registeredCards = []
    if (id_filter.length > 0) {
      registeredCards = props.cards.filter((c1) => id_filter.includes(c1.retailer_id.toString()))
    }
    if (registeredCards.length > 0) {
      holder = registeredCards[0];
    }
  } else {
    if (!retailer_id && !holder && props.cards.length > 0) {
      holder = props.cards[0];
    }
  }

  retailer_id = props.accountScreenMode ? props.retailer_id : holder ? holder.retailer_id : props.selectedStore ? props.selectedStore.retailer_id : app_retailer_id ? app_retailer_id : null;
  // let cardSrc = selectedStore.retailer_id!=-1 ? imageURL[selectedStore.retailer.id] : card1
  let cardsrc = ""
  // console.log(holder)
  // console.log(holder && holder.retailer_id === parseInt(retailer_id) ? imageURL[holder.retailer.id] : imageURL[selectedStore.retailer.id])
  if (props.accountScreenMode) {
    cardsrc = imageURL[retailer_id]
  }
  else if (selectedStore.retailer_id != -1) {
    // console.log("Store selected")
    cardsrc = holder && holder.retailer_id === parseInt(retailer_id) ? imageURL[holder.retailer.id] : imageURL[selectedStore.retailer.id]
  } else {
    // console.log("Store not selected")
    if (holder && holder.retailer_id) {
      cardsrc = imageURL[holder.retailer.id]
    } else {
      cardsrc = card1
    }

  }
  const goToCampaign = () => {
    let userLiffData = JSON.parse(localStorage.getItem('user-liff-data'));
    window.dataLayer = window.dataLayer || [];
    console.log({
      event: "virtualPageview",
      pageTitle: "home",
      campaignButtonId: "VisitCampaignButton",
      dm_user_id: userLiffData ? userLiffData.userId : 0,
      pageUrl: window.location.pathname, // replace page url with current url
      dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
    });
    window.dataLayer.push({
      event: "virtualPageview",
      pageTitle: "home",
      campaignButtonId: "VisitCampaignButton",
      dm_user_id: userLiffData ? userLiffData.userId : 0,
      pageUrl: window.location.pathname, // replace page url with current url
      dm_screen_type: "other", // if list of coupons displayed then "list_view" , if one coupon displayed as product then "product_view" otherwise set to "other"})
    });
    let app_retailer_id = localStorage.getItem("app_retailer_id");
    let url = "/0"
    if(app_retailer_id){
      url = url + '?retailerId='+app_retailer_id
    }
    history.push(url);
  }
  return (
    <div className="block  bg-white">
      <div className="flex items-center px-4 pt-3 bg-white">
        <div>
          <img
            className="items-center justify-center rounded-md flex overflow-hidden object-contain"
            style={holder && holder.retailer_id === parseInt(retailer_id) ? {
              // height: "2.875rem",
              // width: "3.875rem",
              height: "3.125rem",
              width: "3.125rem",
            } : {
              height: "3.125rem",
              width: "3.125rem",
            }}
            src={cardsrc}
            alt="Card"
          />
        </div>
        {holder && holder.retailer_id === parseInt(retailer_id) && (
          <div className="flex-1 pl-4">
            {/* <div className="text-xs text-gray-500">{holder.retailer.name}</div> */}
            {holder.ref === "" && <div className="text-green-400 text">登録</div>}
            {holder.ref && holder.retailer_id === Number(izumiId) && (
              <div>
                <div style={{ color: "#1F1F1F", fontSize: 14 }}>
                  {holder.ref.substr(1, 4).replace(new RegExp("\\d", "g"), "X")}-
                  {holder.ref.substr(5, 6).replace(new RegExp("\\d", "g"), "X")}
                  {holder.ref.substr(10, 2)}-
                  {holder.ref.substr(-1)}
                </div>
                {!props.accountScreenMode && holder.retailer_id === Number(waonId) && (
                  <>
                    <div className="text-xs mt-3" style={{ fontSize: 14, color: "#666666" }}>
                      Apple Pay の WAON / イオンカード (WAON一体型)
                      <Link to={"/9"} className="text">
                        <b className="underline"> ご利用上の注意</b>
                      </Link>
                    </div>
                    {/*<div className="text-xs mt-3" style={{ fontSize: 14, color: "#FF0000" }}>*/}
                    {/*  <p onClick={() => {*/}
                    {/*    window.location.href =*/}
                    {/*      "https://lin.ee/2MHiDLiO/llwa";*/}
                    {/*  }} className="underline"> ※3月に開催した春トク祭りキャンペーン詳細はこちら</p>*/}
                    {/*</div>*/}
                  </>
                )}
                {!props.accountScreenMode && (holder.retailer_id === Number(iyId) || holder.retailer_id === Number(ybId)) && (
                  <div className="text-xs mt-3" style={{ fontSize: 14, color: "#666666" }}>
                    Apple Pay の nanaco / セブンカード・プラス（nanaco一体型）
                    <Link to={"/9"} className="text">
                      <b className="underline"> ご利用上の注意</b>
                    </Link>
                  </div>
                )}

              </div>
            )}
            {holder.ref && holder.retailer_id !== Number(izumiId) && (
              <div>
                <div style={{ color: "#1F1F1F", fontSize: 14 }}>
                  {holder.ref.substr(1, 4).replace(new RegExp("\\d", "g"), "X")}-
                  {holder.ref.substr(5, 4).replace(new RegExp("\\d", "g"), "X")}-
                  {holder.ref.substr(9, 4).replace(new RegExp("\\d", "g"), "X")}-
                  {holder.ref.substr(-4)}
                </div>
                {!props.accountScreenMode && holder.retailer_id === Number(waonId) && (
                  <>
                    <div className="text-xs mt-3" style={{ fontSize: 14, color: "#666666" }}>
                      Apple Pay の WAON / イオンカード (WAON一体型)
                      <Link to={"/9"} className="text">
                        <b className="underline"> ご利用上の注意</b>
                      </Link>
                    </div>
                    {/*<div className="text-xs mt-3" style={{ fontSize: 14, color: "#FF0000" }}>*/}
                    {/*  <p onClick={() => {*/}
                    {/*    window.location.href =*/}
                    {/*      "https://lin.ee/2MHiDLiO/llwa";*/}
                    {/*  }} className="underline"> ※3月に開催した春トク祭りキャンペーン詳細はこちら</p>*/}
                    {/*</div>*/}
                  </>
                )}
                {!props.accountScreenMode && (holder.retailer_id === Number(iyId) || holder.retailer_id === Number(ybId)) && (
                  <div className="text-xs mt-3" style={{ fontSize: 14, color: "#666666" }}>
                    Apple Pay の nanaco / セブンカード・プラス（nanaco一体型）
                    <Link to={"/9"} className="text">
                      <b className="underline"> ご利用上の注意</b>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {holder && holder.retailer_id != parseInt(retailer_id) && (
          <div className="flex-1 pl-4">
            <div style={{ color: "#1F1F1F", fontSize: 14 }}>ポイントカード</div>
          </div>
        )}
        {/*  No holder */}

        {/*{!holder && (*/}
        {/*  <div className="flex-1 pl-4 ">*/}
        {/*    <div style={{ color: "#1F1F1F", fontSize: 14 }}>ポイントカードを登録</div>*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*Case 4 implemented*/}
        {!holder && !props.accountScreenMode && retailer_id !== null && (
          <div style={{ color: "#06C755" }}>
            <Link to={"/9"} className="text pl-6 font-bold">
              カードを登録
            </Link>
          </div>
        )}

        {/*Case 2 && 3 implemented*/}
        {!holder && !props.accountScreenMode && retailer_id === null && (
          <div style={{ color: "#06C755" }}>
            {props.retailers.length === 1 && <div style={{ color: "#06C755" }}>
              <Link to={"/9"} className="text pl-4 font-bold">
                カードを登録
              </Link>
            </div>}
            {!props.selectedStore && props.retailers.length > 1 &&

              <div style={{ color: "#06C755" }} onClick={() => {
                setShowNoStorePopup(true)
              }
              }>
                <div className="text pl-4 font-bold">
                  カードを登録
                </div>
              </div>
            }
            {props.selectedStore && props.retailers.length > 1 &&

              <div style={{ color: "#06C755" }}>
                <Link to={"/select-retailer"} className="text pl-4 font-bold">
                  ポイントカードを登録
                </Link>
              </div>
            }
          </div>
        )}

        {/*  No holder and account screen mode*/}
        {!holder && props.accountScreenMode && (
            <div style={{ color: "#06C755" }} className="flex flex-1">
              <Link to={"/9"} className="text pl-6 font-bold">
                カードを登録する
              </Link>
            </div>
        )}
        {/* {!holder &&  (
              <div style={{ color: "#06C755", fontWeight: 600 }}>
                <Link to={"/9"} className="text pl-6">
                  登録
                </Link>
              </div>
          )} */}
        {holder && holder.retailer_id === parseInt(retailer_id) && props.accountScreenMode && (
          <div style={{ color: "#06C755", fontWeight: 600 }}>
            <Link to={"/9"} className="text pl-6">
              編集
            </Link>
          </div>
        )}
        {holder && holder.retailer_id != parseInt(retailer_id) && (
          <div style={{ color: "#06C755", fontWeight: 600 }}>
            <Link to={"/9"} className="text pl-6">
              登録
            </Link>
          </div>
        )}

        {/* {holder && (
        <div className="text-green-400 text">
          <Link to={"/9"} className="text pl-6">
            編集
          </Link>
        </div>
      )} */}
        {props.accountScreenMode && (
          <div className="text-green-400 text flex justify-items-end">
            <IonImg
              slot="icon-only"
              src={questionCircle}
              onClick={() => {
                history.push(`/account/retailer-about`);
              }}
            />
          </div>
        )}
      </div>
      {/*New Orange and Grey buttons*/}

      {!holder && !props.accountScreenMode &&
        <div className="mt-2">
          <div className="pointer"></div>
          <div
            className="p-3 mx-3 mb-2 flex flex-col items-center text-center "
            style={{ background: "#FFF4D8", borderRadius: "0.5rem" }}
          >
            {
              retailer_id === null && <div
                className="text-sm font-semibold"
                style={{ color: "#FF6814" }}
              >
                お店とカードが未登録です
                </div>
            }

            {
              retailer_id !== null && <div
                className="text-sm font-semibold"
                style={{ color: "#FF6814" }}
              >
                カードが未登録です
                </div>
            }

            <div
              className="text-xs pt-2 font-normal"
              style={{ color: "#FF6814" }}
            >
             お店とカードを登録しないと使えません


            </div>
            <div className="mt-2 text-white w-24 flex rounded-full justify-center items-center" style={{
              background: "#FF6814"
            }}>
              {!holder && !props.accountScreenMode && retailer_id === null && (
                <>
                  <div className="text-xs py-2 pl-2" style={{
                    fontWeight: 600
                  }} onClick={() => history.push("/2")} >今すぐ登録</div>
                  <ChevronRightIcon className="h-5 w-5" />
                </>
              )}
              {!holder && !props.accountScreenMode && retailer_id !== null && (
                <>
                  <div className="text-xs py-2 pl-2" style={{
                    fontWeight: 600
                  }} onClick={() => history.push("/9")} >今すぐ登録</div>
                  <ChevronRightIcon className="h-5 w-5" />
                </>
              )}

            </div>
          </div>
        </div>
      }

      {/*{props.cards.length===0 && (todayDate > bannerStart &&  todayDate< bannerEnd) &&*/}
      {/*  <div className="flex flex-col items-center justify-start flex-1 overflow-auto details p-4">*/}
      {/*    <img className='' src="https://res.cloudinary.com/cwjp/image/upload/v1683877247/LCM_CAMPAIGN/CP_0428_updated_Button_muuoo2.png" onClick={goToCampaign} />*/}
      {/*  </div>}*/}
      {holder && !props.selectedStore && !props.accountScreenMode &&
        <div className="mt-4">
          <div className="pointer"></div>
          <div
              className="px-4 mx-3 mb-2 flex flex-col items-center text-center "
              style={{background: "#FFF4D8", borderRadius: "0.5rem"}}
          >
            <div
                className="text-sm pt-1188 font-semibold"
                style={{color: "#FF6814"}}
            >
              お店が未登録です

            </div>
            <div
                className="text-xs pt-438 pb-1188 font-normal"
                style={{color: "#FF6814"}}
            >
              お店とカードを登録しないと使えません
            </div>
            <div className="text-white w-24 flex rounded-full justify-center items-center mb-4" style={{
              background: "#FF6814"
            }}>
              <div className="text-xs py-2 pl-2" style={{
                fontWeight: 600
              }} onClick={() => {
                //logic to be added here
                props.resetHierarchy()
                let ret = props.retailers.filter((retailer)=>{
                  return retailer.id === holder.retailer_id;
                })[0]
                props.setRetailer(ret)
                setTimeout(() => {
                  history.push("/4");
                }, 1000);
              }
              }>今すぐ登録
              </div>
              <ChevronRightIcon className="h-5 w-5"/>
            </div>
            {!holder && !props.accountScreenMode && retailer_id === null && (
                <div className="mt-2 text-white w-24 flex rounded-full justify-center items-center" style={{
                  background: "#FF6814"
                }}>
                  <div className="text-xs py-2 pl-2" style={{
                    fontWeight: 600
                  }} onClick={() => history.push("/2")}>今すぐ登録
                  </div>
                  <ChevronRightIcon className="h-5 w-5"/>
                </div>
            )}
            {!holder && !props.accountScreenMode && retailer_id !== null && (
                <div className="mt-2 text-white w-24 flex rounded-full justify-center items-center" style={{
                  background: "#FF6814"
                }}>
                  <div className="text-xs py-2 pl-2" style={{
                    fontWeight: 600
                  }} onClick={() => history.push("/9")}>今すぐ登録
                  </div>
                  <ChevronRightIcon className="h-5 w-5"/>
                </div>
            )}


          </div>
        </div>
      }

      {props.cards.length === 0 && (id_filter.length === 0 || app_retailer_id === ybId) && (todayDate > bannerStart && todayDate < bannerEnd) &&
          <div className="flex flex-col items-center justify-start flex-1 overflow-auto details py-4 px-3">
            <img className=''
                 src="https://res.cloudinary.com/cwjp/image/upload/v1694048651/LCM_CAMPAIGN/Banner_CardUser_sep_k83b5c.png" onClick={goToCampaign}
            />
          </div>
      }
      {(!holder||!props.selectedStore) &&  showBanner && !props.accountScreenMode &&
        <div className="relative p-5 mx-3 mb-2 flex flex-col items-center text-center rounded-lg" style={{ background: "#F1F1F1" }}>
          <img src={grayboxicon} className="w-20" />
          <div className="text-sm font-semibold mt-2">登録すればレジでクーポンを見せずに</div>
          <div className="text-sm font-semibold">全国主要スーパーでポイントゲット！</div>
          <div className="text-xs font-semibold underline mt-2.5" onClick={() => {
            // scrollDown(props.stepSection);
            history.push(`/coupon-plus-info`);
          }}>クーポンプラスについて</div>
          <div className="absolute top-2 right-2 text-gray-600 cursor-pointer" onClick={() => {
            setShowBanner(false)
          }}>
            <XIcon className="h-5 w-5" />
          </div>
        </div>}

      {
        showNoStorePopup &&
        <NoStorePopup handleConfirm={setShowNoStorePopup} open={showNoStorePopup} setOpen={setShowNoStorePopup} />
      }
    </div>
  );
};

export const MemberHolder = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberHolderComponent);
