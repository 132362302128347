import { HalfSplitBackgroundShell } from "../../../shells/half-split-background-shell/half-split-background-shell";
import { FieldWithIcon } from "../../../shells/components/field-with-icon/field-with-icon";
import { FullRoundButton } from "../../../shells/components/full-round-button/full-round-button";
import {
  MailIcon,
  UserCircleIcon,
  LockClosedIcon,
} from "@heroicons/react/solid";

import google from "../logos/google.png";
import facebook from "../logos/facebook.png";
import twitter from "../logos/twitter.png";
import { SocialIcons } from "../social-icons/social-icons";
import { BottomTermsCopywight } from "../bottom-terms-copyright/bottom-terms-copyright";
import { useState } from "react";
import { useIonAlert } from "@ionic/react";
import { InformModal } from "../../../components/inform-modal/inform-modal";
import { useHistory } from "react-router-dom";

const logos = [
  {
    image: google,
  },
  {
    image: facebook,
  },
  {
    image: twitter,
  },
];

export const LoginScreen = () => {
  const [present] = useIonAlert();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);

  const handleLogin = () => {
    fetch("https://cmjp-test.azure-api.net/wl/members/authentication", {
      "method": "POST",
      "headers": {
        "Ocp-Apim-Subscription-Key": "d0b58c66d44840d39babd56412d7b081",
        "Ocp-Apim-Trace": "true",
        "Content-Type": "application/json"
      },
      "body": JSON.stringify({
        email,
        password
      })
      })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.access_token) {
          localStorage.setItem('user-data', JSON.stringify(response));
          history.push('get-started');
        } else {
          setErrorMessage(response.error);
          setOpenErrorModel(true);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  return (
    <HalfSplitBackgroundShell>
      <FieldWithIcon
        Icon={MailIcon}
        value={email}
        onChange={(value: string) => setEmail(value)}
        placeholder="電子メールアドレス"
        type="email"
      />
      <FieldWithIcon
        Icon={LockClosedIcon}
        value={password}
        onChange={(value: string) => setPassword(value)}
        placeholder="パスワード"
        type="password"
      />
      <FullRoundButton text="パスワード変更" onClick={() => handleLogin()}/>
      <div className="text-sm text-okuwa_blue text-right">
        パスワードをお忘れですか？
      </div>
      <div className="text-sm text-center text-gray-500 pt-10">
        ログイン画面へ戻る
      </div>
      <SocialIcons />
      <BottomTermsCopywight />
      {
        openErrorModel && <InformModal message={errorMessage} open={openErrorModel} setOpen={setOpenErrorModel}/>
      }
    </HalfSplitBackgroundShell>
  );
};
