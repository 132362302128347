interface Props {
  text: string;
  onClick?: Function;
}

export const FullRoundButton = (props: Props) => {
  return (
    <div className="py-3 bg-gray-50 text-center w-full">
      <button
        type="submit"
        onClick={() => {
          props.onClick!()
        }}
        className="bg-okuwa_blue w-full rounded-md shadow-sm py-4 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {props.text}
      </button>
    </div>
  );
};
