import { SET_SELECTED_OFFER } from "./offer-details.types";

const INITIAL_STATE = {
  selectedOffer: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  // console.log("Reducer: ", state, action);
  switch (action.type) {
    case SET_SELECTED_OFFER:
      // console.log("Action: ", action);
      return {
        ...state,
        selectedOffer: { ...action.payload },
      };

    default:
      return state;
  }
};

export default reducer;
