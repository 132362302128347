import {CheckCircleIcon, ChevronRightIcon} from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";

import { useEffect, useState } from "react";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import {IonSpinner, useIonViewDidEnter} from "@ionic/react";
import {connect} from "react-redux";
import {fetchRetailers, resetHierarchy, setRetailer} from "../../../redux/retailers/retailers.actions";
import waon from "./1.png";
import izumi from "./2.png";
import yb from "./6.png";
import iy from "./10.png";
import {setStoreSelection} from "../../../redux/global/global.actions";


function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const mapStateToProps = (state: any) => {
    return {
        retailers: state.retailers.list,
        loading: state.retailers.loading,
        selectedStore: state.stores.selectedStore,
        storeSelection:state.global.storeSelection

    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchRetailers: () => dispatch(fetchRetailers()),
        setRetailer: (data: any) => dispatch(setRetailer(data)),
        resetHierarchy: (data: any) => dispatch(resetHierarchy()),
        setStoreSelection: (data: any) => dispatch(setStoreSelection())
    };
};
interface Props {
    fetchRetailers: Function;
    setRetailer: Function;
    resetHierarchy: Function;
    retailers:any;
    selectedStore:any;
    loading:boolean;
    storeSelection:boolean;
    setStoreSelection:Function;
}
export const Screen2Component = (props: Props) => {
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;

  let imageURL:any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  const [selectedId, setSelectedId] = useState(0);
  const history = useHistory();
    useIonViewDidEnter(()=>{
        init();
    })
  useEffect(() => {
   init()
  }, []);
    const init = () => {
        setSelectedId(0);
        props.resetHierarchy();
        props.fetchRetailers();
    }
  return (
    <ScrollpageWithHeader title="カタリナ" id="screen-2">
      <div className="px-4 pb-6">
        {props.loading ||
          (selectedId !== 0 && (
            <div className="mt-6 flex justify-center">
              <IonSpinner />
            </div>
          ))}
        {props.retailers &&
          props.retailers.length != 0 &&
          props.retailers.map((card: any) => (
            <div
              onClick={() => {
                if (selectedId !== 0) {
                  return false;
                }
                setSelectedId(card.id);
                props.setRetailer(card);
                setTimeout(() => {
                  setSelectedId(0);
                  if(props.storeSelection){
                      // props.setStoreSelection(false)
                      history.push("/4");
                  } else {
                      // props.setStoreSelection(false)
                      history.push("/9");
                  }

                }, 2000);
              }}
              key={card.id}
              className={classNames(
                "card mt-4 flex items-center py-2",
                selectedId === card.id ? "bg-gray-100 rounded-md" : ""
              )}
            >
              <img
                className="items-center justify-center rounded-md flex overflow-hidden object-contain"
                style={{
                  height: "2.875rem",
                  width: "3.875rem",
                }}
                src ={imageURL[card.id]}
              />
              <div className="pl-3 text-sm flex-1 items-center">
                {card.name}
              </div>
                {selectedId === card.id && <CheckCircleIcon className="h-5 w-5 text-green-600"/>}
              <ChevronRightIcon className="h-5 w-5" style={{color: "#C8C8C8"}} />
            </div>
          ))}

      </div>
    </ScrollpageWithHeader>
  );
};
export const Screen2 = connect(
    mapStateToProps,
    mapDispatchToProps
)(Screen2Component);