import {
    IonButton,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonNote
} from "@ionic/react";

import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import { StarIcon } from "@heroicons/react/solid";
import { eventBus } from "../../event-bus/event-bus";
import MembersService from "../../services/members.service";
import { connect } from "react-redux";
import { setSelectedStore } from "../../../redux/stores/stores.actions";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { setSelectedTab } from "../../../redux/tabs/tabs.actions";
import {setEvent, setStoreSelection} from "../../../redux/global/global.actions";
import { setRetailer } from "../../../redux/retailers/retailers.actions";
import waon from "../screen2/1.png";
import izumi from "../screen2/2.png";
import yb from "../screen2/6.png";
import iy from "../screen2/10.png";

const mapStateToProps = (state: any) => {
    return {
        stores: state.stores,
        state: state,
        retailers: state.retailers,
        selectedStore: state.stores.selectedStore,
        favoriteStores: state.stores.favoriteStores
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedStore: (data: any) => dispatch(setSelectedStore(data)),
        setEvent: (data: any) => dispatch(setEvent(data)),
        setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
        setRetailer: (data: any) => dispatch(setRetailer(data)),
        setStoreSelection: (data: any) => dispatch(setStoreSelection(data)),
    };
};

interface Props {
    state: any;
    stores: any;
    retailers: any;
    selectedStore: any;
    favoriteStores: any;
    setSelectedStore: Function;
    setStoreSelection: Function;
    setEvent: Function;
    setSelectedTab: Function;
    setRetailer: Function;
}

export const Screen38Component = (props: Props) => {
    let [stores, setStores] = useState<any>([]);
    let [retailers, setRetailers] = useState<any>({});
    let waonId = process.env.REACT_APP_WAON_ID as string;
    let izumiId = process.env.REACT_APP_IZUMI_ID as string;
    let iyId = process.env.REACT_APP_IY_ID as string;
    let ybId = process.env.REACT_APP_YB_ID as string;

    let imageURL: any = {};
    imageURL[waonId] = waon;
    imageURL[izumiId] = izumi;
    imageURL[iyId] = iy;
    imageURL[ybId] = yb;

    const history = useHistory();
    function toGroup(obj: any, prop: any) {
        return obj.reduce(function (acc: any, item: any) {
            let key = item[prop]
            if (!acc[key]) {
                acc[key] = []
            }
            acc[key].push(item)
            return acc
        }, {})
    }

    function toGroupStores(obj: any, prop: any, retailer_ids:any) {
        return obj.reduce(function (acc: any, item: any) {
            let key = item[prop]

            if(retailer_ids.includes(key)){
                if (!acc[key]) {
                    acc[key] = []
                }
                acc[key].push(item)
            }
            return acc
        }, {})
    }

    useEffect(() => {
        if (props.favoriteStores.length == 0) {
            props.setSelectedTab('home');
            history.replace('/tabs/home')
        } else {
            let retailer_ids = props.retailers.list.map((retailer:any) => {
                return retailer.id
            });

            let retailers = toGroup(props.retailers.list, 'id')
            // console.log(retailers)
            setRetailers(retailers)
            let groupedStores = toGroupStores(props.favoriteStores, 'retailer_id', retailer_ids)
            // console.log('Grouped stores: ', props.favoriteStores, groupedStores);

            setStores(groupedStores);
            // console.log(props.retailers)
        }
    }, [props.favoriteStores])

    return (
        <ScrollpageWithHeader title="編集" id="screen-fav-store">
            <div className="flex flex-col flex-1 overflow-auto">
                <div className="overflow-auto">
                    <div className="divide-y">
                        {
                            Object.keys(stores).map((id: any) => {
                                let retailer: any = retailers[id][0];
                                return (
                                    <div key={retailer.id}>
                                        <div
                                            className="flex items-center px-6 py-4">
                                            <img className=""
                                                style={{
                                                    height: "2.875rem",
                                                    width: "3.875rem",
                                                }}
                                                src={imageURL[retailer.id]}
                                                alt=""></img>

                                            <div className="text-sm font-medium ml-4 text-gray-900">{retailer.name}</div>
                                        </div>
                                        {stores[id].map((fstore: any) => {
                                            return (
                                                <IonItemSliding id={fstore.id + "-item"} key={fstore.id}>
                                                    <IonItem
                                                        lines="none"
                                                        onClick={() => {
                                                            props.setSelectedStore(fstore);
                                                            props.setRetailer(fstore.retailer)
                                                            props.setEvent("STORE_CHANGED")
                                                            history.goBack()
                                                            // eventBus.dispatch("GTM_EVENT", {
                                                            //     eventName: "STORE_CHANGED",
                                                            //     data: props.state,
                                                            // });
                                                        }}
                                                    >
                                                        <div className="ml-4 text-875">{fstore.name}</div>
                                                        <IonNote slot="end">
                                                            {props.selectedStore && props.selectedStore.id === fstore.id && (
                                                                <StarIcon className="w-5 h-5" color="orange" />
                                                            )}
                                                        </IonNote>
                                                    </IonItem>
                                                    <IonItemOptions side="end">
                                                        <IonItemOption
                                                            color="danger"
                                                            onClick={(ev) => {
                                                                let starStore = JSON.parse(localStorage.getItem('fav-store'));
                                                                if (starStore && starStore.id == fstore.id) {
                                                                    localStorage.removeItem('fav-store');
                                                                    localStorage.setItem('fav-store-retailer', fstore.retailer_id);
                                                                }
                                                                MembersService.removeStoreFromFavs(fstore.id).then(() => {
                                                                    // will also work when store is removed
                                                                    eventBus.dispatch("storeAddedToFavorites", {});
                                                                });
                                                                // ref.current.close();
                                                            }}
                                                        >
                                                            {/* <IonIcon size="small" slot="icon-only" icon={trash} /> */}
                                                            削除
                                                        </IonItemOption>
                                                    </IonItemOptions>
                                                </IonItemSliding>
                                            )
                                        })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <IonButton
                    onClick={() => {
                        props.setStoreSelection(true);
                        history.push("/2");
                    }}
                    fill="clear"
                    expand="block"
                    size="default"
                    className="border border-gray rounded-xl mx-4 text-875 my-4"
                    style={{ color: "#1f1f1f" }}
                >
                    店舗を追加する
                </IonButton>
            </div>
        </ScrollpageWithHeader>
    );
};
export const Screen38 = connect(
    mapStateToProps,
    mapDispatchToProps
)(Screen38Component);
