import { IonAlert } from "@ionic/react";
import {useHistory} from "react-router";

export const PopupEnquirySent = () => {
  const history = useHistory();
  return (
    <IonAlert
      mode="ios"
      isOpen={true}
      onDidDismiss={() => {}}
      cssClass="confirm-modal"
      header={"お問い合わせありがとうございました"}
      message={
        '<p class="text-gray-400">回答まで今しばらくお待ちください。</p>'
      }
      buttons={[
        {
          text: "はい",
          cssClass: "py-6",
          handler: () => {
            // console.log("Confirm Okay");
            history.replace('/tabs/account')
          },
        },
      ]}
    />
  );
};
