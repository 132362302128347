import {ScrollpageWithHeader} from "../../scrollpage-with-header/scrollpage-with-header";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import DataService from "../../../services/data.service";
import {RetailerPoints} from "../../../components/member-holder-point/retailer-points";

function jsonEscape(str: string) {
  return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
}
function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let todayDate = new Date();

let app_retailer_id = localStorage.getItem("app_retailer_id");
let url = '/0?campaign=true'
let url_campaign_ended = '/campaign-ended?campaign=true'

let url_campaign_apr_ended = '/campaign-apr-ended?campaign=true'
let url_campaign_jun_ended = '/campaign-jun-ended?campaign=true'
let url_campaign_july_ended = '/campaign-july-ended?campaign=true'

if(app_retailer_id){
  url = url + `&retailerId=${app_retailer_id}`;
  url_campaign_ended = url_campaign_ended + `&retailerId=${app_retailer_id}`;
}

const faqs = [

  {
    valid: true,
    question: "Q. クーポンプラスとは何ですか？",
    answer: `「クーポンプラス」は、いつものお買いものでポイントがもらえる、お得なスマートクーポンサービスです。運営会社は、カタリナマーケティングジャパン株式会社です。
     <a class="underline" href="https://www.catalinamarketing.co.jp/about/">https://www.catalinamarketing.co.jp/about/ </a>`
  },
  {
    valid: true,
    question: "Q. 本サービスへの登録は無料ですか？",
    answer: "はい、無料です。",
  },
  {
    valid: true,
    question: "Q. 本サービスへどうやって訪問できますか？",
    answer: `LINEアプリ上より訪問が可能です。<br/>
    1. LINE > 右下の「ウォレット」をタップ > 「クーポン」をタップ(LINEクーポン公式アカウントやホームタブからもアクセスできます)<br/>
    2. LINEクーポンのトップページから「クーポンプラス」のバナーをタップ`,
  },
  {
    valid: true,
    question: "Q. クーポンはどうやって利用できますか？",
    answer: `クーポンボタンを選択して、ポイントカード/電子マネーを選択。<br/>
    1. 利用したいお店とポイントカード番号を登録<br/>
    2. 利用したいクーポンを選択して、登録したポイントカード/電子マネーを使ってお買物/お支払い<br/>
    　※電子マネーをご登録のお客様はお支払いは電子マネーをご利用ください。<br/>
    3. 後日ポイントが還元されます。`,
  },
  {
    valid: true,
    question: "Q. クーポンはどこのお店で使えますか？",
    answer: `クーポンのご利用は「お店を選択してください」に掲載のあるスーパーとドラッグストアにてご利用いただけます。`,
  },
  {
    valid: true,
    question: "Q. クーポンの対象商品がお店にない場合はどうしたら良いですか？",
    answer: `クーポンは、対象商品が実際に店舗にて取り扱いがある場合のみ、利用いただけます。お手数ですが、他店舗にてご利用ください。`
  },
  {
    valid: true,
    question: "Q. 他クーポンとの併用制限はありますか？",
    answer: `1つのクーポンにつきポイント付与は原則一回となります。いずれのポイント分になるかはお選びいただけませんのでご了承ください。
    他キャンペーン（同時期に実施の同一商品を対象）と併用した場合、ポイント付与対象外となる場合があります。`,
  },
  {
    valid: true,
    question: "Q. 1つのクーポンを複数回利用することは可能ですか？",
    answer: `1つのクーポンにつき、定められた適用条件で一度のみ利用することが可能です。
    例）「1個で50ポイント」
    3個を同時にお買い上げいただいても、付与されるポイントは50ポイントとなります。また適用条件には「いずれか」と「よりどり」があるため、ご利用の際はご注意ください。
    例1）「いずれか1本で30ポイント」
    対象商品の、複数ある製品ラインアップの中からお好きなものを1本ご購入の際に、30ポイントが付与されます。
    例2）「よりどり3本で100ポイント」
    対象商品の、複数ある製品ラインアップの中からお好きなものを1回のお会計で3本同時にご購入した際に、100ポイントが付与されます。`,
  },
  {
    valid: true,
    question: "Q. 店舗の追加はどのようにすれば良いですか？",
    answer: `以下手順に従って、店舗の追加が可能です。
    1. 画面の最下部にあるメニューで「トップ」をタップ。
    2. 画面の最上部に表示されるお店の「選択（初めての場合）」もしくは「編集（追加の場合）」をタップ。
    3. 追加の場合は「店舗を追加する」をタップ。初めての場合は次のステップへ。
    4. ポイントカード/電子マネーのアイコンをタップし、ご利用店舗を選択。`,
  },
  {
    valid: true,
    question: "Q. クーポンを使ってからポイントが付くまでにどれくらいかかりますか？",
    answer: `ポイント付与までの期間は、約1週間。
    ポイント付与状況確認方法は、「マイアカウント」のポイント履歴ページで確認。
    マイアカウントのポイント履歴ページの表示説明。
    　「ポイント処理中」:購入が確認されたクーポン履歴を表示。
    　「ポイント付与完了」:ポイントが付与されたクーポンの履歴を表示。
    ※利用条件が満たされていない場合、ポイントは付与されませんのでご了承ください。`,
  },
  {
    valid: true,
    question: "Q. 登録情報の変更はどうすれば良いですか？",
    answer: `以下手順に従って、登録情報の変更が可能です。
    1. 画面の最下部にあるメニューで「アカウント」をタップ。
    2. 画面の最上部に表示される「設定」をタップ。
    3. 画面の最上部に表示される「アカウント編集」をタップ。`
  },
  {
    valid: true,
    question: "Q. メールアドレスの登録ができない場合はどうすれば良いですか？",
    answer: `登録できるメールアドレスは、IETF（Internet Engineering Task Force）に準拠したメールアドレスです。
    以下の特殊文字が入ったアドレスは登録できません。他のメールアドレスでご登録ください。
    ! # $ % & ‘ * + / = ? ^ _ \` . { | } ~
    2つ以上のピリオド（.）の連続、＠マーク前のピリオド（.）、ピリオド（.）で始まるアドレス`,
  },
  {
    valid: true,
    question: "Q. 受信拒否を解除するメールアドレスを教えてください。",
    answer: `以下のメールアドレスを受信拒否設定から解除してください。<br/>
    contact@catalinacoupons.jp <br/>
    noreply@catalinacoupons.jp`,
  },
  {
    valid: true,
    question: "Q. 機種変更した後に本サービスへどうやって訪問できますか？",
    answer: `機種変更前にご利用のLINEアカウントより訪問してください。`,
  },
  {
    valid: true,
    question: "Q. 退会するにはどうすれば良いですか？",
    answer: `マイアカウントの中の「ユーザー情報の削除（アカウントの停止）」を押してください。アカウント削除のメールがご登録メールアドレスに送付されますので、メール本文内のリンクをクリックしてください。リンクをクリックするとアカウントが削除され、退会となります。`,
  },
  {
    valid: true,
    question: "Q. クーポンプラスについて問い合わせ先はどこですか？",
    answer: `「クーポンプラス」に関するお問い合わせは、以下で承っております。
    カタリナお客様サポートセンター
    TEL: 03-3570-6974
    受付時間: 9:00-21:00（年中無休）`,
  },
];

export const AccountFaq = () => {
  let queryParams = useQueryParams();
  let debug  = queryParams.get('debug');
  let [activeCampaigns, setActiveCampaigns] = useState<any>();
  const start_june = (debug === 'true')? new Date(todayDate) : new Date(process.env.REACT_APP_BANNER_START_JUNE);
  const end_june = new Date(process.env.REACT_APP_BANNER_END_JUNE);
  const start_june_faq = new Date(process.env.REACT_APP_FAQ_START_JUNE);
  const end_june_faq = new Date(process.env.REACT_APP_FAQ_END_JUNE);
  // console.log('start_june',start_june)
  // console.log('end_june',end_june)
  // console.log('start_june_faq',start_june_faq)
  // console.log('end_june_faq',end_june_faq)
  //
  const start_july = (debug === 'true')? new Date(todayDate) : new Date(process.env.REACT_APP_BANNER_START_JULY);
  const end_july = new Date(process.env.REACT_APP_BANNER_END_JULY);
  const start_july_faq = new Date(process.env.REACT_APP_FAQ_START_JULY);
  const end_july_faq = new Date(process.env.REACT_APP_FAQ_END_JULY);
  // console.log('start_july',start_june)
  // console.log('end_july',end_june)
  // console.log('start_july_faq',start_june_faq)
  // console.log('end_july_faq',end_june_faq)


  let createDateString = (campaign:any) =>{
    let start_Date = new Date(campaign.started_at);
    let end_Date = new Date(campaign.ended_at);

    let startYear = start_Date.getFullYear();
    let startMonth = ('0' + (start_Date.getMonth() + 1)).slice(-2);
    let strtDate = ('0' + (start_Date.getDate())).slice(-2);
    let startHour = start_Date.getHours();
    let startMinute = start_Date.getMinutes();

    let endYear = end_Date.getFullYear();
    let endMonth = ('0' + (end_Date.getMonth() + 1)).slice(-2);
    let endDate = ('0' + (end_Date.getDate())).slice(-2);
    let endHour = end_Date.getHours();
    let endMinute = end_Date.getMinutes();

    //let period = ・開催期間：2024/7/16 – 2024/8/23
    return `・開催期間：${startYear}/${startMonth}/${strtDate} – ${endYear}/${endMonth}/${endDate}`;
  }
  useEffect(() => {
    DataService.getCampaigns().then(response=>{
      console.log(response.data);
      let campaigns = response.data;
      let activeCampaigns = [];
      let today = new Date();
      for (let index in campaigns) {
        let campaign = campaigns[index];
        let campaignStartDate = new Date(campaign.started_at);
        let campaignFAQEndDate =  new Date(campaign.faq_ended_at);
        if(today>=campaignStartDate && today<=campaignFAQEndDate){
          console.log('Active', campaign);
          campaign.dateString = createDateString(campaign);

          activeCampaigns.push(campaign);
        }

      }
      console.log(activeCampaigns);
      setActiveCampaigns(activeCampaigns);

    }).catch(error=>{
      console.log(error)
    });
  }, []);

  const history = useHistory();
  // console.log('todayDate',todayDate)
  // console.log('(todayDate > start_feb && todayDate < end_feb) ',(todayDate > start_feb && todayDate < end_feb))
  // console.log('(todayDate > start_jan && todayDate < end_jan)',(todayDate > start_jan && todayDate < end_jan))
  // console.log('(todayDate > start_feb && todayDate < end_feb) || (todayDate > start_jan && todayDate < end_jan)',(todayDate > start_feb && todayDate < end_feb) || (todayDate > start_jan && todayDate < end_jan))
  return (
      <ScrollpageWithHeader title="よくある質問" id="account-faq">
        <div className="p-4">
          {activeCampaigns && activeCampaigns.length>0 && <div className="mb-10">
            <div className="text-sm font-semibold">Q. キャンペーンページにはどのようにして訪問できますか？</div>



                <div style={{color: "#777777"}} className="text-xs pt-3 text-gray-400">
                  以下のリンクよりご訪問いただけます。<br/>
                  <br/>
                </div>

            {activeCampaigns.map((campaign:any) => (
                <div style={{color: "#777777"}} className="text-xs pt-3 text-gray-400">
                  {campaign.dateString}
                  <br/>
                  <a onClick={() => {
                    history.push(`/campaign?campaignId=${campaign.id}&dynamic=true`)
                  }} className="underline cursor-pointer" dangerouslySetInnerHTML={{
                    __html: campaign.faq_link_text,
                  }}>

                  </a>
                </div>
            ))}
            {todayDate >= start_july && todayDate <= end_july_faq &&
                <div style={{color: "#777777"}} className="text-xs pt-3 text-gray-400" >
                  ・開催期間：2024/7/16 – 2024/8/23
                  <br/>
                  <a onClick={()=>{
                    history.push(url_campaign_july_ended)
                  }} className="underline cursor-pointer">
                    クーポンプラスにはじめてカード登録の方限定<br/>
                    電子マネーWAONを登録で、WAONポイント50万ポイント山分けキャンペーン（先着50万名）
                  </a>
                </div>
            }
            {/*{todayDate >= start_july && todayDate <= end_july &&*/}
            {/*    <div style={{color: "#777777"}} className="text-xs pt-3 text-gray-400">*/}
            {/*      ・開催期間：2024/7/16 – 2024/8/23*/}
            {/*      <br/>*/}
            {/*      <a onClick={() => {*/}
            {/*        history.push(url)*/}
            {/*      }} className="underline cursor-pointer">*/}
            {/*        クーポンプラスにはじめてカード登録の方限定<br/>*/}
            {/*        電子マネーWAONを登録で、WAONポイント50万ポイント山分けキャンペーン（先着50万名）*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*}*/}

            {/*{todayDate >= end_july && todayDate <= end_july_faq &&*/}
            {/*    <div style={{color: "#777777"}} className="text-xs pt-3 text-gray-400" >*/}
            {/*      ・開催期間：2024/7/16 – 2024/8/23<br/>*/}
            {/*      <a onClick={()=>{*/}
            {/*        history.push(url_campaign_july_ended)*/}
            {/*      }} className="underline cursor-pointer">*/}
            {/*        クーポンプラスにはじめてカード登録の方限定<br/>*/}
            {/*        電子マネーWAONを登録で、WAONポイント50万ポイント山分けキャンペーン（先着50万名）*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{todayDate >= end_june && todayDate <= end_june_faq &&*/}
            {/*    <div style={{color: "#777777"}} className="text-xs pt-3 text-gray-400" >*/}
            {/*      ・開催期間：2024/6/3 – 2024/6/10<br/>*/}
            {/*      <a onClick={()=>{*/}
            {/*        history.push(url_campaign_jun_ended)*/}
            {/*      }} className="underline cursor-pointer">*/}
            {/*        クーポンプラスにはじめてカード登録で必ずもらえるLINEポイント50ポイントプレゼントキャンペーンはこちら*/}
            {/*      </a>*/}
            {/*    </div>*/}
            {/*}*/}
          </div>}

          {faqs.map((faq, index) => (
              <div className="mb-10" key={index}>
                <div className="text-sm font-semibold">{faq.question}</div>
                <div style={{color: "#777777"}} className="text-xs pt-3 text-gray-400" dangerouslySetInnerHTML={{
                  __html: faq.answer,
                }} />
              </div>
          ))}
        </div>
      </ScrollpageWithHeader>
  );
};
