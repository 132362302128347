import { IonSpinner, useIonViewDidEnter } from "@ionic/react";
import { useEffect } from "react";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import { useHistory } from "react-router";
import { connect } from "react-redux";

import {
  fetchAreas,
  setArea,
} from "../../../redux/retailers/retailers.actions";

const mapStateToProps = (state: any) => {
  return {
    areaList: state.retailers.areas,
    area: state.retailers.area,
    loading: state.retailers.loading,
    retailer: state.retailers.selected,
    prefecture: state.retailers.prefecture,
    city: state.retailers.city,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchAreas: (retailer: any, prefecture: any, city: any) =>
      dispatch(fetchAreas(retailer, prefecture, city)),
    setArea: (data: any) => dispatch(setArea(data)),
  };
};
interface Props {
  fetchAreas: Function;
  setArea: Function;
  loading: boolean;
  retailer: any;
  prefecture: any;
  city: any;
  areaList: any;
  area: any;
}

export const Screen6Component = (props: Props) => {
  const history = useHistory();
  useIonViewDidEnter(() => {
    // init();
  });
  const init = () => {
    if (props.retailer && props.prefecture && props.city) {
      props.fetchAreas(props.retailer, props.prefecture, props.city);
    } else {
      history.replace("/2");
    }
  };
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if(props.city){
      init();
    }
  }, [props.city]);
  return (
    <ScrollpageWithHeader title="店舗選択" id="6">
      {props.loading && (
        <div className="mt-6 flex justify-center">
          <IonSpinner />
        </div>
      )}
      <div className="details flex flex-col justify-start overflow-auto px-5 pb-6 mt-6">
        <div className="text-lg text-left">{props.city}</div>
        {props.areaList &&
          props.areaList.length != 0 &&
          props.areaList.map((item: any) => (
            <div key={item} className="mt-6">
              <div
                onClick={() => {
                  props.setArea(item);
                  history.push("/7");
                }}
                className="text pl-6 pt-4"
              >
                {item}
              </div>
            </div>
          ))}
      </div>
    </ScrollpageWithHeader>
  );
};
export const Screen6 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen6Component);
