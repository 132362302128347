import { CheckCircleIcon, TrashIcon } from "@heroicons/react/solid";

export const CartCardBarcode = () => {
  return (
    <li className="flex flex-col py-6 px-4 shadow-lg rounded-md mt-4">
      <div className="top flex">
        <div className="flex-shrink-0">
          <img
            src="https://consolidatedlabel.com/app/uploads/2021/06/wine-bottle-labels-banner.png"
            alt={"Item"}
            className="w-24 rounded-md"
          />
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <div className="text-sm">チョコラBB ローヤル2</div>
          <div className="text-sm pt-1 font-semibold">有効期限：明日まで</div>
        </div>
        <div className="ml-4 flex-shrink-0 flow-root">
          <button
            type="button"
            className="-m-2.5 p-2.5 flex items-center justify-center text-gray-500"
          >
            <TrashIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      <img className="h-8 mt-6 opacity-60" src="https://pngimg.com/uploads/barcode/barcode_PNG7.png" />
    </li>
  );
};
