import { IonPage } from "@ionic/react";
import step1 from "./img_step1.png";
import step2 from "./img_step2.png";
import step3 from "./img_step3.png";
import step4 from "./img_step4.png";
import { useHistory } from "react-router-dom";

const steps = [
  {
    heading: "STEP 1",
    subtitle: "ポイントカードを登録",
    image: step1,
  },
  {
    heading: "STEP 2",
    subtitle: "クーポンを選択",
    image: step2,
  },
  {
    heading: "STEP 3",
    subtitle: "ポイントカードで対象商品を購入",
    image: step3,
  },
  {
    heading: "STEP 4",
    subtitle: "後日ポイントが還元される",
    image: step4,
  },
];

export const Screen23 = () => {
  const history = useHistory();
  return (
    <IonPage>
      <div className="flex flex-col flex-1 overflow-auto bg-white">
        <div className="p-6 flex-1" style={{ background: "#F5F5F5" }}>
          <div className="text-lg text-center pt-6 font-bold">
          お買物のカードで <br /> ポイントを貯める
          </div>
          <div className="coupons pt-4">
            {steps.map((step) => (
              <div
                key={step.heading}
                className="flex flex-col items-center justify-between items-center mt-12"
              >
                <div className="w-16 m-auto text font-semibold text-catalina-base" style={{
                  textAlign: 'center'
                }}>
                  {step.heading}
                </div>
                <div className="text-sm text-gray-500 pt-2">
                  {step.subtitle}
                </div>
                <div>
                  <img className="h-16 mt-2" src={step.image} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </IonPage>
  );
};
