import {
  IonAlert,
  IonButton,
  useIonToast,
  useIonViewDidEnter,

} from "@ionic/react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setAccountMode, setHolderCards } from "../../../redux/holder-cards/holder-cards.actions";
import { fetchCategories, fetchOffers } from "../../../redux/offer-list/offer-list.actions";
import { setSelectedTab } from "../../../redux/tabs/tabs.actions";
import waon from "../../../line/screens/screen2/1.png";
import izumi from "../../../line/screens/screen2/2.png";
import yb from "../../../line/screens/screen2/6.png";
import iy from "../../../line/screens/screen2/10.png";
import MembersService from "../../../line/services/members.service";
import { ScrollpageWithHeader } from "../../../line/screens/scrollpage-with-header/scrollpage-with-header";
import payimage from "./pay.png";
import { useAtom } from "jotai";
import { Atom_Registration_Holder, Atom_Registration_Selected_Retailer } from "../../../line/state/atoms/registration.email.atom";

const mapStateToProps = (state: any) => {
  return {
    state: state,
    cards: state.cards.holders,
    accountScreenMode: state.cards.accountScreenMode,
    selectedStore: state.stores.selectedStore,
    retailer: state.retailers.selectedForAccount,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAccountMode: (data: any) => dispatch(setAccountMode(data)),
    setHolderCards: (data: any) => dispatch(setHolderCards(data)),
    fetchOffers: () => dispatch(fetchOffers()),
    fetchCategories: () => dispatch(fetchCategories()),
    setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
  };
};

interface Props {
  cards: any[];
  state: any;
  setHolderCards: Function;
  retailer: any;
  accountScreenMode: any;
  fetchOffers: Function;
  fetchCategories: Function;
  setSelectedTab: Function;
  setAccountMode: Function;
  selectedStore?: any;
}

export const InputCardComponent = (props: Props) => {
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;

  let imageURL: any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  const history = useHistory();
  const [present, dismiss] = useIonToast();

  const [holderNumberForRegistration, setHolderNumberForRegistration] = useAtom(Atom_Registration_Holder);
  const [selectedRetailerForRegistration] = useAtom(Atom_Registration_Selected_Retailer);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showDelete, setShowDelete] = useState(false);
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  let selectedRetailer = props.accountScreenMode ? props.retailer : props.selectedStore ? props.selectedStore.retailer : props.retailer;
  let selectedStore = props.selectedStore ? props.selectedStore : { retailer_id: "-1" };
  let app_retailer_id = localStorage.getItem("app_retailer_id");
  if (app_retailer_id) {
    if (!selectedRetailer) {
      selectedRetailer = {
        id: Number(app_retailer_id)
      }
    }
    if (!props.selectedStore) {
      selectedStore = {
        retailer_id: Number(app_retailer_id)
      }
    }
  }
  if (!selectedRetailer) {
    selectedRetailer = {
      id: -1
    }
  }

  let { retailer } = props;
  if (!retailer) {
    retailer = selectedStore.retailer;
  }
  if (!retailer) {
    retailer = {
      id: -1
    };
  }
  console.log(selectedRetailerForRegistration)
  let holder = props.accountScreenMode ? props.cards.filter(
    (c) => {
      console.log(c);
      return c.retailer_id === parseInt(retailer.id)
    }
  )[0] : props.cards.filter(
    (c) => {
      console.log(c)
      return c.retailer_id === parseInt(selectedStore.retailer_id)
    }
  )[0]

  console.log("$$$$$$$$$$$$$", holder)
  if (selectedStore.retailer_id == -1) {
    holder = props.cards[0]
  }
  // let holder = props.cards.filter(
  //     (c) => c.retailer_id === parseInt(props.selectedStore.retailer_id)
  // )[0]

  if (holder && retailer && retailer.id != -1 && holder.retailer_id !== retailer.id) {
    holder = null
  }

  const formatCreditCardNumber = (value: any, retailerId: any) => {
    if (!value) {
      return value;
    }
    function clearNumber(value = "") {
      return value.replace(/\D+/g, "");
    }
    const clearValue = clearNumber(value);
    let nextValue;
    if (retailerId !== Number(izumiId)) {
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
    }
    else if (retailerId === Number(izumiId)) {
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        12
      )} ${clearValue.slice(12, 13)}`;
    } else {
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
    }
    return nextValue.trim();
  };
  const [cardNumber, setCardNumber] = useState(null);
  const triggerGTM = async (data: any) => {
    const eventProps = await data.data.state;
    let screenType = "";
    screenType = "Card View";
    window.dataLayer = window.dataLayer || [];
    // console.log({
    //   event: "virtualPageview",

    //   pageUrl: window.location.pathname, //fill dynamic uisng api value when page/state changes

    //   pageTitle: "CARD_REGISTERED", // fill dynamic uisng api value when page/state changes
    //   dm_fsp_id: data.data.card?data.data.card.replaceAll(" ", "") : "",
    //   dm_screen_type: screenType, //fill dynamic:'sign up' 'dm_screen_type': 'list_view' for list view screen, 'product_view' for products view screen

    //   userId: JSON.parse(localStorage.getItem("user-data")!).id, //fill dynamic uisng api value when page/state changes

    //   dm_retailer_id: eventProps.stores.selectedStore
    //       ? eventProps.stores.selectedStore.retailer.id
    //       : 0
    // })
    window.dataLayer.push({
      event: "virtualPageview",

      pageUrl: window.location.pathname, //fill dynamic uisng api value when page/state changes

      pageTitle: "CARD_REGISTERED", // fill dynamic uisng api value when page/state changes
      dm_fsp_id: data.data.card ? data.data.card.replaceAll(" ", "") : "",
      dm_screen_type: screenType, //fill dynamic:'sign up' 'dm_screen_type': 'list_view' for list view screen, 'product_view' for products view screen

      userId: JSON.parse(localStorage.getItem("user-data")!).id, //fill dynamic uisng api value when page/state changes

      dm_retailer_id: eventProps.stores.selectedStore
        ? eventProps.stores.selectedStore.retailer.id
        : 0, //fill dynamic uisng api value when page/state changes
      //   6900175564369054
    });

  }
  const registerCard = () => {
    let cardNumberValueText = cardNumber ? cardNumber.replaceAll(" ", "") : "";
    if (!cardNumberValueText && cardNumberValueText === "") {
      setErrorMessage("カード番号の入力が必要です。");
      setOpenErrorModel(true);
    } else {
      MembersService.registerCard(cardNumberValueText, retailer.id)
        .then(refetchHoldersAndNavigateToTabs)
        .then(() => {
          props.setAccountMode(false);
          triggerGTM({
            eventName: "",
            data: {
              card: cardNumber,
              state: props.state
            },
          }).then(() => {
            setCardNumber("");
            present("カードの登録に成功しました。", 2000);
          })

        })
        .catch((error) => {
          if (error.response && error.response.data) {
            setErrorMessage(error.response.data.error);
            setOpenErrorModel(true);

          }
        });
    }
  };

  const refetchHoldersAndNavigateToTabs = () => {
    props.setAccountMode(false);
    getHolder();
    setCardNumber("");
    props.fetchOffers();
    props.fetchCategories();
    props.setSelectedTab('home');
    setTimeout(() => {
      setCardNumber("");
      history.push("/tabs/home");
    }, 1000);
  };

  useIonViewDidEnter(() => {
    getHolder();
    if (holder) {
      setCardNumber(holder.ref);
    } else {
      setCardNumber("");
    }
  }, [holder]);
  useEffect(() => {
    if (holder) {
      setCardNumber(holder.ref);
    } else {
      setCardNumber("");
    }
  }, [holder]);

  const deleteCard = () => {
    // console.log("Delete: ", holder);
    MembersService.deleteCard(holder.id)
      .then(refetchHoldersAndNavigateToTabs)
      .then(() => {
        props.setHolderCards([]);
        setCardNumber("");
        cardNumberValue("");
        present("カードを正常に削除しました", 2000);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data.error);
          setOpenErrorModel(true);
        }
      });
  };

  const updateCard = () => {
    // console.log("update");
    let cardNumberValueText = cardNumber ? cardNumber.replaceAll(" ", "") : "";
    if (!cardNumberValueText && cardNumberValueText === "") {
      setErrorMessage("カード番号の入力が必要です。");
      setOpenErrorModel(true);
    } else {
      MembersService.updateCard(
        holder.id,
        cardNumberValueText,
        retailer.id
      )
        .then(refetchHoldersAndNavigateToTabs)
        .then(() => {
          present("カード番号が正常に更新されました。", 2000);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            setErrorMessage(error.response.data.error);
            setOpenErrorModel(true);
          }
        });
    }
  };

  const getHolder = () => {
    // MembersService.getHolders()
    //   .then((responseJson) => {
    //     props.setHolderCards(responseJson.data);
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   });
  };

  useEffect(() => {
    getHolder();
  }, []);

  const handleInputFocus = (target: any) => {
    // this.setState({
    //   focused: target.name
    // });
  };

  const handleInputChange = (target: any) => {
    target.value = formatCreditCardNumber(target.value, retailer.id);
    // this.setState({ [target.name]: target.value });
    setHolderNumberForRegistration(target.value);
    setOpenErrorModel(false);
    setErrorMessage("");
  };

  let cardNumberValue: any = "";
  if (cardNumber) {
    cardNumberValue = formatCreditCardNumber(cardNumber, retailer.id)
  }
  console.log(cardNumberValue)
  return (
    <ScrollpageWithHeader title="カードを登録" id="page-holders">
      <IonAlert
        isOpen={showDelete}
        onDidDismiss={() => setShowDelete(false)}
        cssClass="confirm-modal"
        header={"確認"}
        message={
          '<p class="text-gray-400">登録済みのカードを削除しますか？</p>'
        }
        buttons={[
          {
            text: "いいえ",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              setShowDelete(false);
              getHolder();
            },
          },
          {
            text: "はい",
            handler: () => {
              deleteCard();
              // console.log("Confirm Okay: ", holder);
            },
          },
        ]}
      />
      <div className="details flex flex-col justify-start overflow-auto pb-6 flex-1 px-5">

        {selectedRetailerForRegistration.name && selectedRetailerForRegistration.image_url && (
          <div className="flex items-center pt-4">
            <div className="flex items-center justify-center rounded-full">
              <img className="items-center justify-center rounded-md flex overflow-hidden object-contain"
                src={selectedRetailerForRegistration.image_url}
                alt="Card"
                style={{
                  height: "2.875rem",
                  width: "3.875rem",
                }}
              />
            </div>
            <div className="flex-1 pl-4 text-sm text-gray-600" style={{ color: "#1F1F1F", fontWeight: 600 }}>
              {selectedRetailerForRegistration.name}
            </div>
          </div>
        )}

        <div className="text mt-5 mb-2 font-semibold">カード番号を入力</div>

        <input
          type="tel"
          maxLength={19}
          name="number"
          value={holderNumberForRegistration}
          className="mt-5 outline-none text-sm border-b border-gray-300 pb-2"
          placeholder="カード番号を入力してください"
          pattern="[\d| ]{16,22}"
          required
          onChange={(event) => handleInputChange(event.target)}
          onFocus={(event) => handleInputFocus(event.target)}
        />

        {openErrorModel && (
          <div className="text-red-600 text-xs mt-2 mb-10">{errorMessage}</div>
        )}

        <div className="mt-4">
          <Link to="/sign-up/registration">
            <IonButton
              expand="block"
              color="primary"
              disabled={!holderNumberForRegistration}
              className="w-full text-875"
            >
              保存
            </IonButton>
          </Link>
        </div>
        
        {selectedRetailerForRegistration && <img src={selectedRetailerForRegistration.picture_card} className="w-full mt-12" />}
      </div>

      {/*<img src={payimage} />*/}
    </ScrollpageWithHeader>
  );
};

export const InputCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputCardComponent);
