import {
  IonButton,
  IonItem,
  IonLabel,
  IonSpinner,
  useIonToast,
  useIonViewDidEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { setSelectedTab } from "../../../redux/tabs/tabs.actions";
import {
  addToFavStores,
  fetchFavoriteStores,
  fetchStoreToSelect,
  resetError,
  setStore,
} from "../../../redux/retailers/retailers.actions";
import {
  actionGetStoresAtLevelCity,
  actionGetStoresAtLevelPrefecture,
  setSelectedStore
} from "../../../redux/stores/stores.actions";
import MembersService from "../../../line/services/members.service";
import { ScrollpageWithHeader } from "../../../line/screens/scrollpage-with-header/scrollpage-with-header";
import { StepHeader2 } from "../step-headers/step-headers";

const mapStateToProps = (state: any) => {
  return {
    stores: state.retailers.stores,
    store: state.retailers.store,
    retailer: state.retailers.selected,
    prefecture: state.retailers.prefecture,
    city: state.retailers.city,
    area: state.retailers.area,
    loading: state.retailers.loading,
    error: state.retailers.error,
    errorMessage: state.retailers.errorMessage,
    cards: state.cards.holders,
    favoriteStores: state.stores.favoriteStores,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedStore: (data: any) => dispatch(setSelectedStore(data)),
    fetchStoreToSelect: (
      retailer: any,
      prefecture: any,
      city: any,
      area: any
    ) => dispatch(fetchStoreToSelect(retailer, prefecture, city)),
    actionGetStoresAtLevelCity: (retailer: any,
      prefecture: any,
      city: any,) => dispatch(actionGetStoresAtLevelCity(retailer, prefecture, city)),
    setStore: (store: any) => dispatch(setStore(store)),
    fetchFavoriteStores: (store: any) => dispatch(fetchFavoriteStores()),
    addToFavStores: (storeId: any) => dispatch(addToFavStores(storeId)),
    setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
    resetError: () => dispatch(resetError()),
  };
};
interface Props {
  stores: any;
  fetchStoreToSelect: Function;
  actionGetStoresAtLevelCity: Function;
  setStore: Function;
  addToFavStores: Function;
  resetError: Function;
  fetchFavoriteStores: Function;
  setSelectedStore: Function;
  retailer: any;
  loading: any;
  error: boolean;
  errorMessage: any;
  prefecture: any;
  city: any;
  area: any;
  store: any;
  cards: any[];
  setSelectedTab: Function;
  favoriteStores: any[];
}

export const CityStoresComponent = (props: Props) => {
  const [present, dismiss] = useIonToast();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [cardForRetailer, setCardForRetailer] = useState<string>("");
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  const history = useHistory();

  let { city, prefecture, retailerid } = useParams() as any;

  const addToFavStores = (selectedStoreId: any, selectedStore: any) => {
    MembersService.addStoreToFavs(selectedStoreId, selectedStore)
      .then(() => {
        present("店舗が登録されました。", 2000);
        history.push("/tabs/home");
      })
  };

  const checkForHolderCardsAndNavigate = (selectedStore: any) => {
    localStorage.setItem('fav-store', JSON.stringify(selectedStore))
    let card = props.cards.filter((card) => {
      return card.retailer_id === props.retailer.id
    })[0]
    setCardForRetailer(card);
    props.setSelectedStore(selectedStore);
    if (!card) {
      history.push("/9");
    } else {
      setTimeout(() => {
        props.setSelectedTab('home');
        history.push("/tabs/home");
      }, 1000)
    }
  };

  const init = () => {
    // setCardForRetailer(props.cards.filter((card) => {
    //   return card.retailer_id === props.retailer.id
    // })[0]);
    props.fetchStoreToSelect({ id: retailerid }, prefecture, city);
    // props.actionGetStoresAtLevelCity(props.retailer, props.prefecture, props.city);
  };

  useEffect(() => {
    init();
  }, [city]);

  return (
    <ScrollpageWithHeader title="店舗選択" id="city-stores">

      {props.loading && (
        <div className="mt-6 flex justify-center">
          <IonSpinner />
        </div>
      )}

      <div className="details flex flex-col justify-start pb-6">
        
        {openErrorModel && (
          <div className="text-red-600 text-xs -mt-3 mb-10">{errorMessage}</div>
        )}

        {!props.loading && props.stores && <div>

          <StepHeader2 />

          {props.stores.map((item: any) => (
            <div className="block mt-3">
              <IonItem
                onClick={(e) => {
                  addToFavStores(item.id, item);
                }}
                detail={true}
                lines="none"
                className="mt-2"
                key={item.id}
              >
                <IonLabel>{item.name}</IonLabel>
              </IonItem>
            </div>
          ))}
        </div>}
        {!props.loading && props.stores &&
          props.stores.length === 0 &&
          <div className="my-auto flex-shrink-0 py-16 sm:py-32">
            <p className="mt-2 text-base text-gray-600" >データ取得に失敗しました。他の市町村を試すか、後ほど再度お試しください</p>
            <div className="mt-6">
              <a className="text-base font-medium text-indigo-600 hover:text-indigo-500" style={{
                color: 'rgb(6, 199, 85)'
              }} onClick={() => {
                history.push("/tabs/home");
              }
              }>
                「ホーム」に戻る
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        }
      </div>
    </ScrollpageWithHeader>
  );
};
export const CityStores = connect(
  mapStateToProps,
  mapDispatchToProps
)(CityStoresComponent);
