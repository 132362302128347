import {IonItem, IonSpinner, useIonViewDidEnter} from "@ionic/react";
import { useEffect } from "react";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import {
  fetchCities,
  setCity,
} from "../../../redux/retailers/retailers.actions";
import { actionGetStoresAtLevelPrefecture } from "../../../redux/stores/stores.actions";
import { StepHeader2 } from "../../../pages/registration/step-headers/step-headers";

const mapStateToProps = (state: any) => {
  return {
    cities: state.retailers.cities,
    prefecture: state.retailers.prefecture,
    loading: state.retailers.loading,
    retailer: state.retailers.selected,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchCities: (retailer: any, prefecture: any) =>
      dispatch(fetchCities(retailer, prefecture)),
    setCity: (data: any) => dispatch(setCity(data)),
    actionGetStoresAtLevelPrefecture: (retailer:any, prefectutre: any) => dispatch(actionGetStoresAtLevelPrefecture(retailer, prefectutre)),
  };
};
interface Props {
  fetchCities: Function;
  actionGetStoresAtLevelPrefecture: Function;
  setCity: Function;
  cities: any;
  prefecture: any;
  retailer: any;
  loading: boolean;
}

export const Screen5Component = (props: Props) => {
  const history = useHistory();
  useIonViewDidEnter(() => {
    // init();
  });
  const init = () => {
    if (props.retailer && props.prefecture) {
      props.fetchCities(props.retailer, props.prefecture);
      props.actionGetStoresAtLevelPrefecture(props.retailer, props.prefecture);
    } else {
      history.replace("/2");
    }
  };
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if(props.prefecture){
      init();
    }
  }, [props.prefecture]);
  let showError = !props.loading && props.cities &&
      props.cities.length===0;
  console.log(showError)
  return (
    <ScrollpageWithHeader title="店舗選択" id="5">
      {props.loading && (
        <div className="mt-6 flex justify-center">
          <IonSpinner />
        </div>
      )}
      <div className="details flex flex-col justify-start overflow-auto pb-6">
        <StepHeader2 />
        {/* <div className="text-lg pl-4 mt-4">{props.prefecture}</div> */}

        <div>
          {props.cities &&
            props.cities.length != 0 &&
            props.cities.map((item: any) => (
              <IonItem
                       detail={true}
                       lines="none"
                       key={item.id}
                       onClick={() => {
                         props.setCity(item);
                         history.push("/7");
                       }}
                       className="text py-2 px-6 -mt-1"
              >
                {/*<div*/}
                {/*  onClick={() => {*/}
                {/*    history.push("/7");*/}
                {/*    props.setCity(item);*/}
                {/*  }}*/}
                {/*  className="text pl-6"*/}
                {/*>*/}
                  {item}
                {/*</div>*/}
              </IonItem>
            ))}
          {showError &&
          <div className="my-auto flex-shrink-0 py-16 sm:py-32">
            {/*<h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">都市が見つかりません</h1>*/}
            <p className="mt-2 text-base text-xs text-gray-600" >データ取得に失敗しました。他の市町村を試すか、後ほど再度お試しください</p>
            <div className="mt-6">
              <a className="text-base font-medium text-indigo-600 hover:text-indigo-500" style={{
                color: 'rgb(6, 199, 85)'
              }} onClick={()=>{
                history.push("/tabs/home");
              }
              }>
                「ホーム」に戻る
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
          }
        </div>
      </div>
    </ScrollpageWithHeader>
  );
};
export const Screen5 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen5Component);
