import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import catalina from "./catalina.png";
import { useEffect, useState } from "react";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { CalendarIcon } from "@heroicons/react/outline";
// import { DateDropdown } from "./dropdown/dropdown";
import MembersService from "../../services/members.service";
import { MemberHolderPointRetailer } from "../../components/member-holder-point-retailer/member-holder-point-retailer";
import { connect } from "react-redux";
import { MemberHolder } from "../../components/member-holder/member-holder";
import {
  setWalletEndDate,
  setWalletStartDate,
} from "../../../redux/wallet/wallet.actions";
import { DateDropdown } from "../screen24/dropdown/dropdown";
import { getVendorWallet, setVendorWalletLoading } from "../../../redux/vendorWallet/vendorWallet.actions";
import {
  useParams
} from "react-router-dom";
import { refreshAllMemberFilteredWallet } from "../../../redux/filteredAllwallet/filteredAllwallet.actions";
import {useIonViewDidEnter} from "@ionic/react";

const mapDispatchToProps = (dispatch: any) => {
  return {
    setWalletStartDate: (data: any) => dispatch(setWalletStartDate(data)),
    setWalletEndDate: (data: any) => dispatch(setWalletEndDate(data)),
    getVendorWallet: (startDate: any, endDate: any, retailer_id: any) => dispatch(getVendorWallet(startDate, endDate, retailer_id)),
    refreshAllMemberFilteredWallet: (startDate: any, endDate: any) => dispatch(refreshAllMemberFilteredWallet(startDate, endDate)),
    setVendorWalletLoading: (loading: boolean) => dispatch(setVendorWalletLoading(loading)),
  };
};
const mapStateToProps = (state: any) => {
  return {
    startDate: state.wallet.startDate,
    endDate: state.wallet.endDate,
    wallet: state.vendorWallet,
    vendorLoading: state.vendorWallet.vendorLoading,
    cards: state.cards,
    paid: state.vendorWallet.paid,
  };
};

interface Props {
  startDate: string;
  endDate: string;
  paid: any;
  wallet: any,
  refreshAllMemberFilteredWallet: Function;
  vendorLoading: boolean,
  cards: any[],
  getVendorWallet: Function;
  setWalletStartDate: Function;
  setWalletEndDate: Function;
  setVendorWalletLoading: Function;
}

export const Screen24RetailerComponent = (props: Props) => {
  const { wallet } = props;
  const [vendorId, setVendorId] = useState(0);
  let params: any = useParams();
  // console.log(params)
  useIonViewDidEnter(()=>{
    let id = window.location.pathname.match(/retailer\/(\d+)/)[1]
    setVendorId( Number(id));
    refetchWallet(props.startDate, props.endDate, id)
  })
  useEffect(() => {
    let id = window.location.pathname.match(/retailer\/(\d+)/)[1]
    setVendorId( Number(id));
    refetchWallet(props.startDate, props.endDate, id)
  }, []);

  if (wallet === null) {
    return null;
  }
  let sumOfHistoryPoints = 0;


  (wallet.paid || []).forEach((result: any) => (sumOfHistoryPoints += result.amount));
  (wallet.to_be_paid || []).forEach((result: any) => (sumOfHistoryPoints += result.amount));
  (wallet.pending || []).forEach((result: any) => (sumOfHistoryPoints += result.amount));
  (wallet.validated || []).forEach((result: any) => (sumOfHistoryPoints += result.amount));

  const refetchWallet = (start: string, end: string, retailer_id: string) => {
    props.setVendorWalletLoading(true)
    setTimeout(() => {
      props.refreshAllMemberFilteredWallet(start, end);
      props.getVendorWallet(start, end, retailer_id ? retailer_id : vendorId);
    }, 500);
  };




  return (
    <ScrollpageWithHeader title="マイアカウント" id="screen-24">
      {/* <div className="flex items-center px-5">
        <img className="w-12 h-12 object-contain" src={catalina} />
        <div className="text flex-1 pl-2">{member?.email}</div>
        <div className="text text-catalina-base pr-2">設定</div>
      </div> */}
      <MemberHolder accountScreenMode={true} retailer_id={params.id} />
      <div className="px-4 mt-8">
        <div className="flex items-center">
          <div className="flex flex-1">
            <CalendarIcon className="h-5 w-5 mr-2 text-gray-700" />
            <div>年月</div>
          </div>
          <div className="flex flex-1 justify-end">

            {!props.vendorLoading &&
              <>
                <div className="text-gray-600 pr-2">{"合計 "}</div>
                <div className="text">{sumOfHistoryPoints} ポイント</div>
              </>
            }
            {props.vendorLoading &&
              <div className="text text-gray mt-2 ml-4 animate-pulse">
                <div className="w-32 bg-gray-300 h-4 rounded-md ">
                </div>
              </div>
            }

          </div>
        </div>
        <div className="flex items-center mt-2">
          <DateDropdown
            // startDate={props.wallet.startDate}
            // endDate={props.wallet.endDate}
            setWalletStartDate={props.setWalletStartDate}
            setWalletEndDate={props.setWalletEndDate}
            refetchWallet={refetchWallet}
            mode="START"
          />
          <ArrowNarrowRightIcon className="h-5 w-5 mx-2 text-gray-400" />
          <DateDropdown
            // startDate={props.wallet.startDate}
            // endDate={props.wallet.endDate}
            setWalletStartDate={props.setWalletStartDate}
            setWalletEndDate={props.setWalletEndDate}
            refetchWallet={refetchWallet}
            mode="END"
          />
        </div>
        <MemberHolderPointRetailer />
      </div>
    </ScrollpageWithHeader>
  );
};

export const Screen24Retailer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen24RetailerComponent);
