import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setSelectedStore } from "../../../redux/stores/stores.actions";
import store1 from "./store_default.png";
import waon from "../../screens/screen2/1.png";
import izumi from "../../screens/screen2/2.png";
import yb from "../../screens/screen2/6.png";
import iy from "../../screens/screen2/10.png";
import {setStoreSelection} from "../../../redux/global/global.actions";

const mapStateToProps = (state: any) => {
  return {
    stores: state.stores,
    state: state,
    selectedStore: state.stores.selectedStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedStore: (data: any) => dispatch(setSelectedStore(data)),
    setStoreSelection: (data: any) => dispatch(setStoreSelection(data))
  };
};

interface Props {
  state: any;
  stores: any;
  selectedStore: any;
  hideActionButton?: boolean;
  setSelectedStore: Function;
  setStoreSelection:Function;
}

export const MemberStoreComponent = (props: Props) => {
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;

  let imageURL:any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  const history = useHistory();
  const { stores, hideActionButton } = props;
  const selectedStore = stores.selectedStore;
  return (
    <div className="block">
      <div className="flex items-center px-4 pt-3 bg-white">
        <div className="flex items-center justify-center rounded-full">
          <img
            className="items-center justify-center rounded-md flex overflow-hidden object-contain"
            src={store1}
            alt="Card"
            style={selectedStore ? {
              height: "2.875rem",
              width: "3.875rem",
            }:{
              height: "3.125rem",
              width: "3.125rem",
            }}
          />
        </div>
        {!selectedStore && (
          <div onClick={() =>{
            props.setStoreSelection(true);
            history.push("/2")
          }
          } className="flex-1 pl-4 text-okuwa_blue font-bold">
            お店を登録
          </div>
        )}
        {selectedStore && (
          <>
            {/* {!hideActionButton && */}
              <div className="flex-1 pl-4">
                <div style={{ color: "#1F1F1F", fontSize: 14 }}>
                  {selectedStore ? selectedStore.name : ""}
                </div>
              </div>
            {/* } */}
            {!selectedStore &&
              <div className="flex-1 pl-4">
                <div style={{ color: "#06C755", fontSize: 14 }} onClick={() => history.push("/2")}>
                  お店を選択してください
                </div>
              </div>
            }

            {/* {!hideActionButton &&  */}
            <div>
              <div
                onClick={() => history.push("/fav-stores")}
                style={{ color: "#06C755", fontWeight: 600 }}
              >
                編集
              </div>
            </div>
            {/* } */}
          </>
        )}
      </div>
    </div>
  );
};

export const MemberStore = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberStoreComponent);
