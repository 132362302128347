import {
  IonItem,
  IonLabel,
  IonList,
  IonToggle,
} from "@ionic/react";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";

export const Screen35 = () => {
  return (
    <ScrollpageWithHeader title="通知設定"  id="screen-35">
      <IonList lines="none">
        <IonItem className="mt-4">
          <IonLabel>カタリナからのお得な情報</IonLabel>
          <IonToggle mode="ios" value="カタリナからのお得な情報" />
        </IonItem>

        <IonItem className="mt-4">
          <IonLabel>メーカーからのお得な情報</IonLabel>
          <IonToggle mode="ios" value="メーカーからのお得な情報" />
        </IonItem>

        <IonItem className="mt-4">
          <IonLabel>新着情報通知</IonLabel>
          <IonToggle mode="ios" value="新着情報通知" />
        </IonItem>

        <IonItem className="mt-4">
          <IonLabel>ポイント付与完了通知</IonLabel>
          <IonToggle mode="ios" value="ポイント付与完了通知" />
        </IonItem>
        
      </IonList>
    </ScrollpageWithHeader>
  );
};
