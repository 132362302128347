import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";

import { useEffect, useState } from "react";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import { IonSpinner, useIonViewDidEnter } from "@ionic/react";
import { connect } from "react-redux";
import {
  fetchRetailers,
  resetHierarchy,
  selectRetailerForAccount
} from "../../../redux/retailers/retailers.actions";
import waon from "../screen2/1.png";
import izumi from "../screen2/2.png";
import yb from "../screen2/6.png";
import iy from "../screen2/10.png";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const mapStateToProps = (state: any) => {
  return {
    retailers: state.retailers.list,
    loading: state.retailers.loading,

  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchRetailers: () => dispatch(fetchRetailers()),
    selectRetailerForAccount: (data: any) => dispatch(selectRetailerForAccount(data)),
    resetHierarchy: (data: any) => dispatch(resetHierarchy())
  };
};
interface Props {
  fetchRetailers: Function;
  resetHierarchy: Function;
  retailers: any
  loading: boolean
  selectRetailerForAccount: Function;
}
export const SelectRetailerComponent = (props: Props) => {
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;

  let imageURL: any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  const [selectedId, setSelectedId] = useState(0);
  const history = useHistory();
  useIonViewDidEnter(() => {
    init();
  })
  useEffect(() => {
    init()
  }, []);
  const init = () => {
    setSelectedId(0);
    props.resetHierarchy();
    props.fetchRetailers();
  }
  return (
    <ScrollpageWithHeader title="カタリナ" id="screen-2">
      <div className="px-4 pb-6">
        {props.loading ||
          (selectedId !== 0 && (
            <div className="mt-6 flex justify-center">
              <IonSpinner />
            </div>
          ))}
        {props.retailers &&
          props.retailers.length != 0 &&
          props.retailers.map((card: any) => (
            <div
              onClick={() => {
                if (selectedId !== 0) {
                  return false;
                }
                setSelectedId(card.id);
                props.selectRetailerForAccount(card);
                setTimeout(() => {
                  setSelectedId(0);
                  history.push("/9");
                }, 2000);
              }}
              key={card.id}
              className={classNames(
                "card mt-4 flex items-center p-2",
                selectedId === card.id ? "bg-gray-100 rounded-md" : ""
              )}
            >
              <img
                className="items-center justify-center rounded-md flex overflow-hidden object-contain"
                src={imageURL[card.id]}
                style={{
                  height: "2.875rem",
                  width: "3.875rem",
                }}
              />
              <div className="pl-3 text-sm flex-1 items-center">
                {card.name}
              </div>
              {selectedId === card.id && <CheckCircleIcon className="h-5 w-5 text-green-600" />}
              <ChevronRightIcon className="h-5 w-5 text-gray-500" />
            </div>
          ))}

      </div>
    </ScrollpageWithHeader>
  );
};
export const SelectRetailer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectRetailerComponent);