import { PaperClipIcon } from "@heroicons/react/solid";
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonItem,
  IonLabel,
  IonRippleEffect,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { TextField } from "../../../components/text-field/text-field";
import { ScrollpageWithHeader } from "../../scrollpage-with-header/scrollpage-with-header";
import "./account-inquiry.scss";
import { PopupEnquirySent } from "./popup-enquiry-sent";
import MembersService from "../../../services/members.service";

export const AccountInquiry = () => {
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [submitDone, setSubmitDone] = useState<boolean>(false);
  const getMember = () => {
    MembersService.getMember().then((res) => {
      setEmail(res.data.email);
      setFirstName(res.data.firstname || "");
      setLastName(res.data.lastname || "");
    });
  };
  const submitInquiry = () => {
    MembersService.inquiry(email, firstName, lastName, message)
      .then(() => {
        setSubmitDone(true);
      })
      .catch(() => {
        setSubmitDone(true);
      });
  };
  useEffect(() => {
    getMember();
  }, []);
  return (
    <ScrollpageWithHeader title="アカウント編集" id="account-editing">
      <div className="px-6 mt-6">
        <IonLabel className="account-editing font-semibold">メールアドレス</IonLabel>
        <TextField
          placeholder="電子メールID"
          value={email}
          disabled={true}
          type="text"
        />

        <div className="flex">
          <div>
            <IonLabel className="account-editing font-semibold">姓</IonLabel>
            <TextField
              placeholder="姓"
              value={lastName}
              onChange={(e: any) => setLastName(e)}
              type="text"
            />
          </div>
          <div className="w-8" />
          <div>
            <IonLabel className="account-editing font-semibold">名</IonLabel>
            <TextField
              placeholder="名"
              value={firstName}
              onChange={(e: any) => setFirstName(e)}
              type="text"
            />
          </div>
        </div>

        <IonLabel className="account-editing">お問合わせ内容</IonLabel>
        <TextField
          placeholder="お問合わせ内容を入力してください"
          value={message}
          onChange={(e: any) => setMessage(e)}
          type="text"
        />
      </div>

      {/*<div className="px-4 mt-6">*/}
      {/*  <IonButton fill="clear" expand="block" className="text-gray-700 border border-gray rounded-xl">*/}
      {/*    <PaperClipIcon className="w-4 h-4 mr-3" /> ファイル添付*/}
      {/*  </IonButton>*/}
      {/*</div>*/}
      <div className="px-6 mt-6 text text-875 font-semibold">個人情報の取り扱いについて</div>
      <div className="block">
        <div className="overflow-y-auto h-60 text-xs" style={{color: "#C4C4C4"}}>

          <div className="px-6 mt-6">
            <div className="text">
              1. 事業者の氏名又は名称
            </div>
            <div className="px-3 mt-1 mb-1">
              カタリナ マーケティング ジャパン株式会社
            </div>

            <div className="text">
              2. 個人情報保護管理者（若しくはその代理人）の職名、所属及び連絡先
            </div>
            <div className="px-3 mt-1 mb-1">
              管理者職名：　財務経理本部長
            </div>
            <div className="px-3 mt-1 mb-1">
              所属部署：　カタリナ マーケティング ジャパン 株式会社　財務経理本部<br/>
            </div>
            <div className="px-3 mt-1 mb-1">
              メールアドレス：privacypolicy_otoiawase@catalinamarketing.co.jp
            </div>



            <div className="text">
              3. 個人情報の利用目的
            </div>
            <div className="px-3 mt-1 mb-1">
              お問い合わせ対応（ご本人様への連絡を含む）のため
            </div>


            <div className="text">
              4. 個人情報取扱いの委託
            </div>
            <div className="px-3 mt-1 mb-1">
              当社は事業運営上、前項利用目的の範囲に限って個人情報を外部に委託することがあります。
            </div>
            <div className="px-3 mt-1 mb-1">
              この場合、個人情報保護水準の高い委託先を選定し、
            </div>
            <div className="px-3 mt-1 mb-1">
              個人情報の適正管理・機密保持についての契約を交わし、適切な管理を実施させます。
            </div>


            <div className="text">
              5. 個人情報の開示等の請求
            </div>
            <div className="px-3 mt-1 mb-1">
              ご本人様は、当社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、
            </div>
            <div className="px-3 mt-1 mb-1">
              利用の停止または消去、第三者への提供の停止）に関して、下記の当社問合わせ窓口に申し出ることができます。
            </div>
            <div className="px-3 mt-1 mb-1">
              その際、当社はお客様ご本人を確認させていただいたうえで、合理的な期間内に対応いたします。
            </div>

            <div className="px-3 mt-1 mb-1">
              【お問合せ窓口】
            </div>
            <div className="px-3 mt-1 mb-1">
              カタリナ マーケティング ジャパン株式会社　個人情報問合せ窓口
            </div>
            <div className="px-3 mt-1 mb-1">
              メールアドレス：privacypolicy_otoiawase@catalinamarketing.co.jp
            </div>
            <div className="px-3 mt-1 mb-1">
              ※土・日曜日、祝日、年末年始、ゴールデンウィーク期間は翌営業日以降の対応とさせていただきます。
            </div>



            <div className="text">
              6. 個人情報を提供されることの任意性について
            </div>
            <div className="px-3 mt-1 mb-1">
              ご本人様が当社に個人情報を提供されるかどうかは任意によるものですが、必要な項目を頂けない場合、
            </div>
            <div className="px-3 mt-1 mb-1">
              適切な対応ができない場合がございます。
            </div>










            {/* <div className="text">個人情報の取り扱いについて</div>

        <div className="mt-6 text-sm text-gray-900">利用資格</div>
        <div className="text-xs text-gray-500">
          本サービス内のお酒のクーポンをご利用頂けるのは、20歳以上の方に限られます。
        </div>

        <div className="mt-6 text-sm text-gray-900">用語の定義について</div>
        <div className="text-xs text-gray-500">
          「本サービス」とは、利用者が当社アプリまたは当社サイト上でクーポンを取得することができる当社のサービスおよびこれに付随して当社アプリまたは当社サイトにおいて提供する全てのサービスをいいます。
        </div> */}
          </div>
        </div>
      </div>
     
      <div>
        <IonItem lines="none" className="flex items-center mt-6">
          <IonCheckbox
            checked={checked}
            className="checkbox-square h-5 w-5"
            onIonChange={(e) => setChecked(e.detail.checked)}
          />
          <IonLabel style={{fontSize: "0.875rem"}} className="text-875">上記事項に同意します(必須)</IonLabel>
        </IonItem>
      </div>
      <div className="mx-4 mt-2">
        <IonButton
          expand="block"
          disabled={!email || !firstName || !lastName || !message || !checked}
          onClick={() => submitInquiry()}
          color="primary"
          className="text-875 disabled-DFDFDF rounded-25-rem"
        >
          送信する
        </IonButton>
      </div>

      <div className="px-6 mb-6 text-xs" style={{color: "#1F1F1F"}}>
        <div className="mt-6">注意事項</div>
        <div>
          ※お問い合わせ対応時間：9:00~21:00（年中無休） <br />
          ※お問い合わせ・ご要望の回答には、数週間お時間をいただく場合がございます。ご了承ください。 <br />
          ※お電話でのお問い合わせについて 　<br />
          ・お問い合わせ先：03-3570-6974
          <br />
          ・受付時間：9:00~21:00
          <br/><br/>
        </div>
      </div>
      {submitDone && <PopupEnquirySent />}
    </ScrollpageWithHeader>
  );
};
