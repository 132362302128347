import { classNames } from "../member-holder-point-retailer/member-holder-point-retailer";

interface Props {
  type: string;
  value?: string;
  placeholder: string;
  onChange?: Function;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
}

export const TextField = (props: Props) => {
  const { type, placeholder, value, onChange, disabled = false } = props;
  return (
    <div className="mt-2 mb-4 relative pb-2 border-b border-gray placeholder-b7b7b7">
      <input
        type={type}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        disabled={disabled}
        value={value}
        className="block w-full rounded-md outline-none text-sm"
        placeholder={placeholder}
      />
    </div>
  );
};


export const TextFieldRounded = (props: Props) => {
  const { className, type, placeholder, value, onChange, disabled, readOnly = false } = props;
  return (
    <div className={classNames("relative p-3 border border-gray placeholder-b7b7b7 rounded-md", className ?? "")}>
      <input
        type={type}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        className="block w-full rounded-md outline-none text-sm"
        placeholder={placeholder}
      />
    </div>
  );
};