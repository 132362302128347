import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ScrollpageWithHeader } from "../../scrollpage-with-header/scrollpage-with-header";

const mapStateToProps = (state: any) => {
  return {
    loading: state.stores.loading,
    retailers: state.retailers,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

interface Props {
  loading: boolean;
  retailers: {
    list: any[];
    selectedForAccount: any | null;
  };
}

export const AccountRetailerAboutComponent = (props: Props) => {
  // console.log("Piropes: ", props);
  let waonId = Number(process.env.REACT_APP_WAON_ID);
  let iyId = Number(process.env.REACT_APP_IY_ID);
  let ybId = Number(process.env.REACT_APP_YB_ID);

  return (
    <ScrollpageWithHeader id="account-retailer-about" title="Retailer About">
      <div className="p-4">
        {props.loading && (
          <div className="flex justify-center pt-24">
            <div className="loader">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        )}
        {!props.loading && props.retailers.selectedForAccount && (
          <>
            <div>
              <div>{props.retailers.selectedForAccount.name}について</div>
            </div>
            <div>
              <div
                style={{ lineHeight: 2 }}
                dangerouslySetInnerHTML={{
                  __html: props.retailers.selectedForAccount.disclaimer_html,
                }}
              ></div>
              {props.retailers.selectedForAccount.id === waonId && (
                <><div className="text-xs mt-3" style={{ fontSize: 14 }}>
                  ※Apple Pay の WAON / イオンカード<br />
                  (WAON一体型)
                  <Link to={"/9"} className="text">
                    <b className="underline">ご利用上の注意</b>
                  </Link>
                </div></>
              )}
              {(props.retailers.selectedForAccount.id === iyId || props.retailers.selectedForAccount.id === ybId) && (
                <><div className="text-xs mt-3" style={{ fontSize: 14 }}>
                  ※Apple Pay の nanaco / セブンカード・プラス<br />
                  （nanaco一体型）
                  <Link to={"/9"} className="text">
                    <b className="underline"> ご利用上の注意</b>
                  </Link>
                </div></>
              )}
            </div>
          </>
        )}
      </div>
    </ScrollpageWithHeader>
  );
};

export const AccountRetailerAbout = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountRetailerAboutComponent);
