import {SET_ACCOUNT_MODE, SET_HOLDER_CARDS} from "./holder-cards.types";

export const setHolderCards = (data) => {
  return {
    type: SET_HOLDER_CARDS,
    payload: data,
  };
};

export const setAccountMode = (data) => {
  return {
    type: SET_ACCOUNT_MODE,
    payload: data,
  };
};
