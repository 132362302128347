import {SAMPLE_WALLET} from "./SAMPLE_WALLET";
import {RESET_V_ALLWALLET, SET_V_ALLWALLET, SET_V_LOADING} from "./vendorWallet.types";

const INITIAL_STATE = {
    pending: [],
    refused: [],
    to_be_paid: [],
    paid: [],
    validated: [],
    startDate: null,
    endDate: null,
    vendorLoading: false
};
// const INITIAL_STATE = SAMPLE_WALLET;

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_V_ALLWALLET:
            return {
                ...state,
                ...action.payload,
                vendorLoading: false
            };
        case SET_V_LOADING:
            return {
                ...state,
                vendorLoading: action.payload
            };
        case RESET_V_ALLWALLET:
            return {
                pending: [],
                refused: [],
                to_be_paid: [],
                paid: [],
                validated: [],
                startDate: null,
                endDate: null,
                vendorLoading: false
            };
        default:
            return state;
    }
};

export default reducer;
