import React, { FC } from "react";
import logo from "../logos/logo.png";
import { IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import { Link } from "react-router-dom";

const pageHeight = window.innerHeight;

interface Props {
  makeBackgroundTransparent?: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const HalfSplitBackgroundShell: FC<Props> = (props) => {
  return (
    <IonPage>
      <div
        className={classNames(
          "relative flex flex-1 flex-col px-3",
          !!props.makeBackgroundTransparent ? "" : "py-8"
        )}
      >
        <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-1 flex-col">
          <div
            className="bg-okuwa_blue"
            style={{ height: pageHeight / 2.1 }}
          ></div>
          <div className="bg-white flex-1"></div>
        </div>
        <div className="login z-10 flex flex-col  pt-6 flex-1">
          <Link className="text-center flex items-center justify-center" to="/pages">
            <img className="h-20 mb-8 self-center" src="https://vignette1.wikia.nocookie.net/cookierun/images/2/28/Logo_line_circle.png/revision/latest?cb=20150817074324" />
          </Link>
          <div
            className={classNames(
              "flex flex-col flex-1 rounded-md",
              !!props.makeBackgroundTransparent
                ? ""
                : " bg-white shadow-lg px-5 py-8"
            )}
            style={{
              boxShadow: props.makeBackgroundTransparent
                ? "none"
                : "0px 1px 17px 5px rgba(0,0,0,0.30)",
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </IonPage>
  );
};
