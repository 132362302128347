import step3 from "../../../line/screens/screen1/step-images/step3.png";
import marker3 from "../../../pages/registration/step-headers/markers/marker3.png";

export default function HomePopUp() {
    return (
        <div className="p-4 relative">
            <div className="absolute top-5 right-6">
                <img src={marker3} className="h-1" />
            </div>
            <div className="text-red-400 font-semibold">Step3</div>
            <div>
                使いたいクーポンをセットすることでカードと紐付けをおこないます。
            </div>
            <img className="h-20 object-contain mx-auto mt-6 mb-3" src={step3} />
        </div>
    );
}