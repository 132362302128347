import {IonPage, IonHeader, IonToolbar, IonText, IonContent} from "@ionic/react";
import {FC, ReactNode, useEffect, useState} from "react";
import "./scrollpage-with-header.scss";
import liff from "@line/liff";
import { useHistory } from "react-router-dom";
import {setFirstLoad} from "../../../redux/global/global.actions";

interface Props {
  id: string;
  title: string;
  showSelectStoreIcon?: boolean;
  fixedContentBelowHeader?: ReactNode;
  showBackButton?: boolean;
  onCloseClick?: Function;
  isAppLoading?:boolean;
  shouldTrackScroll?:boolean;
  onScroll?:Function;
}


export const ScrollpageWithHeader: FC<Props> = (props) => {
  const history = useHistory();
  const { title, showBackButton, fixedContentBelowHeader, onScroll } = props;
  let [scrollEvents, setScrollEvents] = useState(!!onScroll);
  useEffect(()=>{
      setScrollEvents(props.shouldTrackScroll);
  },[props.shouldTrackScroll])
  return (
    <IonPage id={props.id}>
      <IonHeader mode="ios">
        {false && props.showSelectStoreIcon && (
          <IonToolbar mode="ios" className="catalina-scrollpage-header">
            {/* <IonButtons slot="start">
              <div className="ml-4">
                <FilmIcon className="w-6 h-6"
                  onClick={() => {
                    lineLogOut();
                  }} />
              </div>
            </IonButtons> */}
              {
                  !props.isAppLoading &&<div>
                      <div
                          className="p-4 mx-3 mb-2 flex justify-center"
                          style={{ background: "#FFF4D8", borderRadius: "0.5rem" }}
                      >
                          <IonText
                              className="text-xs pt-1"
                              color="danger"
                              style={{ color: "#FF772A", fontSize: 13, fontWeight: 600 }}
                          >
                              お店とカードを登録してお買い物でポイントを還元
                          </IonText>
                      </div>
                      <div className="pointer"></div>
                  </div>
              }

            {/* <IonButtons slot="end">
            <XIcon className="w-6 h-6 mr-4" 
            onClick={() => {
              lineLogOut();
              setTimeout(() => {
                window.location.reload();
              });
            }}/>
          </IonButtons> */}
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent  scrollEvents={scrollEvents} onIonScroll={()=>{
          console.log('scrolling')
          props.onScroll(false)
      }}>
          <div className="flex flex-col flex-1 bg-white overflow-auto">
              {fixedContentBelowHeader && <div>{fixedContentBelowHeader}</div>}
              <div className="flex flex-col flex-1 overflow-auto">
                  {props.children}
              </div>
          </div>
      </IonContent>
    </IonPage>
  );
};
