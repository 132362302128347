import React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import linelogo from "./linelogo.png";
import steptext from "./steptext.png";
export const LineDarkLink = () => {


    return (
        <>
            <div style={{ background: "rgb(71 71 71)" }} className="px-6 pb-6 pt-6">
                <img src={steptext} className="h-5" />
                <div className="text-white text-xs mt-1">LINEクーポン×カタリナ</div>
                <div className="mt-4" style={{ fontSize: 10, color: "#B7B7B7" }}>
                    ©LINE Corporation
                </div>
            </div>
        </>
    );
};
