import { useEffect, useState } from "react";
import DataService from "../../line/services/data.service";
import waon from "../../line/screens/screen2/1.png";
import izumi from "../../line/screens/screen2/2.png";
import yb from "../../line/screens/screen2/6.png";
import iy from "../../line/screens/screen2/10.png";
interface Props {
  retailer?: any;
}

export const SelectedRetailerComponent = (props: Props) => {
  const { retailer } = props;
  const [totalStores, setTotalStores] = useState(0);
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;

  let imageURL:any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  useEffect(() => {
    if (props.retailer) {
      DataService.getStoresCountForRetailer(retailer).then((response) => {
        setTotalStores(response.data.total);
      });
    }
  }, [props.retailer]);

  if (!retailer) {
    return null;
  }

  return (
    <div className="card mt-4 flex items-center p-2">
      <img
        style={{
          height: "2.875rem",
          width: "3.875rem",
        }}
        className="items-center justify-center rounded-md flex overflow-hidden object-contain"
        src ={imageURL[retailer.id]}
      />
      <div className="flex flex-col pl-3 text-sm flex-1">
        <div>{retailer.name}</div>
         {totalStores !== 0 && <div>総店舗数: {totalStores}</div>}
      </div>
    </div>
  );
};
