import waon from "../../screens/screen2/1.png";
import izumi from "../../screens/screen2/2.png";
import yb from "../../screens/screen2/6.png";
import iy from "../../screens/screen2/10.png";

interface Props {
  retailer: any;
  points: number;
  onClick?: Function;
}

export const RetailerPoints = (props: Props) => {
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;

  let imageURL: any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  const { retailer, points, onClick } = props;
  return (
    <div
      className="flex items-center py-3 px-4 border-b border-gray-200"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <img
        className="items-center justify-center rounded-md flex overflow-hidden object-contain"
        style={{
          height: "2.875rem",
          width: "3.875rem",
        }}
        src={imageURL[retailer.id]}
        alt="Card"
      />
      <div className="text-lg text-gray-600 flex-1 pl-4">
        <span className="text-875" style={{
          color: "#1F1F1F"
        }}>{retailer.name}</span>
      </div>
      <div className="pl-4 text-gray-500">
        <span className="text-base">{points || 0}</span>
        <span className="text-sm">ポイント</span>
      </div>
    </div>
  );
};
