import "./screen-coupons.scss";
import { MemberStore } from "../../components/member-store/member-store";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import { OffersWithCategories } from "../../components/offers-with-categories/offers-with-categories";
import { Steps } from "../steps/steps";
import { connect } from "react-redux";
import { Screen16Component } from "../screen16/screen16";
import { setSelectedTab } from "../../../redux/tabs/tabs.actions";
import { useIonViewDidEnter } from "@ionic/react";
import { useRef } from "react";

interface Props {
  setSelectedTab: Function;
}

const mapStateToProps = (state: any) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedTab: (data: any) => dispatch(setSelectedTab(data)),
  };
};

export const ScreenCouponsComponent = (props: Props) => {
  useIonViewDidEnter(() => {
    console.log('ionViewDidEnter event fired on coupons page ');
    props.setSelectedTab('coupons');

  });
  const stepSection = useRef(null);
  const scrollDown = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    console.log('scrolled' + window.screenX)
  };
  return (
    <ScrollpageWithHeader title="クーポン" showBackButton={true} id="coupons">
      <div className="flex-1">
        <MemberStore />
        <div className="h-2 mt-2 ">&nbsp;</div>
        <div className="px-4 text-sm underline" onClick={() => scrollDown(stepSection)}>クーポンプラス使い方はこちら</div>
        <OffersWithCategories />
        <div className="section steps" ref={stepSection}><Steps /></div>
      </div>
    </ScrollpageWithHeader>
  );
};

export const ScreenCoupons = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenCouponsComponent);