import {IonItem, IonSpinner, useIonViewDidEnter} from "@ionic/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setAppLoading } from "../../../redux/global/global.actions";
import { fetchPrefectures, setPrefecture } from "../../../redux/retailers/retailers.actions";
import { useHistory, useParams } from "react-router";

import { SelectedRetailerComponent } from "../../../components/selected-retailer/selected-retailer";
import { actionGetStoresAtLevelRetailer } from "../../../redux/stores/stores.actions";
import { ScrollpageWithHeader } from "../../../line/screens/scrollpage-with-header/scrollpage-with-header";
import { StepHeader2 } from "../step-headers/step-headers";

const mapStateToProps = (state: any) => {
  return {
    prefectureList: state.retailers.prefectureList,
    prefecture: state.retailers.prefecture,
    loading: state.retailers.loading,
    retailer: state.retailers.selected,
    selectionMode: state.retailers.selectionMode

  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setAppLoading: (data: any) => dispatch(setAppLoading(data)),
    fetchPrefectures: (retailer: any) => dispatch(fetchPrefectures(retailer)),
    actionGetStoresAtLevelRetailer: (retailer: any) => dispatch(actionGetStoresAtLevelRetailer(retailer)),
    setPrefecture: (data: any) => dispatch(setPrefecture(data))
  };
};

interface Props {
  fetchPrefectures: Function;
  setPrefecture: Function;
  actionGetStoresAtLevelRetailer: Function;
  loading: boolean,
  selectionMode: boolean,
  prefectureList: any,
  prefecture: any,
  retailer: any
}

export const showSplitHyphenName = (name: string) => {
  if(name.indexOf("-") === -1) {
    return name;
  }

  return name.split("-")[1];
}

export const RetailerPrefecturesComponent = (props: Props) => {
  const history = useHistory();
  let { retailerid } = useParams() as any;

  const init = () => {
    props.fetchPrefectures({ id: retailerid });
    // props.actionGetStoresAtLevelRetailer({ id: retailerid });
  }

  useEffect(() => {
    init()
  }, [retailerid]);

  let showError = props.loading && props.prefectureList && props.prefectureList.length === 0;

  return (
    <ScrollpageWithHeader title="店舗選択" id="a">

      {/* Loader */}
      {props.loading && (
        <div className="mt-6 flex justify-center">
          <IonSpinner />
        </div>
      )}

      <StepHeader2 />

      <div className="details flex flex-col justify-start overflow-auto px-5 pb-6 flex-1">

        {/* Selected retailer */}
        {!props.loading && (
          <div className="-ml-1.5">
            <SelectedRetailerComponent retailer={{ id: retailerid }} />
          </div>
        )}

        {/* <div className="text-lg text-left mt-5">店舗選択</div> */}

        {/* Prefecture list */}
        {props.prefectureList && props.prefectureList.length != 0 && props.prefectureList.map((item: any) => {
          return (
              <IonItem
                  detail={true}
                  lines="none"
                  key={item}
              className="text pl-1 py-2"
                  onClick={()=>{
                    history.push(`/sign-up/retailer/${retailerid}/prefecture/${encodeURIComponent(item)}/city`)
                  }}
            >
              {showSplitHyphenName(item)}
              </IonItem>
          )
        })}

        {showError &&
          <div className="my-auto flex-shrink-0 py-16 sm:py-32">
            {/*<h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">都市が見つかりません</h1>*/}
            <p className="mt-2 text-base text-gray-600" >データ取得に失敗しました。他の市町村を試すか、後ほど再度お試しください</p>
            <div className="mt-6">
              <a className="text-base font-medium text-indigo-600 hover:text-indigo-500" style={{
                color: 'rgb(6, 199, 85)'
              }} onClick={() => {
                history.push("/tabs/home");
              }
              }>
                「ホーム」に戻る
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        }
      </div>
    </ScrollpageWithHeader>
  );
};
export const RetailerPrefectures = connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailerPrefecturesComponent);