import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import "./App.scss";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { GetStartedPage } from "./pages/get-started/GetStarted";
import { TermsPage } from "./pages/terms/terms";
import { Terms2Page } from "./pages/terms/terms2";
import { CartPage } from "./pages/cart/cart";
import { ProductDetailsPage } from "./pages/product-details/product-details";
import { ForgotPasswordReset } from "./pages/auth/forgot-password-reset/forgot-password-reset";
import { LoginScreen } from "./pages/auth/login/login";
import { RegisterScreen } from "./pages/auth/register/register";
import { ForgotPasswordSendMail } from "./pages/auth/forgot-password-send-mail/forgot-password-send-mail";
import { PagesMenu } from "./pages/auth/menu/menu";
import { Screen1 } from "./line/screens/screen1/Screen1";
import { Screen2 } from "./line/screens/screen2/screen2";
import { Screen23 } from "./line/screens/screen23/screen23";
import { Screen6 } from "./line/screens/screen6/screen6";
import { Screen9 } from "./line/screens/screen9/screen9";
import { Screen4 } from "./line/screens/screen4/screen4";
import { Screen5 } from "./line/screens/screen5/screen5";
import { Screen7 } from "./line/screens/screen7/screen7";
import { CouponsTabs } from "./line/tabs/tabs";
import { AccountEditing } from "./line/screens/account-screens/account-editing/account-editing";
import { AccountSettings } from "./line/screens/account-screens/account-settings/account-settings";
import { Screen30 } from "./line/screens/screen30/screen30";
import { Screen31 } from "./line/screens/screen31/screen31";
import { Screen35 } from "./line/screens/screen35/screen35";
import { Screen36 } from "./line/screens/screen36/screen36";
import { Screen37 } from "./line/screens/screen37/screen37";
import { AccountFaq } from "./line/screens/account-screens/account-faq/account-faq";
import { AccountTerms } from "./line/screens/account-screens/account-terms/account-terms";
import { AccountPrivacy } from "./line/screens/account-screens/account-privacy/account-privacy";
import { AccountInquiry } from "./line/screens/account-screens/account-inquiry/account-inquiry";
import { AccountRetailerAbout } from "./line/screens/account-screens/account-retailer-about/account-retailer-about";

import { Provider } from "react-redux";

import store from "./redux/store";
import { OfferDetails } from "./line/screens/screen18/screen18";
import { Screen24Retailer } from "./line/screens/screen24-retailer/screen24-retailer";
import {Screen38} from "./line/screens/screen38/screen38";
import {SelectRetailer, SelectRetailerComponent} from "./line/screens/select-retailer/SelectRetailer";
import {ErrorPage} from "./pages/error/error";
import {CouponPlusInfo} from "./line/screens/coupon-plus-info/coupon-plus-info";
import {Screen0} from "./line/screens/screen0/Screen0";
import {CampaignEnded} from "./line/screens/campaign-ended/CampaignEnded";
import {CampaignSepEnded} from "./line/screens/campaign-ended/CampaignEndedSep";
import {CampaignEndedNov} from "./line/screens/campaign-ended/CampaignEndedNov";
import {CampaignEndedJan} from "./line/screens/campaign-ended/CampaignEndedJan";
import {CampaignFeb} from "./line/screens/campaign/CampaignFeb";
import {CampaignEndedFeb} from "./line/screens/campaign-ended/CampaignEndedFeb";
import {CampaignEndedApr} from "./line/screens/campaign-ended/CampaignEndedApr";

import {CouponPlusInfoUpdated} from "./line/screens/coupon-plus-info/coupon-plus-info-updated";
import { RegistrationPage } from "./pages/registration/Registration";
import { SignUpRetailers } from "./pages/registration/retailers/retailers";
import { RetailerPrefectures } from "./pages/registration/retailer-prefectures/retailer-prefectures";
import { PrefectureCities } from "./pages/registration/prefecture-cities/prefecture-cities";
import { CityStores } from "./pages/registration/city-stores/city-stores";
import { InputCard } from "./pages/registration/input-card/input-card";

import {CampaignEndedJune} from "./line/screens/campaign-ended/CampaignEndedJune";
import {ServiceInro} from "./line/screens/coupon-plus-info/service-intro";
import {CampaignEndedJuly} from "./line/screens/campaign-ended/CampaignEndedJuly";
import {CampaignComponent, CampaignPage} from "./line/screens/campaign/CampaignPage";




const App: React.FC = () => {
  return (
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet mode="ios" id="main">
            <Route path="/tabs" render={() => <CouponsTabs />} />

            {/* Registration Flow */}
            <Route exact path="/sign-up/registration">
              <RegistrationPage />
            </Route>

            <Route exact path="/sign-up/input-card">
              <InputCard />
            </Route>

            <Route exact path="/sign-up/retailer">
              <SignUpRetailers />
            </Route>

            <Route exact path="/sign-up/retailer/:retailerid/prefecture">
              <RetailerPrefectures />
            </Route>

            <Route exact path="/sign-up/retailer/:retailerid/prefecture/:prefecture/city">
              <PrefectureCities />
            </Route>

            <Route exact path="/sign-up/retailer/:retailerid/prefecture/:prefecture/city/:city/store">
              <CityStores />
            </Route>
            {/*  */}

            {/*<Route exact path="/1">*/}
            {/*  <Screen1 />*/}
            {/*</Route>*/}
            <Route exact path="/1">
              <CouponPlusInfoUpdated />
            </Route>
            <Route exact path="/0">
              <Screen0 />
            </Route>
            <Route exact path="/campaign">
              <CampaignPage />
            </Route>
            <Route exact path="/campaign-feb">
              <CampaignFeb />
            </Route>
            <Route exact path="/campaign-ended">
              <CampaignEnded />
            </Route>

            <Route exact path="/campaign-apr-ended">
              <CampaignEndedApr/>
            </Route>
            <Route exact path="/campaign-jun-ended">
              <CampaignEndedJune/>
            </Route>
            <Route exact path="/campaign-july-ended">
              <CampaignEndedJuly/>
            </Route>
            <Route exact path="/2">
              <Screen2 />
            </Route>
            <Route exact path="/select-retailer">
              <SelectRetailer />
            </Route>
            <Route exact path="/4">
              <Screen4 />
            </Route>
            <Route exact path="/5">
              <Screen5 />
            </Route>
            <Route exact path="/6">
              <Screen6 />
            </Route>
            <Route exact path="/7">
              <Screen7 />
            </Route>
            <Route exact path="/9">
              <Screen9 />
            </Route>

            <Route exact path="/23">
              <Screen23 />
            </Route>

            <Route exact path="/30">
              <Screen30 />
            </Route>
            <Route exact path="/31">
              <Screen31 />
            </Route>
            <Route exact path="/35">
              <Screen35 />
            </Route>
            <Route exact path="/36">
              <Screen36 />
            </Route>
            <Route exact path="/37">
              <Screen37 />
            </Route>
            <Route
              path="/offer-details"
              render={() => <OfferDetails />}
              exact={true}
            />
            {/* ACCOUNT SCREENS */}
            <Route
              path="/account/retailer/:id"
              render={() => <Screen24Retailer />}
              exact={true}
            />
            <Route
                path="/fav-stores"
                render={() => <Screen38 />}
                exact={true}
            />
            <Route exact path="/account/retailer-about">
              <AccountRetailerAbout />
            </Route>
            <Route exact path="/account/settings">
              <AccountSettings />
            </Route>
            <Route exact path="/account/editing">
              <AccountEditing />
            </Route>
            <Route exact path="/account/faq">
              <AccountFaq />
            </Route>
            <Route exact path="/account/terms">
              <AccountTerms />
            </Route>
            <Route exact path="/account/privacy">
              <AccountPrivacy />
            </Route>
            <Route exact path="/account/inquiry">
              <AccountInquiry />
            </Route>
            <Route exact path="/login">
              <LoginScreen />
            </Route>
            <Route exact path="/register">
              <RegisterScreen />
            </Route>
            <Route exact path="/forgot-password-email">
              <ForgotPasswordSendMail />
            </Route>
            <Route exact path="/forgot-password-reset">
              <ForgotPasswordReset />
            </Route>
            <Route exact path="/terms">
              <TermsPage />
            </Route>
            <Route exact path="/error">
              <ErrorPage />
            </Route>
            <Route exact path="/terms2">
              <Terms2Page />
            </Route>
            <Route exact path="/get-started">
              <GetStartedPage />
            </Route>
            <Route exact path="/cart">
              <CartPage />
            </Route>
            <Route exact path="/product-details">
              <ProductDetailsPage />
            </Route>
            <Route exact path="/coupon-plus-info">
              <ServiceInro />
            </Route>
            <Route exact path="/pages">
              <PagesMenu />
            </Route>
            <Route exact path="/">
              <Redirect to="/1" />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </Provider>
  );
};

export default App;
