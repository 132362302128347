import { IonButton, IonItem, IonLabel } from "@ionic/react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ScrollpageWithHeader } from "../../scrollpage-with-header/scrollpage-with-header";
import "./account-settings.scss";



const mapStateToProps = (state: any) => {
  return {
      retailers: state.retailers.list

  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};
interface Props {
  retailers:any
}


export const AccountSettingsPage = (props: Props) => {
  const history = useHistory();

  return (
    <ScrollpageWithHeader title="設定"  id="account-settings">
      <div className="mt-4">
        <IonItem lines="none" className="settings-item" button onClick={() => {history.push("/account/editing")}}>
          <IonLabel className="settings-label">アカウント編集</IonLabel>
        </IonItem>

        <IonItem lines="none" className="settings-item" button onClick={() => {
          props.retailers.length===1 ? history.push("/9"): history.push("/select-retailer")
          }}>
          <IonLabel className="settings-label">マイカード</IonLabel>
        </IonItem>

        <IonItem lines="none" className="settings-item" button onClick={() => {history.push("/account/inquiry")}}>
          <IonLabel className="settings-label">お問い合わせ</IonLabel>
        </IonItem>

        <IonItem lines="none" className="settings-item" button onClick={() => {history.push("/account/faq")}}>
          <IonLabel className="settings-label">よくある質問</IonLabel>
        </IonItem>

        <IonItem lines="none" className="settings-item" button onClick={() => {history.push("/account/terms")}}>
          <IonLabel className="settings-label">利用規約</IonLabel>
        </IonItem>

        <IonItem lines="none" className="settings-item" button onClick={() => {history.push("/account/privacy")}}>
          <IonLabel className="settings-label">個人情報保護方針</IonLabel>
        </IonItem>
       
        {/*<IonButton className="settings-button" fill="clear" color="danger">ログアウト</IonButton>*/}
      </div>
    </ScrollpageWithHeader>
  );
};

export const AccountSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsPage);