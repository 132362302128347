import { IonAlert } from "@ionic/react";
interface Props {
    open: boolean;
    setOpen: Function;
    confirmClose: Function;
}
export const Popup2 = (props: Props) => {
  return (
    <IonAlert
      mode="ios"
      isOpen={props.open}
      onDidDismiss={() => {}}
      cssClass="confirm-modal"
      header={"ご登録のメールアドレスに確認メールを送信しました"}
      message={
        '<p class="text-gray-400">アカウントを削除するにはメールに記載のURLにアクセスしてください、</p>'
      }
      buttons={[
        {
          text: "はい",
          cssClass: "py-6",
          handler: () => {
            props.setOpen(false);
            props.confirmClose(true);
          },
        },
      ]}
    />
  );
};
