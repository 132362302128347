// function getFormattedDate(date: Date) {
//   var year = date.getFullYear();

//   var month = (1 + date.getMonth()).toString();
//   month = month.length > 1 ? month : "0" + month;

//   var day = date.getDate().toString();
//   day = day.length > 1 ? day : "0" + day;

//   return year + "/" + month + "/" + day;
// }

const UtilityService = {
  fetchEndDate: (endDate: string) => {
    const endDateArray = endDate.split("/");
    var d = new Date(Number(endDateArray[0]), Number(endDateArray[1]), 0);
    return d.toString();
  },
  timeOut: 15000
};

(window as any).UtilityService = UtilityService;

export default UtilityService;
