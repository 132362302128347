import { IonItem, IonLabel } from "@ionic/react";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";

export const Screen36 = () => {
  return (
    <ScrollpageWithHeader title="キャンペーンコード"  id="screen-36">
      <div className="mt-4">
        <IonItem lines="none" button onClick={() => {}}>
          <IonLabel>コードを入力</IonLabel>
        </IonItem>

        <IonItem lines="none" button onClick={() => {}}>
          <IonLabel>コード履歴</IonLabel>
        </IonItem>
      </div>
    </ScrollpageWithHeader>
  );
};
