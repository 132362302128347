import {applyMiddleware, createStore} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux';
import rootReducer from "./rootReducer";
import thunk from "redux-thunk"
const middleware = {
    thunk
}
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);
// store.subscribe(()=>{
//     console.log(store.getState())
// })
export default store;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;