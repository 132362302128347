import { useEffect, useState } from "react";
import { connect } from "react-redux";
import MembersService from "../../services/members.service";

interface Props {
  wallet: any;
  selectedStore: any;
}

const mapStateToProps = (state: any) => {
  return {
    wallet: state.wallet,
    selectedStore: state.stores.selectedStore,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const MemberWalletComponent = (props: Props) => {
  const { wallet } = props;

  if (wallet === null) {
    return null;
  }
  let sumOfHistoryPoints = 0;
  const selectedStore = props.selectedStore ? props.selectedStore : {retailer_id: "-1"};

  (wallet.paid || []).forEach((result: any) => {
        if(result.retailer_id === selectedStore.retailer_id){
          sumOfHistoryPoints += result.amount
        }
  }
  );
  (wallet.to_be_paid || []).forEach((result: any) => {
    if(result.retailer_id === selectedStore.retailer_id){
      sumOfHistoryPoints += result.amount
    }
  });
  (wallet.pending || []).forEach((result: any) => {
    if(result.retailer_id === selectedStore.retailer_id){
      sumOfHistoryPoints += result.amount
    }
  });
  (wallet.validated || []).forEach((result: any) => {
    if(result.retailer_id === selectedStore.retailer_id){
      sumOfHistoryPoints += result.amount
    }
  });

  return (
    <div className="px-4 mt-3 flex items-center border-t border-gray-100 pt-4 bg-white">
      <div className="flex-1" style={{ color: "#1F1F1F", fontSize: 14 }}>
        過去６ヶ月の獲得したポイント数
      </div>
      <div className="px-4" style={{ color: "#1F1F1F", fontSize: 14 }}>
        <span className="text-lg">{sumOfHistoryPoints}</span>
        <span className="text-sm">ポイント</span>
      </div>
    </div>
  );
};

export const MemberWallet = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberWalletComponent);
