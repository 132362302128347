import { ScrollpageWithHeader } from "../../scrollpage-with-header/scrollpage-with-header";

export const AccountPrivacy = () => {
  return (
    <ScrollpageWithHeader id="account-terms" title="利用規約">
      <div className="mt-5 p-4 text-sm" style={{ lineHeight: 2, color: "#1F1F1F" }}>
        <div className="text-xl font-bold">個人情報保護方針</div>
        {/* <div className="text">改定　2020年8月25日</div> */}
        <div className="mt-12 text">カタリナマーケティングジャパン株式会社</div>
        <div className="text mt-3">
          当社の個人情報保護方針は以下のリンクよりご確認をお願い致します。
        </div>
        <div
          className="underline-offset-4 text-blue-700"
          style={{
            textDecorationLine: "underline",
            textUnderlineOffset: '3px'
          }}
          onClick={() => {
            window.location.href =
              "https://www.catalinamarketing.co.jp/privacy-policy/#houshin";
            // liff.closeWindow();
          }}
        >
            https://www.catalinamarketing.co.jp/privacy-policy/#houshin
        </div>


        {/* <div className="mt-10">
          当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。
        </div>

        <div className="mt-10">
          個人情報は、日本全国のGMS／SM／ドラッグストアの店頭におけるPOS連動の店頭メディアおよびオンラインメディアによる「カタリナ・ターゲット・メディア®」を活用したターゲット・マーケティング戦略の企画・立案・実施・効果検証業務における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。
          個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。
          個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、万一の際には速やかに是正措置を講じます。
          個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。
          個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。
          本方針は、全ての従業者に配付して周知させるとともに、当社のホームページ、パンフレットなどに掲載することにより、いつでもどなたにも入手可能な措置を取るものとします。
        </div>
        <div className="mt-10">
          以上
        </div> */}
      </div>
    </ScrollpageWithHeader>
  );
};
