import {IonItem, IonSpinner, useIonViewDidEnter} from "@ionic/react";
import {useEffect} from "react";
import {useHistory, useParams} from "react-router";
import {connect} from "react-redux";
import {
    fetchCities,
    setCity,
} from "../../../redux/retailers/retailers.actions";
import {actionGetStoresAtLevelPrefecture} from "../../../redux/stores/stores.actions";
import {ScrollpageWithHeader} from "../../../line/screens/scrollpage-with-header/scrollpage-with-header";
import {StepHeader2} from "../step-headers/step-headers";
import {Link} from "react-router-dom";

const mapStateToProps = (state: any) => {
    return {
        cities: state.retailers.cities,
        prefecture: state.retailers.prefecture,
        loading: state.retailers.loading,
        retailer: state.retailers.selected,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchCities: (retailer: any, prefecture: any) =>
            dispatch(fetchCities(retailer, prefecture)),
        setCity: (data: any) => dispatch(setCity(data)),
        actionGetStoresAtLevelPrefecture: (retailer: any, prefectutre: any) => dispatch(actionGetStoresAtLevelPrefecture(retailer, prefectutre)),
    };
};

interface Props {
    fetchCities: Function;
    actionGetStoresAtLevelPrefecture: Function;
    setCity: Function;
    cities: any;
    prefecture: any;
    retailer: any;
    loading: boolean;
}

export const PrefectureCitiesComponent = (props: Props) => {
    const history = useHistory();
    let {prefecture, retailerid} = useParams() as any;

    const init = () => {
        props.fetchCities({id: retailerid}, prefecture);
    };

    useEffect(() => {
        init();
    }, [prefecture]);

    let showError = !props.loading && props.cities && props.cities.length === 0;

    return (
        <ScrollpageWithHeader title="店舗選択" id="5">

            {props.loading && (
                <div className="mt-6 flex justify-center">
                    <IonSpinner/>
                </div>
            )}

            <StepHeader2/>

            <div className="details flex flex-col justify-start overflow-auto px-5 pb-6 mt-6">

                {/* <div className="text-lg pl-4 mt-4">{prefecture}</div> */}

                <div className="space-y-2 pl-1">
                    {props.cities &&
                        props.cities.length != 0 &&
                        props.cities.map((item: any) => (
                            <div>
                                <IonItem key={item}
                                         detail={true}
                                         lines="none"
                                         className="mt-2"
                                         onClick={() => {
                                             props.setCity(item);
                                             history.push(`/sign-up/retailer/${retailerid}/prefecture/${encodeURIComponent(prefecture)}/city/${encodeURIComponent(item)}/store`);
                                         }}
                                >
                                    {item}

                                </IonItem>
                            </div>
                        ))}

                    {showError &&
                        <div className="my-auto flex-shrink-0 py-16 sm:py-32">
                            {/*<h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">都市が見つかりません</h1>*/}
                            <p className="mt-2 text-base text-gray-600">データ取得に失敗しました。他の市町村を試すか、後ほど再度お試しください</p>
                            <div className="mt-6">
                                <a className="text-base font-medium text-indigo-600 hover:text-indigo-500" style={{
                                    color: 'rgb(6, 199, 85)'
                                }} onClick={() => {
                                    history.push("/tabs/home");
                                }
                                }>
                                    「ホーム」に戻る
                                    <span aria-hidden="true"> &rarr;</span>
                                </a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </ScrollpageWithHeader>
    );
};
export const PrefectureCities = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrefectureCitiesComponent);
