export const SET_RETAILER_LIST = "SET_RETAILER_LIST";
export const SET_RETAILER = "SET_RETAILER";
export const SELECT_RETAILER_FOR_ACCOUNT = "SELECT_RETAILER_FOR_ACCOUNT";
export const SET_RETAILERS_LOADING = "SET_RETAILERS_LOADING";
export const SET_PREFECTURE_LIST = "SET_PREFECTURE_LIST";
export const SET_PREFECTURE = "SET_PREFECTURE";
export const SET_CITY_LIST = "SET_CITY_LIST";
export const SET_CITY = "SET_CITY";
export const SET_AREA_LIST = "SET_AREA_LIST";
export const SET_AREA = "SET_AREA";
export const SET_STORES_LIST = "SET_STORES_LIST";
export const SET_STORE = "SET_STORE";
export const SET_SELECTION_MODE = "SET_SELECTION_MODE";
export const RESET_ERROR = "RESET_ERROR";
export const ADD_FAVORITE_STORE = "ADD_FAVORITE_STORE";
export const ADD_FAVORITE_STORE_ERROR = "ADD_FAVORITE_STORE_ERROR";
export const RESET_HIERARCHY = "RESET_HIERARCHY";