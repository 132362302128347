import { SET_WALLET, SET_START_DATE, SET_END_DATE } from "./wallet.types";
import DataService from "../../line/services/data.service";
import { SET_RETAILER_LIST } from "../retailers/retailers.types";
import MembersService from "../../line/services/members.service";

// MembersService.getHolders()
//     .then((responseJson) => {
//       DataService.holders.set(responseJson.data);
//       if (responseJson.data.length) {
//         setHolder(responseJson.data[0]);
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//     });

export const fetchRetailers = () => {
  // console.log("fetchRetailers");
  return dispatch => {
    // console.log("fetchRetailers dispatch");
    DataService.getRetailers()
      .then(res => {
        let retailers = res.data;
        let app_retailer_id = localStorage.getItem("app_retailer_id");
          let id_filter = [];
          if(app_retailer_id){
              id_filter = app_retailer_id.split(',').map( Number );
          }
          if(id_filter.length>0){
              retailers = retailers.filter((retailer) => id_filter.includes(retailer.id))
          }

        dispatch({
          type: SET_RETAILER_LIST,
          payload: retailers
        });
      })
  };
};
export const setMemberWallet = (data) => {
  return {
    type: SET_WALLET,
    payload: data,
  };
};

export const setWalletStartDate = (data) => {
  return {
    type: SET_START_DATE,
    payload: data,
  };
}

export const setWalletEndDate = (data) => {
  return {
    type: SET_END_DATE,
    payload: data,
  };
}

export const getWallet = (startDate, endDate) => {
  // console.log("getWallet");
  return dispatch => {
    // console.log("getWallet dispatch");
    MembersService.getWallet(startDate, endDate)
      .then(res => {
        const wallet = res.data;
        dispatch({
          type: SET_WALLET,
          payload: wallet
        });
      })
  };
};
