import { MailIcon, LockClosedIcon } from "@heroicons/react/solid";
import { FieldWithIcon } from "../../../shells/components/field-with-icon/field-with-icon";
import { FullRoundButton } from "../../../shells/components/full-round-button/full-round-button";
import { HalfSplitBackgroundShell } from "../../../shells/half-split-background-shell/half-split-background-shell";
import { BottomTermsCopywight } from "../bottom-terms-copyright/bottom-terms-copyright";
import { SocialIcons } from "../social-icons/social-icons";

export const ForgotPasswordReset = () => {
  return (
    <HalfSplitBackgroundShell>
      <FieldWithIcon
        Icon={LockClosedIcon}
        placeholder="新しいパスワード"
        type="password"
      />
      <FieldWithIcon
        Icon={LockClosedIcon}
        placeholder="新しいパスワード確認"
        type="password"
      />
      <FullRoundButton text="パスワード変更" />
      <div className="text-sm text-center text-gray-500 pt-3">
        ログイン画面へ戻る
      </div>
      <SocialIcons />
      <BottomTermsCopywight />
    </HalfSplitBackgroundShell>
  );
};
