import { SET_CATEGORIES, SET_SELECTED_CATEGORY } from "./categories.types";

const INITIAL_STATE = {
  categories: [],
  selectedCategory: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  // console.log("Reducer: ", state, action);
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        selectedCategory: action.payload[0],
      };

    case SET_SELECTED_CATEGORY:
      // console.log("Set selected: ", action.payload);
      return {
        ...state,
        selectedCategory: state.categories.filter(c => c.id === action.payload)[0],
      }
    default:
      return state;
  }
};

export default reducer;
