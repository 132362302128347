import { SAMPLE_WALLET } from "./SAMPLE_WALLET";
import { SET_WALLET, SET_START_DATE, SET_END_DATE } from "./wallet.types";

const INITIAL_STATE = {
  pending: [],
  refused: [],
  to_be_paid: [],
  paid: [],
  startDate: null,
  endDate: null,
};
// const INITIAL_STATE = SAMPLE_WALLET;

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WALLET:
      return {
        ...state,
        ...action.payload, // Comment this to use sample data
      };

    case SET_START_DATE:
      // console.log("Setting start date: ", action.payload);
      return {
        ...state,
        startDate: action.payload,
      };

    case SET_END_DATE:
      // console.log("Setting end date: ", action.payload);
      return {
        ...state,
        endDate: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
