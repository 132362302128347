import { SET_F_ALLWALLET, SET_F_START_DATE, SET_F_END_DATE } from "./filteredAllwallet.types";
import MembersService from "../../line/services/members.service";

export const setAllMemberFilteredWallet = (data) => {
  return {
    type: SET_F_ALLWALLET,
    payload: data,
  };
};

export const refreshAllMemberFilteredWallet = (startDate, endDate) => {
  // console.log("get All Wallet");
  return dispatch => {
    // console.log("refresh getAllWallet dispatch");
    MembersService.getWallet(startDate, endDate)
        .then(res => {
          const wallet = res.data;
          dispatch({
            type: SET_F_ALLWALLET,
            payload: wallet
          });
        })
  };
};

export const setWalletStartDate = (data) => {
  return {
    type: SET_F_START_DATE,
    payload: data,
  };
};

export const setWalletEndDate = (data) => {
  return {
    type: SET_F_END_DATE,
    payload: data,
  };
};

export const getWallet = (startDate, endDate, retailer_id) => {
  // console.log("getWallet");
  return (dispatch) => {
    // console.log("getWallet dispatch");
    MembersService.getWallet(startDate, endDate, retailer_id).then((res) => {
      const wallet = res.data;
      dispatch({
        type: SET_F_ALLWALLET,
        payload: wallet,
      });
    });
  };
};
