import {IonItem, IonSpinner, useIonViewDidEnter} from "@ionic/react";
import { useEffect} from "react";
import { Link } from "react-router-dom";
import { ScrollpageWithHeader } from "../scrollpage-with-header/scrollpage-with-header";
import {connect} from "react-redux";
import {setAppLoading} from "../../../redux/global/global.actions";
import {fetchPrefectures, fetchRetailers, setPrefecture, setRetailer} from "../../../redux/retailers/retailers.actions";
import {useHistory} from "react-router";

import { SelectedRetailerComponent } from "../../../components/selected-retailer/selected-retailer";
import { actionGetStoresAtLevelPrefecture, actionGetStoresAtLevelRetailer } from "../../../redux/stores/stores.actions";
import { showSplitHyphenName } from "../../../pages/registration/retailer-prefectures/retailer-prefectures";
import { StepHeader2 } from "../../../pages/registration/step-headers/step-headers";

const mapStateToProps = (state: any) => {
    return {
        prefectureList: state.retailers.prefectureList,
        prefecture:state.retailers.prefecture,
        loading: state.retailers.loading,
        retailer:state.retailers.selected,
        selectionMode:state.retailers.selectionMode

    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setAppLoading: (data: any) => dispatch(setAppLoading(data)),
        fetchPrefectures: (retailer:any) => dispatch(fetchPrefectures(retailer)),
        actionGetStoresAtLevelRetailer: (retailer:any) => dispatch(actionGetStoresAtLevelRetailer(retailer)),
        setPrefecture: (data: any) => dispatch(setPrefecture(data))
    };
};
interface Props {
    fetchPrefectures: Function;
    setPrefecture: Function;
    actionGetStoresAtLevelRetailer: Function;
    loading:boolean,
    selectionMode:boolean,
    prefectureList: any,
    prefecture:any,
    retailer:any
}
// const list = ["世田谷区", "ピーコックスト下北沢", "イオンリカー千歳船橋"];

export const Screen4Component = (props: Props) => {
    const history = useHistory();
    useIonViewDidEnter(()=>{
        // init()
    })
    const init = () => {
        if(props.retailer && props.selectionMode){
            props.fetchPrefectures(props.retailer)
            props.actionGetStoresAtLevelRetailer(props.retailer)
        } else{
            history.replace('/2');
        }
    }
  useEffect(() => {
      // init()
  }, []);

  useEffect(()=>{
      init()
  }, [props.retailer])
   let showError = props.loading && props.prefectureList &&
       props.prefectureList.length===0;
    console.log(showError)
  return (
    <ScrollpageWithHeader title="店舗選択" id="a">
      {props.loading && (
        <div className="mt-6 flex justify-center">
          <IonSpinner />
        </div>
      )}
      <div className="details flex flex-col justify-start overflow-auto">
        <StepHeader2 />

        <div className="px-3 mb-4">
          {!props.loading && <SelectedRetailerComponent retailer={props.retailer} />}
        </div>

        {/* <div className="text-lg text-left mt-5">店舗選択</div> */}
        {props.prefectureList && props.prefectureList.length!=0 && props.prefectureList.map((item:any) => (
          <IonItem
            detail={true}
            lines="none"
            key={item}
            onClick={() => {
              props.setPrefecture(item);
              history.push("/5");
            }}
            className="text py-2 px-6 -mt-1"
          >
            {showSplitHyphenName(item)}
          </IonItem>
        ))}
          {showError &&
          <div className="my-auto flex-shrink-0 py-16 sm:py-32">
              {/*<h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">都市が見つかりません</h1>*/}
              <p className="mt-2 text-base text-xs text-gray-600" >データ取得に失敗しました。他の市町村を試すか、後ほど再度お試しください</p>
              <div className="mt-6">
                  <a className="text-base font-medium text-indigo-600 hover:text-indigo-500" style={{
                      color: 'rgb(6, 199, 85)'
                  }} onClick={()=>{
                      history.push("/tabs/home");
                  }
                  }>
                      「ホーム」に戻る
                      <span aria-hidden="true"> &rarr;</span>
                  </a>
              </div>
          </div>
          }
      </div>
    </ScrollpageWithHeader>
  );
};
export const Screen4 = connect(
    mapStateToProps,
    mapDispatchToProps
)(Screen4Component);