import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import step1 from "./s1.png";
import step2 from "./step2_updated.png";
import step3 from "./s3.png";
import step4 from "./s4.png";
import steptext from "./steptext.png";
import linelogo from "./linelogo.png";
import waon from "../screen2/1.png";
import izumi from "../screen2/2.png";
import iy from "../screen2/10.png";
import yb from "../screen2/6.png";

import updated1 from "./updated1.png"
import updated2 from "./updated2.png"

import step_2_1 from "./2/img_step-1.png";
import step_2_2 from "./2/img_step-2.png";
import step_2_3 from "./2/img_step-3.png";
import step_2_4 from "./2/img_step-4.png";
import step_2_5 from "./2/img_step-5.png";

import step_3_1 from "./3/img_step01.png";
import step_3_2 from "./3/img_step02.png";
import step_3_3 from "./3/img_step03.png";
import React from "react";
import {classNames} from "../../components/member-holder-point-retailer/member-holder-point-retailer";


const collapsibles = [
  // {
  //   heading: "ポイントカード・お店の登録方法",
  //   steps: [
  //     {
  //       title: "STEP 1",
  //       subtitle: "利用したいポイントカードを選択",
  //       image: step_1_1,
  //     },
  //     {
  //       title: "STEP 2",
  //       subtitle: "利用したい店舗を選択",
  //       image: step_1_2,
  //     },
  //     {
  //       title: "STEP 3",
  //       subtitle: "ポイントカードの番号を入力し、「保存」をタップすると登録完了",
  //       image: step_1_3,
  //     }
  //   ]
  // },
  {
    heading: "お店登録済、他のポイントカードを変更したい",
    steps: [
      {
        title: "STEP 1",
        subtitle: "画面上部の店舗名の右にある「編集」をタップ",
        image: step_2_1,
      },
      {
        title: "STEP 2",
        subtitle: "「店舗を追加する」をタップ",
        image: step_2_2,
      },
      {
        title: "STEP 3",
        subtitle: "ポイントカードを選択",
        image: step_2_3,
      },
      {
        title: "STEP 4",
        subtitle: "利用したい店舗を選択",
        image: step_2_4,
      },
      {
        title: "STEP 5",
        subtitle: "ポイントカードの番号を入力し、「保存」をタップすると登録完了",
        image: step_2_5,
      }
    ]
  },
  {
    heading: "登録したポイントカードを削除したい場合",
    steps: [
      {
        title: "STEP 1",
        subtitle: "カード番号下の「ご利用の注意」をタップ",
        image: step_3_1,
      },
      {
        title: "STEP 2",
        subtitle: "ポイントカード番号をすべて消して「保存」をタップ",
        image: step_3_2,
      },
      {
        title: "STEP 3",
        subtitle: "確認ポップアップ「はい」をタップするとカードの削除は完了",
        image: step_3_3,
      }
    ]
  },
]

const steps = [
  {
    heading: "STEP 1",
    subtitle: "メールアドレスを登録し、お店とポイントカードを登録",
    image: step1,
  },
  {
    heading: "STEP 2",
    subtitle: "クーポンを選択",
    image: step2,
  },
  {
    heading: "STEP 3",
    subtitle: "ポイントカードで対象商品を購入",
    image: step3,
  },
  {
    heading: "STEP 4",
    subtitle: "後日ポイントが還元される",
    image: step4,
  },
];

const steps_new = [
  {
    heading: "Step 1",
    subtitle: "使いたいクーポンをセットすることで登録カードと紐付けをおこないます",
    image: updated1,
  },
  {
    heading: "Step 2",
    subtitle: "商品を購入する際はレジで登録カードを提示すればポイント付与",
    image: updated2,
  }
];

export const Steps = () => {

  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;

  let imageURL: any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  let app_retailer_id = localStorage.getItem("app_retailer_id");
  let retailers = [waonId, izumiId, iyId, ybId]
  let id_filter: any = [];

  if (app_retailer_id) {
    id_filter = app_retailer_id.split(',');
    localStorage.setItem('app_retailer_id', localStorage.getItem("app_retailer_id"))
  }
  if (id_filter.length > 0) {
    retailers = retailers.filter((retailer) => id_filter.includes(retailer))
  }
  // const history = useHistory();
  // const lineLogOut = async () => {
  //   liff
  //     .init({ liffId: LIFF_ID_DEV.toString() })
  //     .then(async () => {
  //       console.log(liff.isLoggedIn());
  //       if (liff.isLoggedIn()) {
  //         console.log(
  //           "Line profile : " + (await (await liff.getProfile()).userId)
  //         );
  //         console.log("AccessToken : " + liff.getAccessToken());
  //         console.log("IdToken : " + liff.getIDToken());
  //         console.log("DecodedIdToken : " + liff.getDecodedIDToken().aud);
  //         liff.logout();
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  return (
      <>
        <div className="p-6" style={{background: "#FFFFFF"}}>
          <div className="text-lg mb-1813">クーポンの使い方</div>
          <div className="coupons">
            {steps_new.map((step, index) => (
                <div key={step.heading}>
                  <div className="text-catalinared text-sm">{step.heading}</div>
                  <div className="mt-1 text-sm">{step.subtitle}</div>
                  <img className={classNames(
                      "object-contain mx-auto my-10",
                      index === 0 ? "h-24" : "",
                      index === 1 ? "h-24" : ""
                  )} src={step.image}/>
                </div>
            ))}

            <div className="text-sm">※ 登録しているカードを購入時に必ずご提示ください。</div>
            <div className="text-sm">※ 決済機能が付いたカードの場合、そのカードによる決済が必要となります。</div>
          </div>

        </div>

        <div className="p-6" style={{background: "#FFFFFF"}}>
          <div className="text-lg">使えるカード</div>
          {id_filter.length === 0 &&
              <>
                <div className="text-sm text-gray-500 mt-4">・電子マネーWAON</div>
                <div className="text-sm text-gray-500">・電子マネーnanaco</div>
                <div className="text-sm text-gray-500">・ゆめカード</div>
                <div className="text-sm text-gray-400 mt-4">
                  {/*<div className="font-bold underline">対象のポイントカードはこちらをご確認ください。</div>*/}
                  <div>※電子マネー一体型のクレジットカードも対象です。</div>
                </div>
              </>
          }
          {id_filter.length === 1 &&
              (id_filter[0] === iyId || id_filter[0] === ybId) &&
              <>
                <div className="text-sm text-gray-500">・電子マネーnanaco</div>
              </>
          }
          {id_filter.length === 1 &&
              id_filter[0] === izumiId &&
              <>
                <div className="text-sm text-gray-500">・ゆめカード</div>
              </>
          }
          {id_filter.length === 1 &&
              id_filter[0] === waonId &&
              <>
                <div className="text-sm text-gray-500">・電子マネーWAON</div>
              </>
          }
          <div className="text-lg mt-6">使えるお店</div>
          {id_filter.length === 1 && id_filter[0] === ybId &&
              <div className="text-sm text-gray-500">
                ・ヨークベニマル<br/>
              </div>
          }
          {id_filter.length === 1 && id_filter[0] === iyId &&
              <div className="text-sm text-gray-500">
                ・イトーヨーカードー<br/>
              </div>
          }
          {id_filter.length === 1 && id_filter[0] === waonId &&
              <div className="text-sm text-gray-500">
                ・イオン、マックスバリュ、ダイエーなど
                電子マネーWAONが使える全国のスーパーマー
                ケット<br/>
              </div>
          }
          {id_filter.length === 1 && id_filter[0] === izumiId &&
              <div className="text-sm text-gray-500">
                ・ゆめタウン、ゆめマート<br/>
              </div>
          }
          {id_filter.length === 0 &&
              <>
                <div className="flex items-end justify-center mt-4">
                  <img className="" style={{width: "100%"}}
                       src={"https://res.cloudinary.com/cwjp/image/upload/v1693885077/LCM/retailer_logos_wcaiqc.png"}/>
                </div>
                <div className="text-sm text-gray-500 mt-4">
                  ・イオン、マックスバリュ、ダイエーなど
                  電子マネーWAONが使える全国のスーパーマーケット<br/>
                  ・イトーヨーカドー<br/>
                  ・ヨークベニマル<br/>
                  ・ゆめタウン、ゆめマート<br/>
                </div>
              </>
          }

          {(app_retailer_id != ybId && app_retailer_id != iyId && app_retailer_id != izumiId) &&
              <div className="text-sm text-gray-400">
                ※<span>対象外の企業・店舗がございます。</span>
                {/*「お店を選択」から対象の店舗をご確認ください。*/}
              </div>
          }
          {/*{(app_retailer_id === ybId || app_retailer_id === iyId || app_retailer_id === izumiId) &&*/}
          {( app_retailer_id === iyId || app_retailer_id === izumiId) &&
              <div className="text-sm text-gray-400">
                「お店を選択」から対象の店舗をご確認ください。
              </div>
          }
          {/* <div className="text-sm text-gray-500">
          ※{app_retailer_id != ybId && <span>対象外の企業・店舗がございます。</span>}「お店を選択」から対象の店舗をご確認ください。
        </div> */}
          {/*<div className="h-5"></div>*/}
        </div>
        {/* <a href="https://liff.line.me/1611905212-3bydBEmv?utm_source=catalina&utm_medium=c_plus&utm_campaign=top"> */}
        <div className="h-2 mt-4 bg-gray-200">&nbsp;</div>
        <div
            className="bg-white flex p-4 items-center"
            onClick={() => {
              console.log("Executing Analytics tag code for line redirection!");
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "virtualClick",
                pageTitle: window.location.pathname,
                pageUrl:
                    "https://liff.line.me/1611905212-3bydBEmv?utm_source=catalina&utm_medium=c_plus&utm_campaign=top",
                dm_screen_type: "redirect",
              });
              window.location.href =
                  "https://liff.line.me/1611905212-3bydBEmv?utm_source=catalina&utm_medium=c_plus&utm_campaign=top";
              // liff.closeWindow();
            }}
        >
          <div className="flex-1">
            <div className="text-lg  font-bold">
              <img src={linelogo} className="h-5 mb-1"/>
            </div>
            <div className="text text-sm" style={{color: "#616161"}}>
              今すぐ使えて毎日おトク 全国40ショップ以上で使える
            </div>
          </div>
          <ChevronRightIcon className="w-6 h-6"/>
        </div>
        <div className="h-2 mt-4 bg-gray-200">&nbsp;</div>
        {/* </a> */}
        <div style={{background: "rgb(71 71 71)"}} className="px-6 pb-10 pt-14">
          <img src={steptext} className="h-5"/>
          <div className="text-white text-xs mt-1">LINEクーポン×カタリナ</div>
          <div className="mt-4" style={{fontSize: 10, color: "#B7B7B7"}}>
            ©LINE Corporation
          </div>
        </div>
      </>
  );
};
