import { IonButton, IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import MembersService from "../../line/services/members.service";
import { ScrollpageWithHeader } from "../../line/screens/scrollpage-with-header/scrollpage-with-header";
import { TextField } from "../../line/components/text-field/text-field";
import { InformModal } from "../../components/inform-modal/inform-modal";
import AuthService from "../../line/services/auth.service";

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}

export const Terms2Page = () => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState<string>("francisco.rojas@catalina.com");
  const [password, setPassword] = useState<string>("Password.1");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModel, setOpenErrorModel] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  // const handlePatch = () => {
  //   MembersService.patchUser({ email })
  //     .then((res) => {
  //       console.log("user patch update res ", res);
  //       history.push("/2");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setErrorMessage(err.response.data.error);
  //       setOpenErrorModel(true);
  //     });
  // };
  const handleLogin = () => {
    if (!email) {
      setErrorMessage("Please enter a valid email");
      setOpenErrorModel(true);
      return;
    }
    if (validateEmail(email) === false) {
      setErrorMessage("Please enter a valid email");
      setOpenErrorModel(true);
      return;
    }

    setLoading(true);
    // AuthService.socialLogin(liff.getAccessToken(), lineUser.userId)
    // .then(res => console.log(res)).catch(err => console.log(err));
    AuthService.login(email, password)
      .then((response) => {
        localStorage.setItem("user-data", JSON.stringify(response.data));
        return MembersService.getFavoriteStores();
      })
      .then((response) => {
        // if (response.data.length) {
        history.push("/tabs/home");
        // } else {
        //   history.push("/2");
        // }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code[0] === 5 || err.response.data.code[0] === 16) {
          setLoading(true);
          // Social Auth Parameters
          // AuthService.signupSocial(email, password, {
          //   // token: liff.getAccessToken(),
          //   // encrypted_token: liff.getIDToken(),
          //   type: 1,
          //   id: lineUser.userId
          // })
          AuthService.signup(email, password)
            .then((response) => {
              setLoading(true);
              // AuthService.socialLogin(liff.getAccessToken(), lineUser.userId)

              AuthService.login(email, password)
                .then((response) => {
                  localStorage.setItem(
                    "user-data",
                    JSON.stringify(response.data)
                  );
                  return MembersService.getFavoriteStores();
                })
                .then((response) => {
                  setLoading(false);
                  history.push("/tabs/home");
                })
                .catch((err) => {
                  setLoading(false);
                  setErrorMessage(err.response.data.error);
                  setOpenErrorModel(true);
                });
            })
            .catch((err) => {
              setLoading(false);
              setErrorMessage(err.response.data.error);
              setOpenErrorModel(true);
            });
        } else {
          setLoading(false);
          setErrorMessage(err.response.data.error);
          setOpenErrorModel(true);
        }
      });
  };

  return (
    <ScrollpageWithHeader title="メルアドレスを登録" id="terms">
      <div className="px-6 mt-6">
        {openErrorModel && (
          <div className="text-red-600 text-xs -mt-3 mb-10">{errorMessage}</div>
        )}
        <IonLabel className="account-editing">メールアドレス</IonLabel>
        <TextField
          placeholder="メールアドレスを入力してください"
          value={email}
          onChange={(value: any) => setEmail(value)}
          type="text"
        />
        {email && !validateEmail(email) && (
          <div className="text-red-600 text-xs -mt-3 mb-10">
            メールアドレスを正しく入力してください。
          </div>
        )}

        <div className="mt-16"></div>
        <div className="text">利用規約</div>
        <div className="text-sm text-gray mt-4">
          本利用規約は、カタリナマーケティングジャパン株式会社(以下｢当社｣といいます)が運営するクーポン値引きをカードに還元するサービス「カタリナ」をご利用頂くに当たっての注意事項を規定したものです。ご利用に先立ち、本利用規約をよくお読み頂きますようお願い申し上げます。ユーザーは、本サービス（第2条で定義します）を利用することにより、本利用規約の内容に同意したものとさせて頂きます。
          利用資格
          本サービス内のお酒のクーポンをご利用頂けるのは、20歳以上の方に限られます。
          用語の定義について「本サービス」とは、利用者が当社アプリまたは当社サイト上でクーポンを取得することができる当社のサービスおよびこれに付随して当社アプリまたは当社サイトにおいて提供する全てのサービスをいいます。
        </div>
      </div>

      <IonItem lines="none" className="mt-6 flex items-center">
        <IonCheckbox
          checked={checked}
          onIonChange={(e) => setChecked(e.detail.checked)}
        />
        <IonLabel>利用規約に同意する</IonLabel>
      </IonItem>

      <div className="px-4 my-6">
        <IonButton
          disabled={
            !email ||
            !validateEmail(email)
          }
          onClick={handleLogin}
          expand="block"
          color="light"
        >
          登録する
        </IonButton>
      </div>
      {/* {openErrorModel && (
        <InformModal
          message={errorMessage}
          open={openErrorModel}
          setOpen={setOpenErrorModel}
        />
      )} */}
    </ScrollpageWithHeader>
  );
};
