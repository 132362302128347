import {CheckCircleIcon, ChevronRightIcon} from "@heroicons/react/solid";
import { Link, useHistory } from "react-router-dom";

import { useEffect, useState } from "react";
import {IonSpinner, useIonViewDidEnter} from "@ionic/react";
import {connect} from "react-redux";
import {
    fetchRetailers,
    fetchRetailersWithoutAuth,
    resetHierarchy,
    setRetailer
} from "../../../redux/retailers/retailers.actions";
import waon from "./1.png";
import izumi from "./2.png";
import yb from "./6.png";
import iy from "./10.png";
import { ScrollpageWithHeader } from "../../../line/screens/scrollpage-with-header/scrollpage-with-header";
import { useAtom, useSetAtom } from "jotai";
import { Atom_Registration_Selected_Retailer } from "../../../line/state/atoms/registration.email.atom";


function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const mapStateToProps = (state: any) => {
    return {
        retailers: state.retailers.list,
        loading: state.retailers.loading,

    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchRetailers: () => dispatch(fetchRetailersWithoutAuth()),
        setRetailer: (data: any) => dispatch(setRetailer(data)),
        resetHierarchy: (data: any) => dispatch(resetHierarchy())
    };
};
interface Props {
    fetchRetailers: Function;
    setRetailer: Function;
    resetHierarchy: Function;
    retailers:any
    loading:boolean
}
export const SignUpRetailersComponent = (props: Props) => {
  let waonId = process.env.REACT_APP_WAON_ID as string;
  let izumiId = process.env.REACT_APP_IZUMI_ID as string;
  let iyId = process.env.REACT_APP_IY_ID as string;
  let ybId = process.env.REACT_APP_YB_ID as string;

  let imageURL:any = {};
  imageURL[waonId] = waon;
  imageURL[izumiId] = izumi;
  imageURL[iyId] = iy;
  imageURL[ybId] = yb;

  const setSelectedRetailer = useSetAtom(Atom_Registration_Selected_Retailer);

  const [selectedId, setSelectedId] = useState(0);
  const history = useHistory();
    useIonViewDidEnter(()=>{
        init();
    })
  useEffect(() => {
   init()
  }, []);
    const init = () => {
        setSelectedId(0);
        props.resetHierarchy();
        props.fetchRetailers();
    }
  return (
    <ScrollpageWithHeader title="カタリナ" id="screen-2">
      <div className="px-4 pb-6">
        {props.loading ||
          (selectedId !== 0 && (
            <div className="mt-6 flex justify-center">
              <IonSpinner />
            </div>
          ))}
        {props.retailers &&
          props.retailers.length != 0 &&
          props.retailers.map((card: any) => (
            <Link onClick={() => {
              setSelectedRetailer({
                id: card.id,
                name: card.name,
                image_url: imageURL[card.id],
                picture_card: card.picture_card
              })
            }} to={`/sign-up/input-card`}>
              <div
                key={card.id}
                className={classNames(
                  "card mt-4 flex items-center py-2",
                  selectedId === card.id ? "bg-gray-100 rounded-md" : ""
                )}
              >
                <img
                  className="items-center justify-center rounded-md flex overflow-hidden object-contain"
                  style={{
                    height: "2.875rem",
                    width: "3.875rem",
                  }}
                  src ={imageURL[card.id]}
                />
                <div className="pl-3 text-sm flex-1 items-center">
                  {card.name}
                </div>
                  {selectedId === card.id && <CheckCircleIcon className="h-5 w-5 text-green-600"/>}
                <ChevronRightIcon className="h-5 w-5" style={{color: "#C8C8C8"}} />
              </div>
            </Link>
          ))}

      </div>
    </ScrollpageWithHeader>
  );
};
export const SignUpRetailers = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpRetailersComponent);